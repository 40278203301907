import React from "react";
import { Icon } from "components/ui";
import { AttributesDataService } from "./services/attributes-data-service";
import { decorateComponent, withAuthContext } from "../../auth/withAuthContext";
import AuthenticatedLayout from "../../components/app/AuthenticatedLayout";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import BooleanRenderer from "../../components/app/grid/booleanRenderer";
import {
  CHANNELS,
  DISPLAYS,
  EDITOR_FROM_TYPECAST,
  RENDERER_FROM_DISPLAY
} from "../../lib/constants";
import { SheetDataService } from "lib";
import { withRouter } from "react-router-dom";
import TextRenderer from "../../components/app/grid/textRenderer";
import Modal from "../../components/ui/Modal";
import { NewToolbar } from "../../components/app/grid";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AttributesGridController } from "./gridConfiguration/attributesGridController";
import {
  ATTRIBUTES_WRITER_LOCAL_STORAGE_INDEX,
  WriterBuffer
} from "../../lib/WriterBuffer";

const columns = [
  {
    name: "code",
    code: "code",
    label: "Code",
    options: {
      filter: false,
      display: "excluded"
    }
  },
  {
    name: "shortLabel",
    label: "Short Label",
    code: "shortLabel",
    options: {
      filter: false,
      customBodyRender: value => {
        return <Icon size="18" line="1" glyph={value} />;
      }
    }
  },
  {
    name: "name",
    label: "Name",
    code: "name",
    options: {
      filter: false
    }
  },
  {
    name: "entityName",
    label: "Entity",
    code: "entityName",
    display: DISPLAYS.entityAttribute,
    key: "entity.name",
    protect: "true"
  },
  {
    name: "typecast",
    label: "Type",
    code: "typecast",
    options: {
      filter: true
    }
  },
  {
    name: "description",
    label: "Description",
    code: "description",
    options: {
      filter: false
    }
  },
  {
    name: "label",
    label: "Label",
    code: "label",
    options: {
      filter: false
    }
  },
  {
    name: "testable",
    label: "Testable",
    code: "testable",
    typecast: "boolean",
    options: {
      filter: false,
      display: "boolean"
    }
  },
  {
    name: "defaultValue",
    label: "Default",
    code: "defaultValue",
    options: {
      filter: true
    }
  },
  {
    name: "attributeGroup",
    label: "Group",
    code: "attributeGroup",
    display: "select",
    typecast: "select",
    options: {
      filter: true
    }
  },
  {
    name: "attributeSubgroup",
    label: "Subgroup",
    code: "attributeSubgroup",
    display: "select",
    typecast: "select",
    options: {
      filter: true
    }
  }
];

const getInitialProps = () => {
  const attributesDataService = new AttributesDataService();
  const attributesWriterCache = new WriterBuffer(
    ATTRIBUTES_WRITER_LOCAL_STORAGE_INDEX
  );
  return {
    loading: true,
    attributesDataService,
    attributesWriterCache
  };
};

// TODO: inject international
class AttributesStatic extends React.Component {
  componentDestroyedSubject = new Subject();

  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.urlParams = {
      sheet: query.get("sheet"),
      channel: query.get("channel") || CHANNELS.core,
      view: query.get("viewObjectSubject"),
      alternative: query.get("alternative")
    };
    this.user = this.props.user;
    // TODO: remove the hardcoded value of organisation id when onboarding is completed
    this.user.sub = "28a83b47-a6b3-4634-8125-f0afa723427c";
    this.tokens = this.props.tokens;
    this.gridController = new AttributesGridController(
      columns,
      [],
      this.urlParams,
      this.props.attributesWriterCache
    );
    this.state = {
      data: [],
      entities: [],
      inspector: false,
      current: null,
      channelData: {},
      settings: {},
      view: [],
      views: [],
      sets: [],
      loading: this.props.loading,
      rowsData: [],
      selectedRows: [],
      rowsToDisplay: [],
      isModalOpen: false,
      modalContent: {},
      isChildModalOpen: false,
      childModalContent: {},
      modalSize: "medium",
      childModalSize: "size"
    };
  }

  // TODO: find a less verbose way to initialise observables
  componentDidMount() {
    this.gridController.settingsObservable$
      .pipe(takeUntil(this.componentDestroyedSubject))
      .subscribe(settings => {
        this.setState(currentState => ({
          ...currentState,
          settings
        }));
      });
    this.gridController.viewObservable$
      .pipe(takeUntil(this.componentDestroyedSubject))
      .subscribe(view => {
        this.setState(currentState => ({
          ...currentState,
          view
        }));
        if (this.gridController.gridColumnApi) {
          const columnsToHide = this.gridController._cols
            .filter(column => view.attribute_codes.indexOf(column.code) === -1)
            .map(column => column.code);
          this.gridController.gridColumnApi.setColumnsVisible(
            view.attribute_codes || [],
            true
          );
          this.gridController.gridColumnApi.setColumnsVisible(
            columnsToHide || [],
            false
          );
        }
      });
    this.gridController.viewsObservable$
      .pipe(takeUntil(this.componentDestroyedSubject))
      .subscribe(views => {
        this.setState(currentState => ({
          ...currentState,
          views
        }));
      });
    this.gridController.setsObservable$
      .pipe(takeUntil(this.componentDestroyedSubject))
      .subscribe(sets => {
        this.setState(currentState => ({
          ...currentState,
          sets
        }));
      });
    this.gridController.channelDataObservable$
      .pipe(takeUntil(this.componentDestroyedSubject))
      .subscribe(channelData => {
        this.setState(currentState => ({
          ...currentState,
          channelData
        }));
      });
    this.gridController.isModalOpenObservable$
      .pipe(takeUntil(this.componentDestroyedSubject))
      .subscribe(isModalOpen => {
        this.setState(currentState => ({
          ...currentState,
          isModalOpen
        }));
      });
    this.gridController.modalContentObservable$
      .pipe(takeUntil(this.componentDestroyedSubject))
      .subscribe(modalContent => {
        this.setState(currentState => ({
          ...currentState,
          modalContent
        }));
      });
    this.gridController.isChildModalOpenObservable$
      .pipe(takeUntil(this.componentDestroyedSubject))
      .subscribe(isChildModalOpen => {
        this.setState(currentState => ({
          ...currentState,
          isChildModalOpen
        }));
      });
    this.gridController.childModalContentObservable$
      .pipe(takeUntil(this.componentDestroyedSubject))
      .subscribe(childModalContent => {
        this.setState(currentState => ({
          ...currentState,
          childModalContent
        }));
      });
    this.gridController.selectedRowsObservable$
      .pipe(takeUntil(this.componentDestroyedSubject))
      .subscribe(selectedRows => {
        this.setState(currentState => ({
          ...currentState,
          selectedRows
        }));
      });
    this.gridController.rowsToDisplayObservable$
      .pipe(takeUntil(this.componentDestroyedSubject))
      .subscribe(rowsToDisplay => {
        this.setState(currentState => ({
          ...currentState,
          rowsToDisplay
        }));
      });
    this.gridController.entitiesObservable$
      .pipe(takeUntil(this.componentDestroyedSubject))
      .subscribe(entities => {
        this.setState(currentState => ({
          ...currentState,
          entities
        }));
      });
    AttributesDataService.attributesObservable$
      .pipe(takeUntil(this.componentDestroyedSubject))
      .subscribe(next => {
        this.setState(currentState => ({
          ...currentState,
          data: next,
          loading: false
        }));
        this.gridController._rows = next;
      });
    // TODO: ensure that correct organisation id is passed
    this.props.attributesDataService.readItems(
      this.user,
      this.tokens.accessToken
    );
    const interval = process.env.REACT_APP_CACHE_FLUSH_INTERVAL
      ? parseInt(process.env.REACT_APP_CACHE_FLUSH_INTERVAL)
      : 5000;
    this.updateDataInterval = setInterval(() => {
      const storedItems = this.props.attributesWriterCache.getItemsForUpdate();
      this.props.attributesWriterCache.clearItemsForUpdate();
      if (Object.keys(storedItems).length === 0) return;
      const dataRequest = Object.keys(storedItems).map(key => storedItems[key]);
      this.props.attributesDataService.updateItems(
        this.tokens.accessToken,
        dataRequest,
        () => {
          // TODO: add logic to prevent loss of data between reading the items and successful update
          this.props.attributesDataService.readItems(
            this.props.user,
            this.props.accessToken
          );
        }
      );
    }, interval);
  }

  componentWillUnmount() {
    this.gridController.unsubscribe();
    this.componentDestroyedSubject.next(true);
    this.componentDestroyedSubject.complete();
    clearInterval(this.updateDataInterval);
  }

  render() {
    return (
      //TODO: modify authenticated layout to have a dynamic route displayed
      <AuthenticatedLayout title="Attributes">
        <NewToolbar
          // onFilterChange={handleFilter}
          onViewChange={event =>
            this.gridController.gridOptions.handleView(event)
          }
          // onSelectionChange={handleSelection}
          // onActionClick={handleActionClick}
          // onButtonClick={handleToggleButtonClick}
          // onWidgetToggle={handleWidget}
          // onSliderChange={handleSliders}
          views={this.state.views}
          view={this.state.view}
          rowsToDisplay={this.state.rowsToDisplay.length}
          rows={this.state.data}
          // widget={widget}
          entities={this.state.entities}
          selectedNumber={this.state.selectedRows.length}
          // appliedFilters={appliedFilters}
        />
        <div
          className={`ag-grid ag-theme-balham${
            this.urlParams.alternative === DISPLAYS.gallery ? " hidden" : ""
          }`}
          style={{
            width: "100%",
            height: "600px"
          }}
        >
          <AgGridReact
            onGridReady={({ api, columnApi }) =>
              this.gridController.onGridReady({ api, columnApi })
            }
            rowData={this.state.data}
            immutableData={true}
            getRowNodeId={data => data.id}
            frameworkComponents={{
              // renderers
              booleanRenderer: BooleanRenderer,
              textRenderer: TextRenderer
            }}
            {...this.gridController.gridOptions.options}
          >
            <AgGridColumn
              headerName="#"
              field="rowNumber"
              width={75}
              rowDrag={
                this.gridController.urlParams.alternative === DISPLAYS.hierarchy
                  ? this.state.settings[DISPLAYS.hierarchy]
                    ? this.state.settings[DISPLAYS.hierarchy].dragging
                    : true
                  : true
              }
              checkboxSelection
              headerCheckboxSelection
              headerCheckboxSelectionFilteredOnly
              //valueGetter={(params) => params.node.rowIndex + 1}
              suppressMenu
              suppressCellFlash
              suppressMovable
              suppressColumnsToolPanel
              editable={false}
              cellClass="lock-col" // add style for this class ( :disabled )
              lockPosition
              lockPinned
              pinned="left"
              suppressKeyboardEvent={params => {
                return this.gridController.suppressKeyPress(
                  params,
                  ["D", "d", "A", "a"],
                  true
                );
              }}
            />
            {columns.map(col => (
              <AgGridColumn
                key={col.code}
                field={col.code}
                headerName={col.label}
                cellRenderer={
                  RENDERER_FROM_DISPLAY[col.display] || "textRenderer"
                }
                cellRendererParams={{
                  channelData: this.state.channelData,
                  typecast: col.typecast,
                  cols: this.gridController._cols,
                  onHelperClick: ({ api, node, column, value }) =>
                    this.gridController.handleHelperClick({
                      api,
                      node,
                      column,
                      value
                    }),
                  editable: params =>
                    this.gridController.handleEditable(params, col),
                  onTesting: (params, targetCol) =>
                    this.gridController.handleTesting(params, targetCol || col),
                  suppressCount: true
                }}
                cellEditorParams={{
                  cellRenderer:
                    RENDERER_FROM_DISPLAY[col.display] || "textRenderer",
                  values: SheetDataService.extractValues(
                    this.state.sets[col.code]
                  ),
                  channelData: this.state.channelData
                }}
                cellEditorSelector={params => {
                  if (params.keyPress && params.keyPress === "helperBtn") {
                    return {
                      component: EDITOR_FROM_TYPECAST[col.typecast]
                    };
                  } else if (params.value && params.value.length > 30) {
                    return {
                      component: "agPopupTextCellEditor"
                    };
                  }
                }}
                sortable
                editable={params =>
                  this.gridController.handleEditable(params, col)
                }
                resizable
                enableValue
                hide={false}
                valueFormatter={this.gridController.gridOptions.valueFormatter(
                  col
                )}
                valueSetter={this.gridController.gridOptions.valueSetter(col)}
                // valueParser={this.gridController.gridOptions.valueParser(col)}
                valueGetter={this.gridController.gridOptions.valueGetter(col)}
                cellClassRules={{
                  good: params =>
                    this.gridController.cellClassRules({
                      colState: col,
                      sets: this.state.sets,
                      channel: this.urlParams.channel,
                      ...params
                    }).good,
                  critical: params =>
                    this.gridController.cellClassRules({
                      colState: col,
                      sets: this.state.sets,
                      channel: this.urlParams.channel,
                      ...params
                    }).critical,
                  warning: params =>
                    this.gridController.cellClassRules({
                      colState: col,
                      sets: this.state.sets,
                      channel: this.urlParams.channel,
                      ...params
                    }).warning,
                  readOnly: params =>
                    this.gridController.cellClassRules({
                      colState: col,
                      sets: this.state.sets,
                      channel: this.urlParams.channel,
                      ...params
                    }).readOnly,
                  "hover-over": params =>
                    params.node === this.gridController.potentialParent
                }}
                cellStyle={params =>
                  this.gridController.cellStyleRules({
                    colState: col,
                    ...params
                  })
                }
                headerComponentParams={{
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
                    `    ${
                      col.testable && col.channels && col.channels.length > 0
                        ? '<span title="" class="ag-icon ag-icon-eye"></span>'
                        : ""
                    }` +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    "  </div>" +
                    "</div>"
                }}
              />
            ))}
          </AgGridReact>
        </div>
        <Modal
          open={this.state.isModalOpen}
          onClose={() => {
            this.gridController.setIsModalOpen(false);
          }}
          size={this.state.modalSize}
          isDialog={this.state.modalContent.isDialog}
        >
          {this.gridController.renderParentModal(
            this.state.modalContent.type,
            this.state.modalContent.params
          )}
        </Modal>
        <Modal
          open={this.state.isChildModalOpen}
          size={this.state.childModalSize}
          onClose={() => {
            this.gridController.setChildModalOpen(false);
          }}
          isDialog={this.state.modalContent.isDialog}
        >
          {this.gridController.renderChildModal(
            this.state.childModalContent.type,
            this.state.childModalContent.params
          )}
        </Modal>
      </AuthenticatedLayout>
    );
  }
}

export default decorateComponent(
  getInitialProps,
  withAuthContext(withRouter(AttributesStatic))
);
