import React from "react";
// import ContentLoader from "react-content-loader";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
// import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { AuthenticatedLayout } from "../../components/app";

// Should be moved to a separate folder for reuse across screens
// const ToolsListLoader = () => (
//   <ContentLoader speed={2} primaryColor="#c0c0c0" secondaryColor="#ecebeb">
//     <rect x="280" y="15" rx="1" ry="1" width="66" height="100" />
//     <rect x="210" y="15" rx="1" ry="1" width="66" height="100" />
//     <rect x="140" y="15" rx="1" ry="1" width="66" height="100" />
//     <rect x="70" y="15" rx="1" ry="1" width="66" height="100" />
//     <rect x="0" y="15" rx="1" ry="1" width="66" height="100" />
//     <rect x="0" y="2" rx="1" ry="1" width="300" height="10" />
//   </ContentLoader>
// );

// const Loading = () => (
//   <AuthenticatedLayout>
//     <ToolsListLoader />
//   </AuthenticatedLayout>
// );

// const StyledToggleButtonGroup = withStyles(theme => ({
//   grouped: {
//     margin: theme.spacing(0.5),
//     border: "none",
//     padding: theme.spacing(0, 1),
//     "&:not(:first-child)": {
//       borderRadius: theme.shape.borderRadius
//     },
//     "&:first-child": {
//       borderRadius: theme.shape.borderRadius
//     }
//   }
// }))(ToggleButtonGroup);

export default class ToolsIndex extends React.Component {
  state = {
    loading: true,
    items: [],
    formats: []
  };

  // componentWillMount = async () => {
  //   await this.fetch();
  // };

  // resource = () => Tool;

  // fetch = async () => {
  //   const items = await this.resource().all();
  //   this.setState({ items, loading: false });
  // };

  render() {
    // const { loading, items, formats } = this.state;

    // if (loading) return <Loading />;

    // const [formats, setFormats] = React.useState(() => ['italic']);

    // const handleFormat = (event, newFormats) => {
    //  setFormats(newFormats);
    // };

    const TOOl_CARDS = [
      {
        name: "Import Wizard",
        image: "images/clip-1.png",
        path: `${window.location.pathname}/csv-import`
      },
      {
        name: "Connection Wizard",
        image: "images/clip-2.png",
        path: `${window.location.pathname}/connection-setup`
      },
      {
        name: "BC custom field wizard ",
        image: "images/clip-2.png",
        path: `${window.location.pathname}/bc-custom-fields`
      },
      {
        name: "Shopify meta field wizard",
        image: "images/clip-2.png",
        path: `${window.location.pathname}/shopify-meta-fields`
      }
    ];

    return (
      <AuthenticatedLayout title="Tools">
        <div className="box">
          {TOOl_CARDS.map(item => {
            return (
              <div style={{ padding: "20px" }}>
                <Link to={item.path}>
                  <Card style={{ maxWidth: "345px" }}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        alt="Card"
                        height="140"
                        image="/images/clip-1.png"
                        title={item.name}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          {item.name}
                        </Typography>
                        {/* <Typography variant="body2" color="textSecondary" component="p">
                      611 rows
                    </Typography> */}
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </div>
            );
          })}
        </div>
        {/* <Toolbar>
          <span>
            {items.length} of {items.length}
          </span>
          <StyledToggleButtonGroup
            size="small"
            value={formats}
          // onChange={handleFormat}
          >
            <ToggleButton value="bold">Refresh</ToggleButton>
            <ToggleButton value="italic">
              <Icon size="22" line="0" glyph="banana" />
            </ToggleButton>
            <ToggleButton value="underlined">
              {" "}
              <Icon size="22" line="0" glyph="apple" />
            </ToggleButton>
            <ToggleButton value="color">
              {" "}
              <Icon size="22" line="0" glyph="brain" />
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Toolbar>
        <Grid.Row>
          {loading && <p>Loading...</p>}

          {!loading &&
            items.map(tool => (
              <Grid.Col s={2} key={tool.id}>
                <ToolCard
                  title={tool.name}
                  description={tool.description}
                  tags={tool.tags}
                  id={tool.id}
                  icon={tool.icon}
                  path={tool.code}
                  link={tool.readMoreUrl}
                />
              </Grid.Col>
            ))}
        </Grid.Row> */}
      </AuthenticatedLayout>
    );
  }
}
