import { useState, useEffect, useRef } from "react";

let holdingData = [];

function UseLoadMore(
  data = [],
  initialLoad = 10,
  perPage = 10,
  suppressDataUpdate
) {
  const [dataToShow, setDataToShow] = useState([]);
  const ref = useRef(perPage);
  const dataLoaded = useRef(false);

  const loopWithSlice = (start, end) => {
    const slicedData = data.slice(start, end);
    holdingData =
      !suppressDataUpdate ||
      holdingData.length === 0 ||
      initialLoad > holdingData.length
        ? [...holdingData, ...slicedData]
        : holdingData;
    setDataToShow(holdingData);
  };

  useEffect(() => {
    // Flush holded data if length of passed data is lower
    if (data.length < holdingData.length) {
      holdingData = [];
    }
    if (data.length > 0 && !dataLoaded.current) {
      loopWithSlice(0, initialLoad);
      dataLoaded.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleShowMorePosts = () => {
    loopWithSlice(ref.current, ref.current + perPage);
    ref.current += perPage;
  };

  return [dataToShow, handleShowMorePosts];
}

export default UseLoadMore;
