import React from "react";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";

import "./style.css";

const AssetPreview = ({ image, tag, onDelete }) => {
  const [showDelete, setShowDelete] = React.useState(false);

  return (
    <div
      className="preview-frame"
      onMouseOver={() => setShowDelete(true)}
      onMouseOut={() => setShowDelete(false)}
    >
      <Button
        disableRipple
        variant="contained"
        className="delete-btn"
        onClick={onDelete}
        style={{ visibility: showDelete ? "visible" : "hidden" }}
      >
        <DeleteIcon color="error" />
      </Button>
      <img src={image} alt="preview" />
      <Chip label={tag} className="chip" />
    </div>
  );
};

export default AssetPreview;
