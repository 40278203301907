import React from "react";
import { Registry } from "lib";
import { Card, CardActionArea, CardContent } from "@material-ui/core";
import { H4, H5, H6, P, Colours } from "components/ui";
import { InfoCard } from "models";
// import ContentLoader from "react-content-loader";
import dayjs from "dayjs";
import { Grid } from "../../components/ui";
import { AuthenticatedLayout } from "../../components/app";
import DashCard from "./DashCard";

// const DashboardLoader = () => (
//   <ContentLoader speed={2} primaryColor="#c0c0c0" secondaryColor="#ecebeb">
//     <rect x="210" y="60" rx="1" ry="1" width="100" height="41" />
//     <rect x="105" y="60" rx="1" ry="1" width="100" height="41" />
//     <rect x="0" y="60" rx="1" ry="1" width="100" height="41" />
//     <rect x="315" y="15" rx="1" ry="1" width="100" height="41" />
//     <rect x="210" y="15" rx="1" ry="1" width="100" height="41" />
//     <rect x="105" y="15" rx="1" ry="1" width="100" height="41" />
//     <rect x="0" y="15" rx="1" ry="1" width="100" height="41" />
//     <rect x="0" y="2" rx="1" ry="1" width="300" height="10" />
//   </ContentLoader>
// );

// const Loading = () => (
//   <AuthenticatedLayout>
//     <DashboardLoader />
//   </AuthenticatedLayout>
// );

export default class DashboardIndex extends React.Component {
  state = {
    loading: false,
    items: []
  };

  componentWillMount = async () => {
    // await this.fetch(); commented to prevent using stitch model
  };

  resource = () => InfoCard;

  fetch = async () => {
    const items = await this.resource().all();
    this.setState({ items, loading: false });
  };

  render() {
    const { loading, items } = this.state;

    // if (loading) return <Loading />;

    const user = Registry.get("user");
    const userRole = user ? user.role : null;

    const account = Registry.get("account");
    const accountPlanCode = account ? account.planCode : null;
    const accountTrialDays = account ? account.trialDays : null;
    const accountTrialStart = account ? account.trialStart : null;

    const showTrialCard = userRole === "owner" && accountPlanCode === "trial";
    const daysLeft =
      accountTrialDays - dayjs().diff(dayjs(accountTrialStart), "day");
    const trialOver = daysLeft < 0;

    function gotoURL(path) {
      window.location = path;
    }

    return (
      <AuthenticatedLayout title="Dashboard">
        {/* <Toolbar>
          <LinkButton size="small" ui="secondary" to="/settings/infocards">
            Organize
          </LinkButton>
        </Toolbar> */}

        {loading && <p>Loading...</p>}

        {!loading && (
          <Grid.Row>
            {showTrialCard ? (
              <Grid.Col s={3} key="514">
                {trialOver ? (
                  <Card>
                    <CardActionArea
                      onClick={() => gotoURL("/settings/account")}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          width: "100%",
                          backgroundColor: Colours.critical,
                          backgroundImage: "url(/icons/creditcard.svg)",
                          backgroundRepeat: "no-repeat",
                          backgroundPositionX: "95%",
                          backgroundPositionY: "15%",
                          backgroundSize: "100px 100px"
                        }}
                      >
                        <div>
                          <CardContent>
                            <H4>Trial expired!</H4>
                            <H5>Select a plan</H5>
                            <H6>Billing</H6>
                            <P>Last updated: {dayjs().format("dddd HH:mm")}</P>
                          </CardContent>
                        </div>
                      </div>
                    </CardActionArea>
                  </Card>
                ) : (
                  <Card>
                    <CardActionArea
                      onClick={() => gotoURL("/settings/account")}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          width: "100%",
                          backgroundColor: Colours.warning,
                          backgroundImage: "url(/icons/gift.svg)",
                          backgroundRepeat: "no-repeat",
                          backgroundPositionX: "95%",
                          backgroundPositionY: "15%",
                          backgroundSize: "100px 100px"
                        }}
                      >
                        <div>
                          <CardContent>
                            <H4>{daysLeft}</H4>
                            <H5>Trial days left</H5>
                            <H6>Billing</H6>
                            <P>Last updated: {dayjs().format("dddd HH:mm")}</P>
                          </CardContent>
                        </div>
                      </div>
                    </CardActionArea>
                  </Card>
                )}
              </Grid.Col>
            ) : null}
            {items.map(card => (
              <Grid.Col s={3} key={card.id}>
                <DashCard {...card} />
              </Grid.Col>
            ))}
          </Grid.Row>
        )}
      </AuthenticatedLayout>
    );
  }
}
