import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Select,
  Menu,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Switch,
  Divider,
  Button
} from "@material-ui/core";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
// import NativeSelect from "@material-ui/core/NativeSelect";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150
  },
  button: {
    textTransform: "none",
    transition: "none",
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const elements = {
  divider: Divider
};
const TextInput = ({ type = "text", onChange, ...props }) => (
  <TextField type={type} onChange={onChange} {...props} />
);
const TextAreaInput = ({ rowsMax = 4, ...props }) => (
  <TextInput multiline rowsMax={rowsMax} {...props} />
);
const NumberInput = ({ ...props }) => <TextInput type="number" {...props} />;
const EmailInput = ({ ...props }) => <TextInput type="email" {...props} />;
const PasswordInput = ({ ...props }) => (
  <TextInput type="password" {...props} />
);
const SelectInput = ({ options = [], label, ...props }) => {
  const { id } = props;
  const classes = useStyles();

  return (
    <FormControl
      margin="normal"
      variant="outlined"
      className={classes.formControl}
    >
      <InputLabel>{label}</InputLabel>

      <Select
        inputProps={{
          id
        }}
        MenuProps={MenuProps}
        {...props}
      >
        {options.map((option, index) => {
          const Component = elements[option.element] || Divider;
          return option.element ? (
            <Component key={index} />
          ) : (
            <MenuItem key={index} value={option.code}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
const MenuInput = ({ options = [], label, onChange, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { id, disabled } = props;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <span>
      <Button
        aria-controls={id}
        aria-haspopup="true"
        variant="outlined"
        onClick={handleClick}
        disabled={disabled}
      >
        {label}
      </Button>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => {
          const Component = elements[option.element] || Divider;
          return option.element ? (
            <Component key={index} />
          ) : (
            <MenuItem
              key={index}
              value={option.code}
              onClick={() => {
                handleClose();
                onChange(option);
              }}
              title={option.tooltip}
              disabled={option.disabled}
            >
              {option.icon && <img src={option.icon} alt={option.name} />}
              {option.name}
            </MenuItem>
          );
        })}
      </Menu>
    </span>
  );
};

const MenuInputNew = ({ options = [], label, onChange, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { id, disabled } = props;
  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls={id}
        aria-haspopup="true"
        variant={props.display === "text" ? "text" : "outlined"}
        onClick={handleClick}
        disabled={disabled}
        className={classes.button}
        style={{
          width: "100%",
          padding: "0px 10px 0px 10px",
          display: "flex"
        }}
      >
        {label}
        <div
          style={{
            flexGrow: "1"
          }}
        >
          {" "}
        </div>
        <ArrowDropDown />
      </Button>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => {
          const Component = elements[option.element] || Divider;
          return option.element ? (
            <Component key={index} />
          ) : (
            <MenuItem
              key={index}
              value={option.code}
              onClick={() => {
                handleClose();
                onChange(option);
              }}
              title={option.tooltip}
              disabled={option.disabled}
            >
              {option.icon && <img src={option.icon} alt={option.name} />}
              {option.name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const DateInput = ({ label, name, value, onChange }) => (
  <FormControl margin="normal" variant="outlined">
    <div className="date-picker-control">
      <label htmlFor={label}>{label}</label>

      <DatePicker
        selected={value ? new Date(value) : null}
        dateFormat="MMMM d, yyyy"
        onChange={v => {
          const formatted = dayjs(v).format("YYYY-MM-DD");
          onChange(name, formatted);
        }}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </div>
  </FormControl>
);

const CheckboxInput = ({ label, id, value, onChange }) => (
  <FormControl margin="normal" variant="outlined">
    <div className="date-picker-control">
      <label htmlFor={id}>{label}</label>
      <input type="checkbox" id={id} checked={value} onChange={onChange} />
    </div>
  </FormControl>
);

const ButtonInput = ({ label, onClick, icon, variant, color, disabled }) => (
  <span>
    <Button
      variant={variant || "outlined"}
      onClick={onClick}
      startIcon={icon ? <img src={icon} alt="" /> : null}
      color={color}
      disabled={disabled}
    >
      {label}
    </Button>
  </span>
);

const SwitchInput = ({ label, value, onChange }) => (
  <FormControlLabel
    control={<Switch checked={value} onChange={onChange} color="primary" />}
    label={label}
  />
);

const FileInput = ({ label, onChange }) => (
  <span>
    <Button variant="outlined" component="label">
      {label}
      <input type="file" style={{ display: "none" }} onChange={onChange} />
    </Button>
  </span>
);

const INPUTS = {
  text: TextInput,
  textarea: TextAreaInput,
  select: SelectInput,
  email: EmailInput,
  password: PasswordInput,
  number: NumberInput,
  date: DateInput,
  checkbox: CheckboxInput,
  menu: MenuInput,
  menuNew: MenuInputNew,
  button: ButtonInput,
  file: FileInput,
  switch: SwitchInput
};

const GridInput = ({
  id,
  label,
  placeholder,
  type = "text",
  intl,
  value,
  onChange,
  ...props
}) => {
  const Component = INPUTS[type] || TextInput;

  return (
    <Component
      id={id}
      label={label}
      onChange={onChange}
      value={value}
      {...props}
    />
  );
};

export default injectIntl(GridInput);
