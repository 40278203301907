/** This is a queue that stores attributes pending to be saved
 * We only execute save once every 5 seconds
 */
export const ATTRIBUTES_WRITER_LOCAL_STORAGE_INDEX = "PENDING_ATTRIBUTES";
export const PRODUCTS_UPDATE_SHEET_ITEM_BUFFER_INDEX = "PENDING_SHEET_ITEMS";
export const PRODUCTS_WRITER_LOCAL_STORAGE_INDEX = "PENDING_PRODUCTS";
export const PRODUCTS_INSERT_BUFFER_LOCAL_STORAGE_INDEX =
  "INSERT_PENDING_PRODUCTS";
export const PRODUCTS_DELETE_BUFFER_LOCAL_STORAGE_INDEX =
  "DELETE_PENDING_PRODUCTS";

export class WriterBuffer {
  constructor(writerBufferKey) {
    this.writerBufferKey = writerBufferKey;
    const dataRequest = localStorage.getItem(this.writerBufferKey);
    if (!dataRequest) {
      localStorage.setItem(this.writerBufferKey, JSON.stringify({}));
    }
  }

  addDataItemForUpdate = dataItem =>
    this.addDataItem(this.writerBufferKey, dataItem, dataItem.id);

  getItemsForUpdate = () => this.getItems(this.writerBufferKey);

  clearItemsForUpdate = () => this.clearItems(this.writerBufferKey);

  // TODO: set up TTL to expire old items
  addDataItem = (key, dataItem, id) => {
    const dataItems = JSON.parse(localStorage.getItem(key));
    dataItems[id] = dataItem;
    localStorage.setItem(key, JSON.stringify(dataItems));
  };

  getItems = key => {
    return JSON.parse(localStorage.getItem(key));
  };

  clearItems = key => {
    localStorage.setItem(key, JSON.stringify({}));
  };
}
