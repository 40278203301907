import React from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AddIcon from "@material-ui/icons/Add";
import ChipInput from "material-ui-chip-input";
import Box from "@material-ui/core/Box";
// import Typography from "@material-ui/core/Typography";

import AssetPreview from "components/app/AssetPreview";

import "./style.css";
import { Button } from "@material-ui/core";

export const UploadModal = () => {
  const [values, setValues] = React.useState({
    filename: "",
    tags: []
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleTagsChange = prop => event => {
    setValues({ ...values, [prop]: event });
  };

  const clearTags = prop => () => {
    setValues({ ...values, [prop]: [] });
  };

  const clearField = prop => () => {
    setValues({ ...values, [prop]: "" });
  };

  return (
    <>
      <h3 className="title">Upload single assets</h3>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        marginTop="32px"
      >
        <TextField
          type="text"
          label="File name"
          className="textfield"
          variant="outlined"
          value={values.filename}
          onChange={handleChange("filename")}
          error
          helperText="Input has to be provided."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={clearField("filename")}
                  aria-label="clear field"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <ChipInput
          label="Tags"
          variant="outlined"
          className="textfield"
          value={values.tags}
          onChange={handleTagsChange("tags")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={clearTags("tags")}
                  aria-label="clear field"
                  className="tags-adronment-fix"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <AssetPreview
          tag="PNG"
          image="https://freeiconshop.com/wp-content/uploads/edd/csv-outline.png"
        />
        <Box marginTop="24px">
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<DoneIcon />}
          >
            Save file
          </Button>
        </Box>
        <Box
          className="drop-zone"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          fontWeight={500}
        >
          <CloudUploadIcon className="grey icon" />
          <p>Drag and drop a file here</p>
          <p>or</p>
          <Button variant="contained" size="large" startIcon={<AddIcon />}>
            Choose file
          </Button>
        </Box>
      </Box>
    </>
  );
};
