import React, { useState } from "react";
import HelperButton from "./helperButton";
import { useTestHelper } from "components/app/grid";

export default function HtmlRenderer({
  value,
  data,
  typecast,
  api,
  column,
  node,
  onHelperClick,
  editable,
  onTesting,
  intl
}) {
  const [isHelperVisible, setIsHelperVisible] = useState(false);
  const testTooltip = useTestHelper({
    value,
    data,
    api,
    node,
    column,
    onTesting,
    intl
  });
  const isEditable = editable && editable({ column, data });

  const handleMouseEnter = () => {
    setIsHelperVisible(true);
  };

  const handleMouseLeave = () => {
    setIsHelperVisible(false);
  };

  const handleHelperClick = () => {
    onHelperClick({ api, node, column, value });
  };

  return (
    <div
      className="ag-cell-container ag-cell-with-helper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span>{value}</span>
      <div className="cell-helpers-wrapper">
        {isHelperVisible && isEditable && (
          <HelperButton type={typecast} onClick={handleHelperClick} />
        )}
        {isHelperVisible && testTooltip}
      </div>
    </div>
  );
}
