import React from "react";
import { AppBar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
// import { H4 } from "components/ui";
const useStyles = makeStyles(theme => ({
  link: {
    display: "flex",
    fontSize: "29px",
    background: "#eceff1",
    border: "1px solid #eceff1",
    borderRadius: "8%"
  }
}));

const Context = () => {
  const classes = useStyles();
  const defaults = {
    path: window.location.pathname,
    name: window.location.name,
    icon: "flash",
    color: "#FFF"
  };

  if (defaults.path[1] === "") return null;
  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" style={{ paddingLeft: "35px" }}>
        <Link color="inherit" to={defaults.path} className={classes.link}>
          {defaults.path}
        </Link>
      </Breadcrumbs>
    </div>
  );
};

export default ({ title, children }) => {
  return (
    <AppBar
      position="static"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: "0.25em",
        paddingBottom: "0.25em",
        backgroundColor: "#fff",
        boxShadow: "none"
      }}
    >
      <div
        style={{
          display: "flex",

          alignItems: "center",
          paddingLeft: "0.85em",
          paddingRight: "0.25em"
        }}
      >
        <img
          src="/images/misc/sheetly-logo-bars.png"
          alt="logo"
          style={{
            height: "32px",
            marginRight: "1.0em"
          }}
        />

        <h4 style={{ fontSize: "25px" }}>{title}</h4>
        <Context />
      </div>

      <div>{children}</div>
    </AppBar>
  );
};
