import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { ExitToApp as LogoutIcon } from "@material-ui/icons";
import { AuthContext } from "../../auth/authContext";
const UserInfo = () => {
  const { user, logout } = useContext(AuthContext);

  return (
    <>
      <div style={{ display: "flex" }}>
        <Link to="/settings" style={{ width: "32px", height: "32px" }}>
          <img
            src={user.picture}
            alt={user.name}
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              marginRight: "0.5em"
            }}
          />
        </Link>

        <IconButton
          color="inherit"
          aria-label="Logout"
          onClick={() => logout()}
        >
          <LogoutIcon />
        </IconButton>
      </div>
    </>
  );
};

export default UserInfo;
