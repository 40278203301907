import React from "react";

import { AuthenticatedLayout } from "components/app";
function BcCustomFieldWizard() {
  return (
    <AuthenticatedLayout title="BC custom field wizard">
      {/* Content */}
    </AuthenticatedLayout>
  );
}

export default BcCustomFieldWizard;
