/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import Gallery from "react-photo-gallery";
import { SheetDataService } from "lib";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { Input, useLoadMore } from ".";
import { MARGINS } from "lib/constants";
import { Loader } from "components/ui";

const cont = {
  overflow: "hidden",
  position: "relative"
};

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "#0091ea",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3"
    }
  }
});

const Checkmark = ({ selected }) => (
  <div
    className="gallery-image__checkmark"
    style={!selected ? { display: "none" } : {}}
  >
    <svg
      style={{ fill: "white", position: "absolute" }}
      width="24px"
      height="24px"
    >
      <circle cx="12.5" cy="12.2" r="8.292" />
    </svg>
    <svg
      style={{ fill: "#06befa", position: "absolute" }}
      width="24px"
      height="24px"
    >
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  </div>
);

const scrollToRef = (ref, settings) => ref.current.scrollIntoView(settings);

const SelectedImage = ({
  index,
  photo,
  margin,
  direction,
  top,
  left,
  selected,
  onSelect,
  onClick,
  active,
  getCellValue,
  firstViewRow,
  showCaptionOnHover
}) => {
  const [isSelected, setIsSelected] = useState(selected);
  const [isActive, setIsActive] = useState(active);
  const [statusClass, setStatusClass] = useState("");
  const classes = useStyles();
  const imageRef = useRef(null);

  if (direction === "column") {
    cont.position = "absolute";
    cont.left = left;
    cont.top = top;
  }

  useEffect(() => {
    if (firstViewRow === index && firstViewRow !== 0) {
      scrollToRef(imageRef, { block: "start" });
    }

    const value = getCellValue("sys_overall_health", photo.rowData);
    const className = getClassFromValue(value);
    setStatusClass(className);
  }, []);

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  useEffect(() => {
    setIsActive(active);
    if (active) {
      handleActive();
    }
  }, [active]);

  const handleSelect = e => {
    setIsSelected(!isSelected);
    onSelect(photo.rowData, !isSelected);
  };

  const handleActive = e => {
    onClick(index, photo.rowData);
  };

  return (
    <div
      style={{ margin, ...cont }}
      className={`gallery-image${isActive ? " gallery-image--selected" : ""}`}
      ref={imageRef}
    >
      {showCaptionOnHover && isSelected && <Checkmark selected={isSelected} />}
      <figure>
        {photo.rowData.entity_row_id ? (
          <img
            className={`gallery-image__img${
              isSelected ? " gallery-image__img--selected" : ""
            }`}
            src={photo.src}
            alt={photo.title}
            width={photo.width}
            height={photo.height}
            onClick={handleActive}
          />
        ) : (
          <div
            className="gallery-image__virgin_row-placeholder"
            style={{ height: photo.height }}
            onClick={handleActive}
          />
        )}
        <figcaption
          className={`gallery-image__figcaption ${statusClass} ${
            showCaptionOnHover ? "figcaption_onHover" : ""
          }`}
          style={{ width: photo.width }}
        >
          {photo.caption &&
            photo.caption.map((caption, index) =>
              index === 0 ? (
                <p key={index}>
                  <Checkbox
                    checked={isSelected}
                    color="primary"
                    disableRipple
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                    icon={<span className={classes.icon} />}
                    onClick={handleSelect}
                  />
                  {caption}
                </p>
              ) : (
                <p key={index}>{caption}</p>
              )
            )}
        </figcaption>
      </figure>
    </div>
  );
};

export default ({
  data = [],
  settings = {},
  onSelect,
  active,
  onActive,
  selectedRows,
  getCellValue,
  firstViewRow,
  className
}) => {
  const [imagesState, setImagesState] = useState({});
  const [activeImage, setActiveImage] = useState(active);
  const [loading, setLoading] = useState(false);
  const initialLoad = useRef(true);
  const [dataToShow, loadMoreFunc] = useLoadMore(
    data,
    firstViewRow ? firstViewRow + 10 : 20, // initial load
    20, // per page
    initialLoad.current // suppress loading more on mount
  );
  const showCaptionOnHover = settings.caption_on_hover;

  useEffect(() => {
    if (
      dataToShow.length > 0 &&
      data.length > 0 &&
      Object.keys(settings).length > 0
    ) {
      getImagesData();
      initialLoad.current = false;
    }
  }, [dataToShow, data, settings]);

  const getImagesData = async () => {
    setLoading(true);

    const imagesData = await SheetDataService.extractImages(
      dataToShow,
      settings
    );

    setImagesState({
      margins: MARGINS[settings.image_margin],
      data: imagesData
    });
    setLoading(false);
  };

  const handleClick = (index, rowData) => {
    setActiveImage(index);
    onActive(rowData, index);
  };

  const imageRenderer = ({ index, left, top, key, photo }) => (
    <SelectedImage
      key={key}
      selected={checkSelection(selectedRows, photo)}
      margin={imagesState.margins}
      index={index}
      photo={photo}
      left={left}
      top={top}
      onSelect={onSelect}
      onClick={handleClick}
      active={activeImage === index}
      getCellValue={getCellValue}
      firstViewRow={firstViewRow}
      showCaptionOnHover={showCaptionOnHover}
    />
  );

  return (
    <div className={`gallery ${className}`}>
      {imagesState.data && (
        <>
          <Gallery
            photos={JSON.parse(JSON.stringify(imagesState.data))}
            renderImage={imageRenderer}
            targetRowHeight={SheetDataService.imageHeightFromSettings(
              settings.image_size
            )}
          />
          {imagesState.data.length < data.length && (
            <div className="gallery--load_more">
              <Input
                type="button"
                onClick={loadMoreFunc}
                label="Load more"
                variant="contained"
                color="primary"
                disabled={loading}
              />
            </div>
          )}
        </>
      )}
      {loading && (
        <div className="gallery--loader">
          <Loader size={100} color="#1AACE3" />
        </div>
      )}
    </div>
  );
};

function checkSelection(selectedNodes = [], currentRow = {}) {
  return () => {
    if (!(currentRow.rowData && selectedNodes.length > 0)) return false;
    const isSelected = selectedNodes.some(
      node => node.data.id === currentRow.rowData.id
    );
    return isSelected;
  };
}

function getClassFromValue(value) {
  let _className;
  if (value > 999) {
    _className = "critical";
  } else if (value > 0) {
    _className = "warning";
  } else if (value === 0) {
    _className = "good";
  } else {
    _className = "";
  }
  return _className;
}
