import React from "react";
import { GridController } from "../../../components/app/grid/gridController";
import { AttributesGridMenuController } from "./attributesGridMenuController";
import { AttributesGridOptions } from "./attributesGridOptions";

export class AttributesGridController extends GridController {
  constructor(columns, rows, urlParams, intl, writerCache) {
    super(columns, rows, urlParams, intl, writerCache);
    this.initData();
    const attributesGridMenuController = new AttributesGridMenuController(this);
    const attributesGridOptions = new AttributesGridOptions(this);
    this.initGridConfigurationClasses(
      attributesGridMenuController,
      attributesGridOptions
    );
  }
}
