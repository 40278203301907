import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
// import { StitchProvider, StitchContext } from "stitch";
import theme from "./components/app/theme";
import { I18nProvider } from "./lib";
import { AuthWrapperComponent } from "./auth/authWrapperComponent";

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <I18nProvider locale="en">
        <AuthWrapperComponent>
          {/* <StitchProvider>
        <StitchContext.Consumer>
          {({ loggedIn }) => ( */}
          {/*)}
        </StitchContext.Consumer>
           </StitchProvider> */}
        </AuthWrapperComponent>
      </I18nProvider>
    </MuiThemeProvider>
  );
};

export default App;
