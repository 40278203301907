import React from "react";

/**
 * Auth context with default values that can be overriden at provider
 * @type {React.Context<{setUser: setUser, user: {}}>}
 */
export const AuthContext = React.createContext({
  user: {},
  setUser: () => {}
});
