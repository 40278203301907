import React, { useState } from "react";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import humanize from "humanize-string";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Divider
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Icon } from "components/ui";
import { Storage } from "lib";
import Announcement from "./Announcement";
import TitleBar from "./TitleBar";
import UserInfo from "../UserInfo";
import Tools from "./Tools";
import Navigation from "./Navigation";
import InspectorBarTeleport from "./InspectorBarTeleport";

const PRIMARY_NAV = [
  {
    id: 10,
    label: "Sheets",
    code: "sheets",
    to: "/sheets",
    icon: "barcode"
  },
  {
    id: 20,
    label: "Tools",
    code: "tools",
    to: "/tools",
    icon: "tools"
  }
];

const SECONDARY_NAV = [
  {
    id: 400,
    label: "Settings",
    code: "settings",
    to: "/settings",
    icon: "settings"
  }
];

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#000",
    fontSize: 16
  }
}))(Tooltip);

const Workbench = ({ title = "Sheetly", notification, intl, children }) => {
  const [open, setOpen] = useState(Storage.get("s_sidebar_open"));

  const toggleSidebar = open => {
    Storage.set("s_sidebar_open", !open);
    setOpen(!open);
  };

  const titleBarHeight = 56;
  const notificationHeight = notification ? 58 : 0;
  const headerHeight = titleBarHeight + notificationHeight;

  return (
    <div>
      {notification && <Announcement>{notification}</Announcement>}

      <TitleBar title={title} onToggle={() => toggleSidebar(open)}>
        <UserInfo />
      </TitleBar>

      <div
        style={{
          display: "flex",
          height: `calc(100vh - ${headerHeight}px)`,
          backgroundColor: "#fafafa"
        }}
      >
        <Navigation open={open}>
          <List component="nav" aria-label="Main mailbox folders">
            {PRIMARY_NAV.map(item => (
              <LightTooltip
                disableHoverListener={open}
                key={item.id}
                enterDelay={500}
                title={intl.formatMessage({
                  id: `sidebar.${item.code}`,
                  defaultMessage: humanize(item.label)
                })}
                placement="right"
              >
                <ListItem key={item.id} button component={Link} to={item.to}>
                  <ListItemIcon>
                    <Icon size="24" stroke="#FFF" line="1" glyph={item.icon} />
                  </ListItemIcon>
                  {open ? (
                    <ListItemText
                      style={{
                        paddingLeft: "2px"
                      }}
                      primary={intl.formatMessage({
                        id: `sidebar.${item.code}`,
                        defaultMessage: humanize(item.label)
                      })}
                    />
                  ) : (
                    <ListItemText primary="" />
                  )}
                </ListItem>
              </LightTooltip>
            ))}
          </List>

          <List component="nav" aria-label="Main mailbox folders">
            {SECONDARY_NAV.map(item => (
              <LightTooltip
                disableHoverListener={open}
                key={item.id}
                enterDelay={500}
                title={intl.formatMessage({
                  id: `sidebar.${item.code}`,
                  defaultMessage: humanize(item.label)
                })}
                placement="right"
              >
                <ListItem key={item.id} button component={Link} to={item.to}>
                  <ListItemIcon>
                    <Icon size="24" stroke="#FFF" line="1" glyph={item.icon} />
                  </ListItemIcon>
                  {open ? (
                    <ListItemText
                      style={{
                        paddingLeft: "2px"
                      }}
                      primary={intl.formatMessage({
                        id: `sidebar.${item.code}`,
                        defaultMessage: humanize(item.label)
                      })}
                    />
                  ) : (
                    <ListItemText primary="" />
                  )}
                </ListItem>
              </LightTooltip>
            ))}

            <Divider />

            <ListItem button onClick={() => toggleSidebar(open)}>
              <ListItemIcon>
                {open ? (
                  <Icon size="24" stroke="#FFF" line="1" glyph="left" />
                ) : (
                  <Icon size="24" stroke="#FFF" line="1" glyph="right" />
                )}
              </ListItemIcon>
              {open ? (
                <ListItemText
                  style={{
                    paddingLeft: "2px"
                  }}
                  primary={intl.formatMessage({
                    id: `sidebar.collapse`,
                    defaultMessage: humanize("Collapse")
                  })}
                />
              ) : (
                <ListItemText primary="" />
              )}
            </ListItem>
          </List>
        </Navigation>

        <div style={{ width: "100%", borderLeft: "1px solid #ddd" }}>
          <Tools />

          <div
            style={{
              padding: "1em",
              height: "calc(100vh - 56px - 53px)",
              overflow: "auto"
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap"
              }}
            >
              <div style={{ width: "100%" }}>{children}</div>

              <InspectorBarTeleport.Target />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Workbench);
