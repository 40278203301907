import React from "react";

import { AuthenticatedLayout } from "components/app";
function Elements() {
  return (
    <AuthenticatedLayout title="Elements">{/* Content */}</AuthenticatedLayout>
  );
}

export default Elements;
