import React from "react";
import { AuthContext } from "./authContext";
import jwtDecode from "jwt-decode";
import { AuthenticatedRouter, GuestRouter } from "../lib";
import getConfig from "../getConfig";
import auth0 from "auth0-js";

const LOCAL_STORAGE_USER_KEY = "user";
const LOCAL_STORAGE_RESPONSE_KEY = "session_data";

const LOCAL_STORAGE_EXPIRATION_KEY = "session_duration";

export class AuthWrapperComponent extends React.Component {
  constructor(props) {
    super(props);
    const { domain, auth } = getConfig();
    this.auth0 = new auth0.WebAuth({
      domain: auth.domain,
      clientID: auth.clientID,
      audience: auth.audience,
      redirectUri: auth.redirect,
      responseType: "token id_token",
      scope: "openid email profile"
    });
    const setUser = response => {
      this.setState({
        ...this.state,
        user: jwtDecode(response.idToken),
        accessToken: response.accessToken,
        tokens: response,
        isAuthenticated: true
      });
      this.storeSession(response);
    };
    const logout = () => {
      localStorage.removeItem(LOCAL_STORAGE_RESPONSE_KEY);
      localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
      localStorage.removeItem(LOCAL_STORAGE_EXPIRATION_KEY);
      this.auth0.logout({
        returnTo: window.location.origin
      });
    };
    this.state = {
      user: JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY)),
      tokens: JSON.parse(localStorage.getItem(LOCAL_STORAGE_RESPONSE_KEY)),
      isAuthenticated: this.isAuthenticatedHelper(),
      setUser,
      logout
    };
  }

  storeSession(response) {
    localStorage.setItem(LOCAL_STORAGE_RESPONSE_KEY, JSON.stringify(response));
    localStorage.setItem(
      LOCAL_STORAGE_USER_KEY,
      JSON.stringify(jwtDecode(response.idToken))
    );
    localStorage.setItem(
      LOCAL_STORAGE_EXPIRATION_KEY,
      response.expiresIn * 1000 + new Date().getTime()
    );
  }

  isAuthenticatedHelper() {
    // check if logged in/out after page refresh
    const sessionExpirationTime = localStorage.getItem(
      LOCAL_STORAGE_EXPIRATION_KEY
    );
    if (!sessionExpirationTime) return false;
    return new Date().getTime() < sessionExpirationTime;
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          user: this.state.user,
          tokens: this.state.tokens,
          setUser: this.state.setUser,
          logout: this.state.logout
        }}
      >
        {this.state.isAuthenticated && <AuthenticatedRouter />}
        {!this.state.isAuthenticated && <GuestRouter />}
      </AuthContext.Provider>
    );
  }
}
