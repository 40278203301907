import React from "react";

export default ({ open, children }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      backgroundColor: "#27b1e3",
      width: open ? "200px" : "60px",
      overflow: "hidden"
    }}
  >
    {children}
  </div>
);
