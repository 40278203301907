/* eslint-disable react/sort-comp */
import React from "react";
import { toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Toolbar,
  H5,
  Panel,
  Icon,
  LinkButton,
  P,
  Modal,
  Preview
} from "components/ui";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  GridList,
  GridListTile,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
// import { Registry } from "lib";
import { Field } from "lib/Form";
import { InfoCard, Element, Filter, Entity } from "models";
import ContentLoader from "react-content-loader";
import {
  SettingsLayout,
  InspectorBar,
  ResourceForm
} from "../../components/app";

const columns = [
  {
    name: "id",
    label: "Id",
    options: {
      filter: false,
      display: "excluded"
    }
  },
  {
    name: "code",
    label: "Code",
    options: {
      filter: false,
      display: "excluded"
    }
  },
  {
    name: "icon",
    label: "Icon",
    options: {
      filter: false,
      customBodyRender: value => {
        return <Icon size="24" line="1" glyph={value} />;
      }
    }
  },
  {
    name: "name",
    label: "Name",
    options: {
      filter: false
    }
  },
  {
    name: "entityCode",
    Label: "Sheet",
    options: {
      filter: true
    }
  },
  {
    name: "channel",
    label: "Channel",
    options: {
      filter: true
    }
  },
  {
    name: "criticalLevel",
    label: "Critical (from)",
    options: {
      filter: false
    }
  },
  {
    name: "warningLevel",
    label: "Warning (from)",
    options: {
      filter: false
    }
  }
];

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "stacked",
  selectableRows: "none",
  print: false,
  download: false,
  elevation: 0
};

// Should be moved to a separate folder for reuse across screens
const SettingsListLoader = () => (
  <ContentLoader
    height={300}
    width={400}
    speed={2}
    primaryColor="#c0c0c0"
    secondaryColor="#ecebeb"
  >
    <rect x="0" y="15" rx="1" ry="1" width="300" height="100" />
    <rect x="10" y="2" rx="1" ry="1" width="100" height="5" />
    <circle cx="4" cy="4" r="4" />
  </ContentLoader>
);

const Loading = () => (
  <SettingsLayout>
    <SettingsListLoader />
  </SettingsLayout>
);

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#000",
    fontSize: 16
  }
}))(Tooltip);

export default class InfocardsIndex extends React.Component {
  state = {
    current: null,
    createModalVisible: false,
    editModalVisible: false,
    iconsModalVisible: false,
    filtersModalVisible: false,
    deleteDialogVisible: false,
    entitiesModalVisible: false,
    uploadModalVisible: false,
    currentIcon: "barcode",
    currentEntity: "products",
    currentFilter: null,
    loading: true,
    items: [],
    itemsIcons: [],
    itemsFilters: [],
    itemsEntities: []
  };

  componentWillMount = async () => {
    await this.fetch();
    await this.fetchElements();
    await this.fetchFilters();
    await this.fetchEntities();
  };

  resource = () => InfoCard;

  resourceElement = () => Element;

  resourceFilter = () => Filter;

  resourceEntity = () => Entity;

  // Handlers for the CRUD - needs to get a return value from the back end

  fetch = async () => {
    const items = await this.resource().all();
    this.setState({ items, loading: false });
  };

  fetchElements = async () => {
    const itemsIcons = await this.resourceElement().allIcons();
    this.setState({ itemsIcons });
  };

  fetchFilters = async () => {
    const itemsFilters = await this.resourceFilter().all();
    this.setState({ itemsFilters });
  };

  fetchEntities = async () => {
    const itemsEntities = await this.resourceEntity().all();
    this.setState({ itemsEntities });
  };

  setNewIcon(value) {
    this.setState({ currentIcon: value });
    this.setState({ iconsModalVisible: false });
  }

  setNewFilter(id) {
    this.setState({ currentFilter: id });
    this.setState({ filtersModalVisible: false });
  }

  setNewEntity(value) {
    this.setState({ currentEntity: value });
    this.setState({ entitiesModalVisible: false });
  }

  create = async ({ values }) => {
    await this.resource().create(values);
    await this.fetch();

    toast("Infocard was created");
  };

  update = async ({ id, values }) => {
    await this.resource().update(id, values);
    await this.fetch();

    // toast('Infocard updated');
  };

  deleteRecord = async ({ id }) => {
    await this.resource().destroy(id);
    await this.fetch();

    // toast('Infocard was deleted');
  };

  handleUpdate(values, currentIcon) {
    this.setState({ editModalVisible: false });
    // eslint-disable-next-line no-param-reassign
    values.icon = currentIcon;
    this.update({ id: values.id, values });
  }

  handleCreate(values, currentIcon, currentEntity, currentFilter) {
    this.setState({ createModalVisible: false });
    // eslint-disable-next-line no-param-reassign
    values.icon = currentIcon;
    // eslint-disable-next-line no-param-reassign
    values.entityCode = currentEntity;
    // eslint-disable-next-line no-param-reassign
    values.filterCode = currentFilter;
    this.create({ values });
  }

  handleDelete(currentRecord) {
    this.setState({ deleteDialogVisible: false });
    const cid = currentRecord ? currentRecord.id : null;
    this.deleteRecord({ id: cid });
  }

  openEdit(currentRecord) {
    this.setState({ currentIcon: currentRecord.icon });
    this.setState({ editModalVisible: true });
  }

  openDelete() {
    this.setState({ deleteDialogVisible: true });
  }

  openNew(defaults) {
    this.setState({ currentIcon: defaults.icon });
    this.setState({ currentEntity: defaults.entityCode });
    this.setState({ currentFilter: defaults.filterCode });
    this.setState({ createModalVisible: true });
  }

  render() {
    const {
      loading,
      items,
      itemsFilters,
      itemsIcons,
      itemsEntities,
      current,
      createModalVisible,
      editModalVisible,
      iconsModalVisible,
      entitiesModalVisible,
      filtersModalVisible,
      deleteDialogVisible,
      uploadModalVisible,
      currentIcon,
      currentFilter,
      currentEntity
    } = this.state;

    // const user = Registry.get("user");
    // const oid = user ? user.ownerId : null;

    // Set default values for new infocard docs being created
    const defaults = {
      name: "New Card",
      channel: "core",
      criticalLevel: "0",
      warningLevel: "0",
      entityCode: "products",
      filterCode: "select",
      icon: "barcode",
      bgClass: "#fff",
      updatedAt: null,
      active: true,
      value: 0,
      // ownerId: oid,
      position: "0"
    };

    // Set default values for new asset doc being created
    const defaultAsset = {
      name: "New Asset",
      // ownerId: oid,
      fileType: "SVG",
      fileExtension: "svg",
      systemContext: "icon",
      fileName: "PhysicalFileName",
      tags: "icon"
    };

    // Pause to allow data to load and props to
    if (loading) return <Loading />;

    const currentRecord = current ? items.find(b => b.id === current) : null;

    return (
      <SettingsLayout title="Infocards">
        <Toolbar>
          <Button
            ui="secondary"
            size="small"
            onClick={() => this.setState({ createModalVisible: true })}
          >
            New
          </Button>
          <LinkButton
            size="small"
            to="http://support.sheetly.io"
            target="_blank"
            ui="secondary"
          >
            <Icon size="12" line="1" glyph="brain" />
            Learn More
          </LinkButton>
        </Toolbar>
        <Panel>
          <MUIDataTable
            title="Infocard list"
            data={items}
            columns={columns}
            options={{
              ...options,
              onRowClick: row => this.setState({ current: row[0] })
            }}
          />
          {/*           <Fab
            color="primary"
            onClick={() => this.setState({ createModalVisible: true })}
          >
            <AddIcon />
          </Fab> */}
        </Panel>
        {currentRecord && (
          <InspectorBar open={current} size="small">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <H5>Inspector</H5>
              <IconButton
                style={{ padding: "0px" }}
                onClick={() => this.setState({ current: null })}
              >
                <Icon size="24" line="1" glyph="bigx" />
              </IconButton>
            </div>

            {!loading && (
              <div>
                <Toolbar>
                  <Button
                    ui="secondary"
                    size="small"
                    onClick={() => this.openEdit(currentRecord)}
                  >
                    Edit
                  </Button>
                  <Button
                    ui="secondary"
                    color="secondary"
                    size="small"
                    onClick={() => this.setState({ deleteDialogVisible: true })}
                  >
                    Delete
                  </Button>
                </Toolbar>
                <Panel>
                  <Preview label="Sheet" value={currentRecord.entityCode} />
                </Panel>
                <br />
                <Panel>
                  <Preview label="Name" value={currentRecord.name} />
                  <Preview label="Channel" value={currentRecord.channel} />
                  <Preview label="Source" value={currentRecord.filterCode} />
                </Panel>
                <br />
                <Panel>
                  <Preview
                    label="Critical (from)"
                    value={currentRecord.criticalLevel}
                  />
                  <Preview
                    label="Warning (from)"
                    value={currentRecord.warningLevel}
                  />
                  <Preview label="Icon name" value={currentRecord.icon}>
                    <Icon
                      size="18"
                      line="1"
                      glyph={currentRecord.icon}
                      style={{ paddingLeft: "10px" }}
                    />
                  </Preview>
                  <Preview label="Position" value={currentRecord.position} />
                </Panel>
              </div>
            )}
          </InspectorBar>
        )}
        {/* The edit form */}
        <Modal
          open={editModalVisible}
          onClose={() => this.setState({ editModalVisible: false })}
        >
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <H5>Edit Infocard</H5>
              <IconButton
                style={{ padding: "0px" }}
                onClick={() => this.setState({ editModalVisible: false })}
              >
                <Icon size="24" line="1" glyph="bigx" />
              </IconButton>
            </div>
            <Toolbar>
              <Button
                size="small"
                ui="secondary"
                onClose={() => this.setState({ editModalVisible: false })}
              >
                Clone
              </Button>
            </Toolbar>
            <ResourceForm
              id="specialform"
              resource="infocard"
              initialValues={currentRecord}
              onSubmit={({ values }) => this.handleUpdate(values, currentIcon)}
            >
              {() => (
                <>
                  <Panel>
                    <Preview label="Sheet" value={currentRecord.entityCode} />
                  </Panel>
                  <br />
                  <Panel>
                    <Field name="name" required />
                    <Field
                      input="select"
                      name="channel"
                      options={[
                        { value: "core", label: "Core" },
                        { value: "xyz", label: "Somethinge else" }
                      ]}
                    />
                    <Preview label="Source" value={currentRecord.filterCode} />
                  </Panel>
                  <br />
                  <Panel>
                    <Field name="criticalLevel" />
                    <Field name="warningLevel" />
                    <Preview label="Icon" placeholder="">
                      <Button
                        ui="secondary"
                        size="small"
                        onClick={() =>
                          this.setState({ iconsModalVisible: true })
                        }
                      >
                        Select Icon
                      </Button>
                      <Icon
                        size="18"
                        line="1"
                        glyph={currentIcon}
                        style={{ paddingLeft: "10px" }}
                      />
                    </Preview>
                    <Field name="position" />
                  </Panel>
                  <br />
                  <Button ui="secondary" type="submit">
                    Save
                  </Button>
                </>
              )}
            </ResourceForm>
          </div>
        </Modal>
        {/* The create form */}
        <Modal
          open={createModalVisible}
          onClose={() => this.setState({ createModalVisible: false })}
        >
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <H5>Create Infocard</H5>
              <IconButton
                style={{ padding: "0px" }}
                onClick={() => this.setState({ createModalVisible: false })}
              >
                <Icon size="24" line="1" glyph="bigx" />
              </IconButton>
            </div>
            <br />
            <ResourceForm
              id="specialcreateform"
              initialValues={defaults}
              resource="infocard"
              onSubmit={({ values }) =>
                this.handleCreate(
                  values,
                  currentIcon,
                  currentFilter,
                  currentEntity
                )
              }
            >
              {() => (
                <>
                  <Panel>
                    <Preview label="Sheet" value={defaults.entityCode}>
                      <Button
                        ui="secondary"
                        size="small"
                        onClick={() =>
                          this.setState({ entitiesModalVisible: true })
                        }
                      >
                        Select Sheet
                      </Button>
                    </Preview>
                  </Panel>
                  <br />
                  <Panel>
                    <Field
                      name="name"
                      placeholder="a proper name for the card"
                      required
                    />

                    <Field
                      input="select"
                      name="channel"
                      options={[
                        { value: "core", label: "Core" },
                        { value: "xyz", label: "Somethinge else" }
                      ]}
                    />
                    <Preview label="Source" value={defaults.filterCode}>
                      <Button
                        ui="secondary"
                        size="small"
                        onClick={() =>
                          this.setState({ filtersModalVisible: true })
                        }
                      >
                        Select Source
                      </Button>
                    </Preview>
                  </Panel>
                  <br />
                  <Panel>
                    <Field name="criticalLevel" />
                    <Field name="warningLevel" />
                    <Preview label="Icon" placeholder="">
                      <Button
                        ui="secondary"
                        size="small"
                        onClick={() =>
                          this.setState({ iconsModalVisible: true })
                        }
                      >
                        Select Icon
                      </Button>
                      <Icon
                        size="18"
                        line="1"
                        glyph={currentIcon}
                        style={{ paddingLeft: "10px" }}
                      />
                    </Preview>
                    <Field name="position" />
                  </Panel>
                  <br />
                  <Button ui="secondary" type="submit">
                    Save
                  </Button>
                </>
              )}
            </ResourceForm>
          </div>
        </Modal>
        {/* The icons select */}
        <Modal
          size="small"
          open={iconsModalVisible}
          onClose={() => this.setState({ iconsModalVisible: false })}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "1em"
              }}
            >
              <H5>Select Icon</H5>
              <IconButton
                style={{ padding: "0px" }}
                onClick={() => this.setState({ iconsModalVisible: false })}
              >
                <Icon size="24" line="1" glyph="bigx" />
              </IconButton>
            </div>
            <Toolbar>
              <Button
                size="small"
                ui="secondary"
                onClick={() => this.setState({ uploadModalVisible: true })}
              >
                Upload
              </Button>
            </Toolbar>
            <Panel>
              <GridList cellHeight={48} cols={10}>
                {itemsIcons.map(icon => (
                  <LightTooltip
                    key={icon.id}
                    enterDelay={250}
                    title={icon.name}
                    placement="right"
                  >
                    <GridListTile
                      key={icon.code}
                      cols={1}
                      onClick={() => this.setNewIcon(icon.code)}
                    >
                      <img src={icon.path} alt={icon.code} />
                    </GridListTile>
                  </LightTooltip>
                ))}
              </GridList>
            </Panel>
          </div>
        </Modal>
        {/* The filter select */}
        <Modal
          size="small"
          open={filtersModalVisible}
          onClose={() => this.setState({ filtersModalVisible: false })}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "1em"
              }}
            >
              <H5>Select Filter</H5>
              <IconButton
                style={{ padding: "0px" }}
                onClick={() => this.setState({ filtersModalVisible: false })}
              >
                <Icon size="24" line="1" glyph="bigx" />
              </IconButton>
            </div>
            <Toolbar />
            <Panel>
              <List component="nav">
                {itemsFilters.map(filter => (
                  <ListItem
                    key={filter.id}
                    button
                    onClick={() => this.setNewFilter(filter.id)}
                  >
                    <ListItemIcon>
                      <Icon size="24" line="1" glyph="barcode" />
                    </ListItemIcon>
                    <ListItemText
                      primary={filter.name}
                      secondary={filter.desc}
                    />
                  </ListItem>
                ))}
              </List>
            </Panel>
          </div>
        </Modal>
        {/* The entity select */}
        <Modal
          size="small"
          open={entitiesModalVisible}
          onClose={() => this.setState({ entitiesModalVisible: false })}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "1em"
              }}
            >
              <H5>Select Entity</H5>
              <IconButton
                style={{ padding: "0px" }}
                onClick={() => this.setState({ entitiesModalVisible: false })}
              >
                <Icon size="24" line="1" glyph="bigx" />
              </IconButton>
            </div>
            <Toolbar />
            <Panel>
              <List component="nav">
                {itemsEntities.map(entity => (
                  <ListItem
                    key={entity.id}
                    button
                    onClick={() => this.setNewEntity(entity.code)}
                  >
                    <ListItemIcon>
                      <Icon size="24" line="1" glyph={entity.icon} />
                    </ListItemIcon>
                    <ListItemText primary={entity.name} />
                  </ListItem>
                ))}
              </List>
            </Panel>
          </div>
        </Modal>
        {/* The upload select */}
        <Modal
          size="mini"
          open={uploadModalVisible}
          onClose={() => this.setState({ uploadModalVisible: false })}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "1em"
              }}
            >
              <H5>Upload Single Asset</H5>
              <IconButton
                style={{ padding: "0px" }}
                onClick={() => this.setState({ uploadModalVisible: false })}
              >
                <Icon size="24" line="1" glyph="bigx" />
              </IconButton>
            </div>
            <Toolbar />
            <Panel>
              <div style={{ height: 100, textAlign: "center" }}>
                <H5>Dropzone</H5>
                <P>Files has to be of type SVG</P>
              </div>
            </Panel>
            <br />
            <Panel>
              <H5>Asset details</H5>
              <P>
                This will create an asset which you can find later in the Asset
                sheet
              </P>
              <ResourceForm
                resource="asset"
                initialValues={defaultAsset}
                // onSubmit={({ values }) => this.update({ id: me.id, values })}
              >
                {() => (
                  <>
                    <Field name="name" />
                    <Field name="tags" />
                    <Preview label="File Name" value={defaultAsset.fileName} />
                    <Preview label="File Type" value={defaultAsset.fileType} />
                    <Preview
                      label="File Extension"
                      value={defaultAsset.fileExtension}
                    />
                    <Preview
                      label="Context"
                      value={defaultAsset.systemContext}
                    />
                    <Button ui="secondary" type="submit">
                      Save
                    </Button>
                  </>
                )}
              </ResourceForm>
            </Panel>
          </div>
        </Modal>

        {/* The delete dialog */}
        <Dialog
          open={deleteDialogVisible}
          onClose={() => this.setState({ deleteDialogVisible: false })}
        >
          <DialogTitle id="alert-dialog-title">Delete InfoCard</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the infocard?
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              {currentRecord && currentRecord.name}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ deleteDialogVisible: false })}
              ui="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => this.handleDelete(currentRecord)}
              ui="secondary"
              color="secondary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </SettingsLayout>
    );
  }
}
