import React from "react";
import { ProductsGridMenuController } from "./productsGridMenuController";
import { ProductsGridOptions } from "./productsGridOptions";
import { GridController } from "../../../components/app/grid/gridController";
import SheetDataService from "../../../lib/SheetDataService";
// TODO : implement views for products sheet
import viewJSON from "../../../dummyData/view.json";
import entityJSON from "../../../dummyData/entity.json";
import filterJSON from "../../../dummyData/filter.json";
import settingsJSON from "../../../dummyData/settings.json";
import { AttributesDataService } from "../../attributes-static-sheet/services/attributes-data-service";
import { ProductsDataService } from "../services/products-data-service";
import { combineLatest } from "rxjs";

export const PENDING_INSERT = "PENDING_INSERT";

export class ProductsGridController extends GridController {
  constructor(
    columns,
    rows,
    urlParams,
    intl,
    writerCache,
    sheetData,
    attributesDataService,
    productsDataService,
    rawProductData
  ) {
    super(columns, rows, urlParams, intl, writerCache);
    this.sheetData = sheetData;
    this.initData();
    this.rawProductData = rawProductData;
    this.attributesDataService = attributesDataService;
    this.productsDataService = productsDataService;
    const productsGridMenuController = new ProductsGridMenuController(this);
    const productsGridOptions = new ProductsGridOptions(this);
    this.initGridConfigurationClasses(
      productsGridMenuController,
      productsGridOptions
    );
  }

  initData() {
    // TODO: only execute subscription if value has changed since last time
    this.initialStateSubscription = combineLatest(
      AttributesDataService.attributesObservable$,
      ProductsDataService.productsObservable$,
      ProductsDataService.channelsObservable$,
      ProductsDataService.testsObservable$
    ).subscribe(([attributesNext, productsNext, channelsNext, testsNext]) => {
      const currentChannel = SheetDataService.getChannelData(
        channelsNext,
        this.urlParams.channel
      );
      this._cols = attributesNext;
      this.rawProductData = this.normalizeRawData(productsNext);
      this._rows = this.normalizeProductsForDisplay(
        this.rawProductData,
        currentChannel.code
      );

      this.currentChannel = currentChannel;
      this.allChannels = channelsNext;
      this.tests = testsNext;
      this.initialStateSubject.next({
        columns: attributesNext,
        data: this._rows,
        allChannels: channelsNext,
        selectedChannel: currentChannel,
        rawData: this.rawProductData,
        tests: this.tests,
        loading: false
      });
    });

    // TODO : implement views for products sheet
    this.viewSubject.next(
      SheetDataService.getView(viewJSON, this.urlParams.view)
    );
    this.setsObservable$.subscribe(sets => {
      this.sets = sets;
    });
    this.changedRowsSubject.next([]);
    this.changedRowsObservable$.subscribe(changedRows => {
      this.changedRows = changedRows;
    });
    // Get Sheet object
    // TODO: do we need url params. See products initData
    this.sheetDataSubject.next(this.sheetData);

    // Get View object
    // TODO: do we need url params. See products initData
    const viewObj = SheetDataService.getView(viewJSON);
    this.viewObjectSubject.next(
      this.urlParams.alternative
        ? { ...viewObj, alternative: this.urlParams.alternative }
        : viewObj
    );

    // Get full Rows data based on channel
    // TODO: implement logic to filter based on channel if necessary
    // const rowsData = SheetDataService.getRowsData(
    //   dataJSON,
    //   gridController.urlParams.channel
    // );
    // gridController._rows = rowsData;
    // gridController.rowsSubject.next(gridController._rows);

    // // Channel data based on channel url param
    // const channelData = SheetDataService.getChannelData(
    //     channelJSON,
    //     this.urlParams.channel
    // );
    // this.channelsSubject.next(channelData);

    // Get sets and lookups
    // TODO: check logic
    // const sets = SheetDataService.getDropdownOptions(
    //     attributeJSON,
    //     lookupJSON,
    //     setJSON,
    //     urlParams.channel
    // );
    // setSets(sets);

    // Get entitiesSubject
    const entity = entityJSON.length > 0 ? entityJSON[0] : {};
    this.entitiesSubject.next(entity);

    // Get viewsSubject
    this.viewsSubject.next(viewJSON);

    // Get saved filters
    this.savedFiltersSubject.next(filterJSON);

    // Get settings
    this.settingsSubject.next(settingsJSON[0]);
    // TODO: get settings from data
    this.settings = settingsJSON[0];

    // Get channelsSubject
    // this.channelsSubject.next(channelJSON);

    this.setsSubject.next([]);
  }

  unsubscribe() {
    this.initialStateSubscription.unsubscribe();
  }

  normalizeProductsForDisplay(products, channelCode) {
    return Object.keys(products).reduce((aggregateProducts, key) => {
      let normalizedProduct = {
        id: products[key].entityRow.id
      };
      normalizedProduct = {
        ...normalizedProduct,
        ...products[key].entityRow.channels[channelCode].attributes
      };

      aggregateProducts.push(normalizedProduct);
      return aggregateProducts;
    }, []);
  }

  normalizeRawData(products) {
    return products.reduce((aggregateProducts, product) => {
      aggregateProducts[product.entityRowId] = {
        ...product
      };
      return aggregateProducts;
    }, {});
  }
}
