import axios from "axios";

// TODO: align on the usage of one api service strategy. Observable vs Promise based
const API_URL = `${process.env.REACT_APP_SHEET_SERVICE_URL}/`;
class HttpService {
  static header() {
    const token = localStorage.getItem("accessToken");

    if (!token) return {};
    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  }

  static async formData(url, formData) {
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };
    return await axios
      .post(`${API_URL}${url}`, formData, config)
      .then(resp => resp);
  }

  static async post(url, params = {}) {
    return await axios
      .post(`${API_URL}${url}`, params, this.header())
      .then(res => res);
  }

  static async put(url, params = {}) {
    return await axios
      .put(`${API_URL}${url}`, params, this.header())
      .then(res => res);
  }

  static async delete(url, params = {}) {
    return await axios
      .delete(`${API_URL}${url}`, params, this.header())
      .then(res => res);
  }

  static async get(url, params = {}) {
    const header = this.header();
    const body = {
      body: params
    };
    const paramWithHeader = { ...header, body };
    return axios.get(`${API_URL}${url}`, paramWithHeader).then(res => res);
  }
}

export default HttpService;
