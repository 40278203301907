import { GridMenuController } from "../../../components/app/grid/gridMenuController";
import SheetDataService from "../../../lib/SheetDataService";
import { v4 as uuidv4 } from "uuid";
import { PENDING_INSERT } from "./productsGridController";
import { Decimal } from "decimal.js-light";

export class ProductsGridMenuController extends GridMenuController {
  constructor(gridController) {
    super(gridController);
  }

  getContextMenuItems({ api, defaultItems, node, value, column }) {
    if (node == null || column == null) return null;

    let menuItems = defaultItems.splice(0);
    const colState = this.gridController.gridOptions.colStateFromColumn(
      this.gridController._cols,
      column
    );
    const rangeSelection = api.getCellRanges();
    const testing = this.gridController.handleTesting(
      { api, value, column, node },
      colState
    );
    const testsData = SheetDataService.testsMappings(
      testing,
      this.gridController.tests
    );
    // TODO: check if we need to check for is Editable in the menu
    // const isEditable = this.gridController.handleEditable(
    //   { column, data: node.data },
    //   colState
    // );
    let numberSelectedRows = 1;

    menuItems.splice(0, 0, {
      name: "Health Information",
      shortcut: "Ctrl + Q",
      action: () => {
        this.gridController.isModalOpenSubject.next(true);
        this.gridController.modalContentSubject.next({
          type: "cellTestInfo",
          params: { testsData },
          isDialog: true
        });
      },
      disabled: testsData.length === 0
    });

    // TODO: see if this item is needed in Entity Sheets
    // menuItems.splice(1, 0, {
    //   name: "Show Cell Helper",
    //   shortcut: "Ctrl + H",
    //   action: () => {
    //     handleHelperClick({ api, node, column, value });
    //   },
    //   disabled: !(isEditable && colTypesWithHelpers.includes(colState.typecast))
    // });

    menuItems.splice(2, 0, "separator");

    // TODO: see if the items below are needed in Entity Sheets

    // menuItems.splice(3, 0, generateSampleVariant(rangeSelection));

    // menuItems.splice(4, 0, {
    //   name: "Connect To Master",
    //   action: () => {},
    //   disabled: true
    // });
    //
    // menuItems.splice(5, 0, {
    //   name: "Remove From Master",
    //   action: () => {},
    //   disabled: true
    // });
    //
    // menuItems.splice(6, 0, {
    //   name: "Create Master For Samples",
    //   action: () => {},
    //   disabled: true
    // });
    //
    // menuItems.splice(7, 0, {
    //   name: "Create Samples For Master",
    //   action: () => {},
    //   disabled: true
    // });

    menuItems.splice(8, 0, "separator");

    menuItems.splice(9, 0, {
      name: "Cut",
      icon:
        '<span class="ag-icon ag-icon-copy" unselectable="on" role="presentation"></span>',
      shortcut: "Ctrl + X",
      action: () => {
        api.copySelectedRangeToClipboard();
        node.setDataValue(column.colId, "");
      }
    });

    menuItems.splice(11, 1);

    menuItems.splice(12, 0, "separator");

    menuItems.splice(13, 0, {
      name: "Undo",
      shortcut: "Ctrl + Z",
      action: () => {
        api.undoCellEditing();
      }
    });

    menuItems.splice(14, 0, {
      name: "Redo",
      shortcut: "Ctrl + Y",
      action: () => {
        api.redoCellEditing();
      }
    });

    // Menu items for Row Header ( Row Number )
    if (column.colId === "rowNumber" && rangeSelection.length === 1) {
      menuItems = [];
      const startRow = rangeSelection[rangeSelection.length - 1].startRow;
      const endRow = rangeSelection[rangeSelection.length - 1].endRow;
      const fromUpToDown = endRow.rowIndex > startRow.rowIndex;
      numberSelectedRows = fromUpToDown
        ? endRow.rowIndex + 1 - startRow.rowIndex
        : startRow.rowIndex + 1 - endRow.rowIndex;

      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Select ${numberSelectedRows} rows`
            : "Select row",
        //icon: '',
        shortcut: "Ctrl + A",
        action: () => {
          this.rowsSelection(rangeSelection, true);
        }
      });
      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Unselect ${numberSelectedRows} rows`
            : "Unselect row",
        //icon: '',
        action: () => {
          this.rowsSelection(rangeSelection, false);
        }
      });
      menuItems.push("separator");
      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Insert ${numberSelectedRows} rows below`
            : "Insert row below",
        //icon: '',
        action: () =>
          this.insertRows(
            numberSelectedRows,
            fromUpToDown ? endRow : startRow,
            false
          ),
        disabled: false
      });
      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Insert ${numberSelectedRows} rows above`
            : "Insert row above",
        //icon: '',
        shortcut: "Ctrl + I",
        action: () =>
          this.insertRows(
            numberSelectedRows,
            fromUpToDown ? startRow : endRow,
            true
          ),
        disabled: false
      });
      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Clear ${numberSelectedRows} rows`
            : "Clear row",
        //icon: '',
        action: () => {
          this.clearRows(rangeSelection);
        },
        disabled: true
      });
      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Delete ${numberSelectedRows} rows`
            : "Delete row",
        //icon: '',
        shortcut: "Ctrl + D",
        action: () => {
          this.gridController.isModalOpenSubject.next(true);
          this.gridController.modalContentSubject.next({
            type: "deleteConfirm",
            params: {
              number: numberSelectedRows,
              fromUpToDown: fromUpToDown ? startRow : endRow,
              rangeSelection
            },
            isDialog: true
          });
        },
        cssClasses: ["text-danger"],
        disabled: this.isDeleteEnabled(
          numberSelectedRows,
          fromUpToDown ? startRow : endRow
        )
      });
      menuItems.push("separator");
      menuItems.push({
        name: "Clone Row With Data",
        action: () => {
          this.cloneRow(node);
        },
        disabled: true
      });
    }

    return menuItems;
  }

  insertRows(insertNumber, selectedRow, above) {
    if (!selectedRow) return;

    // calculates the sequence rank before and after the items to be inserted.
    const firstExistingRowIndex = above
      ? selectedRow.rowIndex - 1
      : selectedRow.rowIndex;
    const lastExistingRowIndex = firstExistingRowIndex + 1;
    const firstExistingRowSeq = this.gridController.generateFirstRowSeq(
      firstExistingRowIndex
    );
    const lastExistingRowSeq = this.gridController.generateLastRowSeq(
      lastExistingRowIndex,
      firstExistingRowSeq
    );
    const blankRows = this.generateBlankRows(
      insertNumber,
      this.gridController.sheetData,
      firstExistingRowSeq,
      lastExistingRowSeq,
      this.gridController.urlParams.channel,
      this.gridController._cols
    );
    const rowIndex = above ? selectedRow.rowIndex : selectedRow.rowIndex + 1;

    // TODO: check if we need to can propagate ag grid changes to the products component state using immutable updates
    this.setRowsForInsert(blankRows);
    this.gridController._rows.splice(rowIndex, 0, ...blankRows);
    this.gridController.gridApi.setRowData(this.gridController._rows);
  }

  /**
   * Generates a blank row and updates RawProducts data with a row in pending state
   * @param counter
   * @param sheetData
   * @param firstExistingSeq
   * @param lastExistingSeq
   * @param currentChannel
   * @param columns
   * @returns {[]}
   */
  generateBlankRows(
    counter,
    sheetData,
    firstExistingSeq,
    lastExistingSeq,
    currentChannel,
    columns
  ) {
    const blankRow = {};
    const channelsRaw = {
      [this.gridController.currentChannel.code]: {
        attributes: {}
      }
    };
    channelsRaw[
      this.gridController.currentChannel.code
    ].code = this.gridController.currentChannel.code;
    const blankRows = [];
    for (let i = 0; i < counter; i++) {
      // TODO: see if we need preicsion over 20 decimals. If yes discuss options.
      // TODO: one possible solution is to set precision based on current decimal signs of seqBefore and increase with some value
      // calculate the seq before and after the row to be inserted and divide by 2
      const currentItemSeq = this.gridController.generateItemSeq(
        firstExistingSeq,
        lastExistingSeq,
        i,
        blankRows
      );
      // Assign a temporary uuid. This will be replaced once the item is inserted in the db
      const generatedId = uuidv4();
      const blankRowWithId = {
        ...blankRow,
        id: generatedId
      };
      blankRows.push(blankRowWithId);
      // Include the new row in the rawProductData to handle updates

      this.gridController.rawProductData[generatedId] = {
        entityRowId: generatedId,
        seq: currentItemSeq.toString(),
        sheetId: sheetData.id,
        entityRow: {
          entityId: sheetData.entityId,
          id: generatedId,
          organizationId: sheetData.organizationId,
          tagEntityRows: [],
          channels: channelsRaw
        },
        // Extra flag indicating that the row is not in a clean state. This will prevent rows without uuid to be sent to the backend
        state: PENDING_INSERT,
        pendingRow: blankRowWithId
      };
    }
    return blankRows;
  }

  isDeleteEnabled(numberSelectedRows, selectedRow) {
    for (let i = 0; i < numberSelectedRows; i++) {
      const row = this.gridController._rows[selectedRow.rowIndex];
      if (this.gridController.rawProductData[row.id].state === PENDING_INSERT)
        return true;
    }
    return false;
  }

  deleteRows(selectedRow, numberSelectedRows, rangeSelection) {
    for (let i = 0; i < numberSelectedRows; i++) {
      const entityRowId = this.gridController._rows[selectedRow.rowIndex].id;
      const dataItem = {
        entityRowId,
        sheetId: this.gridController.sheetData.id
      };
      this.gridController._rows.splice(selectedRow.rowIndex, 1);
      delete this.gridController.rawProductData[entityRowId];
      this.gridController.writerCache.addDataItemForDelete(
        dataItem,
        entityRowId
      );
    }
    const nodes = this.getRangeSelectedNodes(
      this.gridController.gridApi,
      rangeSelection
    );
    let nodesToDelete = [];
    nodes.forEach(node => {
      nodesToDelete.push(node.data);
    });
    this.gridController.gridApi.applyTransaction({ remove: nodesToDelete });
  }

  setRowsForInsert(blankRows) {
    blankRows.forEach(blankRow => {
      this.gridController.gridOptions.onCellChange({ data: blankRow });
    });
  }
}
