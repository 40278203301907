import { Subject } from "rxjs";
import {
  RxHttpService,
  sheetDataServiceFullAddress
} from "../../../lib/RxHttpService";

export class ProductsDataService {
  // Subject is used as using BehaviourSubject or ReplaySubject causes unnecessary multiple executions of the subscritptions
  // observables are static to ensure sharing data amongst multiple components is possible. Serves as alternative to passing the services in the context
  static productsSubject = new Subject(1);
  static productsObservable$ = ProductsDataService.productsSubject.asObservable();
  static channelsSubject = new Subject(1);
  static channelsObservable$ = ProductsDataService.channelsSubject.asObservable();
  static testsSubject = new Subject(1);
  static testsObservable$ = ProductsDataService.testsSubject.asObservable();
  rxApiService = new RxHttpService();

  readTests(accessToken) {
    const readTestsRequest = {
      url: `${sheetDataServiceFullAddress}/tests`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    };
    this.rxApiService.rxGetData(readTestsRequest, tests => {
      ProductsDataService.testsSubject.next(tests);
    });
  }

  readChannels(organisationId, accessToken) {
    const readChannelsRequest = {
      url: `${sheetDataServiceFullAddress}/channels/${organisationId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    };
    this.rxApiService.rxGetData(readChannelsRequest, channels => {
      ProductsDataService.channelsSubject.next(channels);
    });
  }

  readItems(sheetId, accessToken) {
    const readProductsRequest = {
      url: `${sheetDataServiceFullAddress}/entity-rows/read/${sheetId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    };
    this.rxApiService.rxGetData(readProductsRequest, products => {
      ProductsDataService.productsSubject.next(products);
    });
  }

  updateItems(accessToken, requests, successCallback) {
    const createProductsRequest = {
      url: `${sheetDataServiceFullAddress}/entity-rows`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: requests
    };
    this.rxApiService.rxPutData(createProductsRequest, successCallback);
  }

  updateSheetItems(accessToken, requests, successCallback) {
    const updateSheetItemsRequest = {
      url: `${sheetDataServiceFullAddress}/sheet-entity-rows`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: requests
    };
    this.rxApiService.rxPutData(updateSheetItemsRequest, successCallback);
  }

  createProducts(accessToken, requests, successCallback) {
    const createProductsRequest = {
      url: `${sheetDataServiceFullAddress}/entity-rows/create-and-assign`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: requests
    };
    this.rxApiService.rxPostData(createProductsRequest, successCallback);
  }

  deleteProducts(accessToken, requests, successCallback) {
    const createProductsRequest = {
      url: `${sheetDataServiceFullAddress}/entity-rows/delete`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: requests
    };
    this.rxApiService.rxPostData(createProductsRequest, successCallback);
  }
}
