import React, { useEffect, useState } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import HttpService from "../../lib/HttpService";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
const OnBoarding = () => {
  let history = useHistory();

  const [error, setError] = useState(false);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("session_data"))
      .idTokenPayload;

    async function checkOnboarding() {
      try {
        // TODO: add an authorization header
        const response = await HttpService.get(
          `account/onBoarding/check/${user.email}`
        );

        if (response.data.onBoarded === true) {
          history.push("/sheets");
        }
        await HttpService.post("account", {
          fullname: user.name,
          email: user.email,
          signupMethod: "none"
        });
        history.push("/sheets");
      } catch (e) {
        setError(true);
        console.log(e.message, e.stack, "message here");
      }
    }

    checkOnboarding();
  }, [history]);

  const procceed = () => {
    history.push("/sheets");
  };

  const Error = () => {
    return (
      <>
        <div>
          <h1 style={{ fontSize: "12px", color: "red" }}>
            Problem with onboarding. Please try signup again
          </h1>
          <Button onClick={procceed}>Procceed without onboarding</Button>
        </div>
      </>
    );
  };

  const Loading = () => {
    return (
      <>
        <div>
          <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
        </div>
        <div>
          <h1 style={{ fontSize: "12px", color: "gray" }}>
            Checking onboarding setup ...
          </h1>
        </div>
      </>
    );
  };

  return (
    <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {error ? <Error /> : <Loading />}
      </div>
    </div>
  );
};

export default OnBoarding;
