import React from "react";

import { AuthenticatedLayout } from "components/app";
function Categories() {
  return (
    <AuthenticatedLayout title="Categories">
      {/* Content */}
    </AuthenticatedLayout>
  );
}

export default Categories;
