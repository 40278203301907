import { GridOptions } from "../../../components/app/grid/gridOptions";
import { PENDING_INSERT } from "./productsGridController";
import { DISPLAYS } from "../../../lib/constants";
import SheetDataService from "../../../lib/SheetDataService";

export class ProductsGridOptions extends GridOptions {
  constructor(gridController) {
    super(gridController);
    this.potentialParent = null;
  }

  onCellChange({ data, node, column, value }) {
    // Stop rows in unclean state from being updated on cell change
    const rawItem = this.gridController.rawProductData[data.id];
    const entityRow = rawItem.entityRow;
    let { id, ...deidentifiedEntityData } = data;
    entityRow.channels[this.gridController.currentChannel.code].attributes = {
      ...deidentifiedEntityData
    };
    switch (rawItem.state) {
      case PENDING_INSERT:
        this.gridController.writerCache.addDataItemForInsert(rawItem, data.id);
        break;
      default:
        super.onCellChange({ data: entityRow, node, column, value });
    }
  }

  valueParser(colState) {
    return params => {
      if (this.gridController.urlParams.alternative === DISPLAYS.hierarchy) {
        return SheetDataService.lookupKey(
          colState,
          this.sets[colState.code],
          params.newValue,
          this.settings[DISPLAYS.hierarchy]
        );
      }
      return SheetDataService.lookupKey(
        colState,
        this.sets[colState.code],
        params.newValue
      );
    };
  }

  valueGetter(colState) {
    return ({ api, data, node }) => {
      // Do not execute tests in case entityRow has just been inserted
      if (this.gridController.isRowEmpty(data)) return;
      if (colState.display === DISPLAYS.entityAttribute) {
        return colState.key.split(".").reduce((acc, val) => {
          return acc[val];
        }, data);
      }

      // TODO: simplify the value getter if possible
      if (colState.display === DISPLAYS.overall_health) {
        if (data.entity_row_id === null) return;
        let _levelTestsScore = 0;

        this.gridController._cols.forEach(col => {
          const columnTests = SheetDataService.getColumnTestsByChannel(
            col,
            this.gridController.urlParams.channel
          );
          let _nodes = [];

          api.forEachNode(rowNode => {
            rowNode.id !== node.id &&
              rowNode.data &&
              _nodes.push(rowNode.data[col.code]);
          });

          const isValidType = SheetDataService.testTypecast(
            data[col.code],
            col.typecast,
            this.sets[col.code]
          );

          columnTests.forEach(test => {
            const testPassed = SheetDataService.testValue(
              data[col.code],
              test,
              _nodes
            );

            if (!testPassed) {
              if (test.level === "critical") {
                _levelTestsScore += 1000;
              } else if (test.level === "warning") {
                _levelTestsScore += 1;
              }
            }
          });

          if (!isValidType) {
            _levelTestsScore += 1000;
          }
        });

        // Update server test score through webSocket
        //WSContext.addData(_levelTestsScore);
        return _levelTestsScore;
      }

      if (colState.display === DISPLAYS.hierarchy && data.entity_row_id) {
        return (
          this.settings[DISPLAYS.hierarchy] &&
          (data[this.settings[DISPLAYS.hierarchy].attribute] ||
            this.settings[DISPLAYS.hierarchy]["empty_label"])
        );
      }

      return data[colState.code];
    };
  }

  valueSetter(colState) {
    return ({ data, newValue, oldValue, column }) => {
      if (
        colState.display === DISPLAYS.hierarchy &&
        this.settings[DISPLAYS.hierarchy]
      ) {
        data[this.settings[DISPLAYS.hierarchy].attribute] = newValue;
        return true;
      }
      data[column.colId] = newValue;
      return true;
    };
  }

  handleRowDragEnd({ api, node }) {
    if (
      this.gridController.urlParams.alternative !== DISPLAYS.hierarchy ||
      !this.potentialParent
    ) {
      this.updateLocalRowsAndSeq(api, node);
      return;
    }

    const movingData = node.data;
    const newParentPath =
      this.potentialParent.data &&
      (this.potentialParent.data.sys_hierarchy || this.potentialParent.data.id)
        ? this.potentialParent.data.sys_hierarchy || [
            this.potentialParent.data.id
          ]
        : [];
    const needToChangeParent = !this.arePathsEqual(
      newParentPath,
      movingData.sys_hierarchy || [movingData.id]
    );
    const invalidMode = this.isSelectionParentOfTarget(
      node,
      this.potentialParent
    );
    if (invalidMode) {
      console.log("invalid move");
    }
    if (needToChangeParent && !invalidMode) {
      const updatedRows = [];
      this.moveToPath(newParentPath, node, updatedRows);
      this.gridController.gridApi.applyTransaction({ update: updatedRows });
      //gridApi.clearFocusedCell();
    }
    this.setPotentialParentForNode(
      api,
      null,
      node,
      this.gridController.entities
    );

    this.updateLocalRows(api);
    // Update server data through webSocket
    //WSContext.sendData(params.data);
  }

  updateLocalRows(api) {
    const _newNodes = [];
    api.forEachNode(node => {
      _newNodes.push(node.data);
    });
    this.gridController._rows = _newNodes;
  }

  updateLocalRowsAndSeq(api, newNode) {
    // TODO: optimize the row move using unmanagedRowDragging if possible: https://www.ag-grid.com/javascript-grid/row-dragging/
    // make no update if index is not changed;
    if (this.gridController._rows[newNode.childIndex].id === newNode.id) return;
    const _newNodes = [];
    api.forEachNode(node => {
      if (newNode.id === node.id) {
        const nodeSeqIndices = this.calculateIndicesAfterDragEnd(newNode);
        const firstExistingRowSeq = this.gridController.generateFirstRowSeq(
          nodeSeqIndices.top
        );
        const lastExistingRowSeq = this.gridController.generateLastRowSeq(
          nodeSeqIndices.bottom,
          firstExistingRowSeq
        );
        const currentItemSeq = this.gridController.generateItemSeq(
          firstExistingRowSeq,
          lastExistingRowSeq
        );
        this.gridController.rawProductData[
          node.id
        ].seq = currentItemSeq.toString();
        const rawProductItemForUpdate = {
          sheetId: this.gridController.rawProductData[node.id].sheetId,
          entityRowId: this.gridController.rawProductData[node.id].entityRowId,
          seq: this.gridController.rawProductData[node.id].seq
        };
        this.gridController.writerCache.addSheetItemForUpdate(
          rawProductItemForUpdate,
          node.id
        );
      }
      _newNodes.push(node.data);
    });
    this.gridController._rows = _newNodes;
  }

  refreshRows(api, rowsToRefresh) {
    const params = {
      rowNodes: rowsToRefresh,
      force: true
    };
    api.refreshCells(params);
  }

  isSelectionParentOfTarget(selectedNode, targetNode) {
    const children = selectedNode.childrenAfterGroup;
    for (let i = 0; i < children.length; i++) {
      if (targetNode && children[i].key === targetNode.key) return true;
      this.isSelectionParentOfTarget(children[i], targetNode);
    }
    return false;
  }

  moveToPath(newParentPath, node, allUpdatedNodes) {
    const oldPath = node.data.sys_hierarchy || [node.data.id];
    const groupName = oldPath[oldPath.length - 1];
    const newChildPath = newParentPath.slice();
    newChildPath.push(groupName);
    node.data.sys_hierarchy = newChildPath;
    allUpdatedNodes.push(node.data);
    if (node.childrenAfterGroup) {
      node.childrenAfterGroup.forEach(childNode => {
        this.moveToPath(newChildPath, childNode, allUpdatedNodes);
      });
    }
  }

  arePathsEqual(path1 = [], path2 = []) {
    if (path1.length !== path2.length) {
      return false;
    }
    let equal = true;
    path1.forEach(function(item, index) {
      if (path2[index] !== item) {
        equal = false;
      }
    });
    return equal;
  }

  setPotentialParentForNode(api, overNode, node, entity = {}) {
    let newPotentialParent;
    const hasChildren = entity.code === "products" && node.allChildrenCount > 0;
    if (overNode && !hasChildren) {
      newPotentialParent =
        !(
          !overNode.data.entity_row_id ||
          (overNode.data.sys_hierarchy &&
            overNode.data.sys_hierarchy.length > 1)
        ) || overNode.allChildrenCount > 0
          ? overNode
          : overNode.parent;
    } else {
      newPotentialParent = null;
    }
    const alreadySelected = this.potentialParent === newPotentialParent;
    if (alreadySelected) {
      return;
    }
    const rowsToRefresh = [];
    if (this.potentialParent) {
      rowsToRefresh.push(this.potentialParent);
    }
    if (newPotentialParent) {
      rowsToRefresh.push(newPotentialParent);
    }
    this.potentialParent = newPotentialParent;
    this.refreshRows(api, rowsToRefresh);
  }

  /**
   * Calculate the correct indices for seq calculation based on the initial position of the node.
   * @param node
   */
  calculateIndicesAfterDragEnd(node) {
    // TODO: optimize the row move using unmanagedRowDragging if possible: https://www.ag-grid.com/javascript-grid/row-dragging/
    let index = 0;
    for (const row of this.gridController._rows) {
      if (row.id === node.id) {
        // Initial index is smaller - node moved towards the bottom of the sheet. All nodes move 1 up
        if (index > node.childIndex) {
          return {
            top: node.childIndex - 1,
            bottom: node.childIndex
          };
        } else {
          // Initial index is bigger. Node moved towards the top of the sheet
          return {
            top: node.childIndex,
            bottom: node.childIndex + 1
          };
        }
      }
      index = index + 1;
    }
  }
}
