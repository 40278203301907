import React from "react";
import { AuthenticatedLayout } from "components/app";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./sheets.css";
const Sheets = () => {
  let location = useLocation().pathname.split("/")[1];
  const SHEET_CARDS = [
    {
      name: "Products",
      image: "images/clip-1.png",
      path: `${location}/all-products`
    },
    {
      name: "Categories",
      image: "images/clip-2.png",
      path: `${location}/all-categories`
    },
    {
      name: "Assets",
      image: "images/clip-2.png",
      path: `${location}/all-assets`
    },
    {
      name: "Buisness",
      image: "images/clip-2.png",
      path: `${location}/all-business`
    },
    {
      name: "Attributes",
      image: "images/clip-2.png",
      path: `${location}/attributes`
    },
    {
      name: "Elements",
      image: "images/clip-2.png",
      path: `${location}/elements`
    },
    {
      name: "Lookups",
      image: "images/clip-2.png",
      path: `${location}/lookups`
    },
    {
      name: "Views",
      image: "images/clip-2.png",
      path: `${location}/views`
    },
    {
      name: "Sets",
      image: "images/clip-2.png",
      path: `${location}/sets`
    }
  ];

  return (
    <AuthenticatedLayout title="Sheets">
      <div className="box">
        {SHEET_CARDS.map(item => {
          return (
            <div style={{ padding: "20px" }}>
              <Link to={item.path}>
                <Card style={{ maxWidth: "345px" }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="Card"
                      height="140"
                      image="/images/clip-1.png"
                      title={item.name}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {item.name}
                      </Typography>
                      {/* <Typography variant="body2" color="textSecondary" component="p">
                      611 rows
                    </Typography> */}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </div>
          );
        })}
      </div>
    </AuthenticatedLayout>
  );
};
export default Sheets;
