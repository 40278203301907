import React from "react";

import { AuthenticatedLayout } from "components/app";
function ConnectionWizard() {
  return (
    <AuthenticatedLayout title="Connection Wizard">
      {/* Content */}
    </AuthenticatedLayout>
  );
}

export default ConnectionWizard;
