import React from "react";
import Settings from "@material-ui/icons/Settings";
import Filter from "@material-ui/icons/Filter";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// import Icon from "../../../ui/Icon";

import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const ProductToolbox = () => {
  return (
    <div>
      <Tooltip title="Filter">
        <IconButton aria-label="filter">
          <Filter />
        </IconButton>
      </Tooltip>
      <Tooltip title="Settings">
        <IconButton aria-label="Settings">
          <Settings />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ProductToolbox;
