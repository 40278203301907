import React, { useEffect, useContext } from "react";
import Auth0Lock from "auth0-lock";
import getConfig from "../../getConfig";
import { AuthContext } from "../../auth/authContext";
import { Mixpanel } from "../../lib/MixPanel";
const LOCK_DOM_ID = "auth0-login-container";

const Lock = () => {
  const { setUser } = useContext(AuthContext);
  const config = getConfig();
  const lock = new Auth0Lock(config.auth.clientID, config.auth.domain, {
    auth: {
      redirectUrl: config.auth.redirect,
      responseType: "token id_token",
      audience: config.auth.audience,
      sso: false
    },

    container: LOCK_DOM_ID,
    languageDictionary: {
      title: "Sheetly"
    },

    theme: {
      logo: "http://sheetly.io/assets/img/logo/sheetly-logo-15-blue.png",
      primaryColor: "#3a99d8"
    }
  });

  lock.on("authenticated", authResult => {
    setUser(authResult);
  });

  useEffect(() => {
    // Avoid showing Lock when hash is parsed.
    if (!/access_token|id_token|error/.test(window.location)) {
      lock.show({
        redirect: true
      });
    }
  }, [lock]);

  return (
    <div>
      <div id={LOCK_DOM_ID} />
    </div>
  );
};

export default Lock;
