import React from "react";

import { AuthenticatedLayout } from "components/app";
function ShopifyMetaFieldWiard() {
  return (
    <AuthenticatedLayout title="Shopify Meta field wizard">
      {/* Content */}
    </AuthenticatedLayout>
  );
}

export default ShopifyMetaFieldWiard;
