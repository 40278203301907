import React from "react";
import { Input } from "components/app/grid";
export const GalleryManager = ({ options, settings, handler }) => {
  const handleUpdate = e => {
    e.preventDefault();
    if (e.target.value > 3) {
      handler({
        ...settings,
        gallery: {
          ...settings.gallery,
          attributes: e.target.value
        }
      });
    }
  };
  return (
    <div>
      <h2 style={{ marginBottom: "12px" }}>Gallery Manager</h2>
      <div>
        <label>Attributes for caption:</label>
        <Input
          type="select"
          multiple
          label="Choose up to 3 attributes"
          onChange={handleUpdate}
          // options={options ? options.attributes : []}
          // value={settings.gallery ? settings.gallery.attributes : []}
        />
      </div>
      <div>
        <label>Margin between images:</label>
        <Input
          type="select"
          label="Select margin"
          onChange={handleUpdate}
          // options={options ? options.margins : []}
          // value={
          //   settings.gallery ? settings.gallery.image_margin : "no_margin"
          // }
        />
      </div>
      <div>
        <label>Image size:</label>
        <Input
          type="select"
          label="Select image size"
          onChange={handleUpdate}
          // options={options ? options.sizes : []}
          // value={
          //   settings.gallery ? settings.gallery.image_size : "thumbnail"
          // }
        />
      </div>
      <div>
        <Input
          type="switch"
          // value={
          //   settings.gallery ? settings.gallery.caption_on_hover : false
          // }
          label="Caption on hover"
          onChange={handleUpdate}
        />
      </div>
    </div>
  );
};

export default GalleryManager;
