import React from "react";
import { Button } from "components/ui";
import { DialogActions } from "@material-ui/core";

export const ActionSlider = ({
  name,
  setChildModalOpen,
  setChildModalContent,
  setIsModalOpen
}) => {
  return (
    <div>
      <h2>{name}</h2>
      <DialogActions>
        <Button
          ui="secondary"
          type="button"
          onClick={() => {
            setIsModalOpen(false);
          }}
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </div>
  );
};
