import SheetDataService from "../../../lib/SheetDataService";
import { v4 as uuidv4 } from "uuid";

// TODO: refactor to enable multiple sheets to reuse
export class GridMenuController {
  constructor(gridController) {
    this.gridController = gridController;
  }

  getContextMenuItems({ api, defaultItems, node, value, column }) {
    if (node == null || column == null) return null;

    let menuItems = defaultItems.splice(0);
    const colState = this.gridController.gridOptions.colStateFromColumn(
      this.gridController._cols,
      column
    );
    const rangeSelection = api.getCellRanges();
    const testing = this.gridController.handleTesting(
      { api, value, column, node },
      colState
    );
    const testsData = SheetDataService.testsMappings(testing, this.tests);
    // TODO: check if we need to check for is Editable in the menu
    // const isEditable = this.gridController.handleEditable(
    //   { column, data: node.data },
    //   colState
    // );
    let numberSelectedRows = 1;

    menuItems.splice(0, 0, {
      name: "Health Information",
      shortcut: "Ctrl + Q",
      action: () => {
        this.gridController.isModalOpenSubject.next(true);
        this.gridController.modalContentSubject.next({
          type: "cellTestInfo",
          params: { testsData },
          isDialog: true
        });
      },
      disabled: testsData.length === 0
    });

    // TODO: see if this item is needed in Entity Sheets
    // menuItems.splice(1, 0, {
    //   name: "Show Cell Helper",
    //   shortcut: "Ctrl + H",
    //   action: () => {
    //     handleHelperClick({ api, node, column, value });
    //   },
    //   disabled: !(isEditable && colTypesWithHelpers.includes(colState.typecast))
    // });

    menuItems.splice(2, 0, "separator");

    // TODO: see if the items belo are needed in Entity Sheets

    // menuItems.splice(3, 0, generateSampleVariant(rangeSelection));

    // menuItems.splice(4, 0, {
    //   name: "Connect To Master",
    //   action: () => {},
    //   disabled: true
    // });
    //
    // menuItems.splice(5, 0, {
    //   name: "Remove From Master",
    //   action: () => {},
    //   disabled: true
    // });
    //
    // menuItems.splice(6, 0, {
    //   name: "Create Master For Samples",
    //   action: () => {},
    //   disabled: true
    // });
    //
    // menuItems.splice(7, 0, {
    //   name: "Create Samples For Master",
    //   action: () => {},
    //   disabled: true
    // });

    menuItems.splice(8, 0, "separator");

    menuItems.splice(9, 0, {
      name: "Cut",
      icon:
        '<span class="ag-icon ag-icon-copy" unselectable="on" role="presentation"></span>',
      shortcut: "Ctrl + X",
      action: () => {
        api.copySelectedRangeToClipboard();
        node.setDataValue(column.colId, "");
      }
    });

    menuItems.splice(11, 1);

    menuItems.splice(12, 0, "separator");

    menuItems.splice(13, 0, {
      name: "Undo",
      shortcut: "Ctrl + Z",
      action: () => {
        api.undoCellEditing();
      }
    });

    menuItems.splice(14, 0, {
      name: "Redo",
      shortcut: "Ctrl + Y",
      action: () => {
        api.redoCellEditing();
      }
    });

    // Menu items for Row Header ( Row Number )
    if (column.colId === "rowNumber" && rangeSelection.length === 1) {
      menuItems = [];
      const startRow = rangeSelection[rangeSelection.length - 1].startRow;
      const endRow = rangeSelection[rangeSelection.length - 1].endRow;
      const fromUpToDown = endRow.rowIndex > startRow.rowIndex;
      numberSelectedRows = fromUpToDown
        ? endRow.rowIndex + 1 - startRow.rowIndex
        : startRow.rowIndex + 1 - endRow.rowIndex;

      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Select ${numberSelectedRows} rows`
            : "Select row",
        //icon: '',
        shortcut: "Ctrl + A",
        action: () => {
          this.rowsSelection(rangeSelection, true);
        }
      });
      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Unselect ${numberSelectedRows} rows`
            : "Unselect row",
        //icon: '',
        action: () => {
          this.rowsSelection(rangeSelection, false);
        }
      });
      menuItems.push("separator");
      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Insert ${numberSelectedRows} rows below`
            : "Insert row below",
        //icon: '',
        action: () =>
          this.insertRows(
            numberSelectedRows,
            fromUpToDown ? endRow : startRow,
            false
          ),
        disabled: true
      });
      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Insert ${numberSelectedRows} rows above`
            : "Insert row above",
        //icon: '',
        shortcut: "Ctrl + I",
        action: () =>
          this.insertRows(
            numberSelectedRows,
            fromUpToDown ? startRow : endRow,
            true
          ),
        disabled: true
      });
      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Clear ${numberSelectedRows} rows`
            : "Clear row",
        //icon: '',
        action: () => {
          this.clearRows(rangeSelection);
        },
        disabled: true
      });
      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Delete ${numberSelectedRows} rows`
            : "Delete row",
        //icon: '',
        shortcut: "Ctrl + D",
        action: () => {
          this.gridController.isModalOpenSubject.next(true);
          this.gridController.modalContentSubject.next({
            type: "deleteConfirm",
            params: { number: numberSelectedRows, rangeSelection },
            isDialog: true
          });
        },
        cssClasses: ["text-danger"],
        disabled: true
      });
      menuItems.push("separator");
      menuItems.push({
        name: "Clone Row With Data",
        action: () => {
          this.cloneRow(node);
        },
        disabled: true
      });
    }

    return menuItems;
  }

  getMainMenuItems({ columnApi, defaultItems, column }) {
    const menuItems = defaultItems.splice(0);
    const colState = this.gridController.gridOptions.colStateFromColumn(
      this.gridController._cols,
      column
    );
    const columnTests = SheetDataService.getColumnTestsByChannel(
      colState,
      this.gridController.urlParams.channel
    );
    const testsData = SheetDataService.testsMappings(
      columnTests,
      this.gridController.tests
    );

    menuItems.splice(6, 0, {
      name: "Hide Column From View",
      action: () => {
        columnApi.setColumnVisible(column.colId, false);
      }
    });

    menuItems.splice(7, 0, {
      name: "Reset Column",
      //icon: '',
      //shortcut: '',
      action: () => {
        const columnState = this.gridController.columnsState.find(
          state => state.colId === column.colId
        );
        columnApi.applyColumnState({ state: [columnState] });
      }
    });

    if (colState.testable && columnTests.length > 0) {
      menuItems.push("separator");
      menuItems.push({
        name: "Show Health Tests",
        //icon: '',
        //shortcut: '',
        action: () => {
          this.gridController.isModalOpenSubject.next(true);
          this.gridController.modalContentSubject.next({
            type: "columnTestInfo",
            params: { testsData }
          });
        }
      });
    }

    return menuItems;
  }

  insertRows(insertNumber, selectedRow, above) {}

  rowsSelection(rangeSelection, select) {
    const nodes = this.getRangeSelectedNodes(
      this.gridController.gridApi,
      rangeSelection
    );

    nodes.forEach(node => {
      node.setSelected(select);
    });
  }

  clearRows(rangeSelection) {
    const nodes = this.getRangeSelectedNodes(
      this.gridController.gridApi,
      rangeSelection
    );

    nodes.forEach(node => {
      const newData = {};
      // Clear row data for every column
      Object.keys(node.data).forEach(key => {
        const colFound = this.gridController._cols.some(
          col => col.code === key
        );
        if (!colFound) {
          newData[key] = node.data[key];
        }
      });
      node.setData(newData);
    });
  }

  cloneRow(rowNode) {
    if (!rowNode) return;
    const rowIndex = rowNode.rowIndex + 1;
    const newRowsData = [...this.gridController._rows];
    const row = this.generateNotEmptyRow(rowNode);

    newRowsData.splice(rowIndex, 0, row);
    // TODO: uncomment when cloneRow is enabled
    // this.gridController._rows = newRowsData;
    // this.gridController.gridApi.setRowData(this.gridController._rows);
    // this.gridController.gridApi.refreshCells();
  }

  generateBlankRows(counter, sheetData, firstExistingSeq) {
    const blank = {
      sheet_entity_row_id: null,
      sheet_id: sheetData.id,
      organization_id: "28a83b47-a6b3-4634-8125-f0afa723427c", // get from current user metadata
      entity_row_id: null,
      channels: null
    };
    const blankRows = [];

    for (let i = 0; i < counter; i++) {
      const generatedId = uuidv4();
      blankRows.push({
        ...blank,
        id: generatedId,
        seq: firstExistingSeq + (i + 1),
        sheet_entity_row_id: generatedId
      });
    }

    return blankRows;
  }

  generateNotEmptyRow(node = {}) {
    const sheet_entity_row_id = uuidv4();
    const entity_row_id = uuidv4();

    const row = {
      ...node.data,
      id: sheet_entity_row_id,
      sheet_entity_row_id,
      entity_row_id,
      seq: node.data.seq + 1,
      sys_hierarchy: [entity_row_id]
    };

    return row;
  }

  getRangeSelectedNodes(api, rangeSelection) {
    if (!api || !rangeSelection) return;
    const _nodes = [];
    const startRowIndex =
      rangeSelection[rangeSelection.length - 1].startRow.rowIndex;
    const endRowIndex =
      rangeSelection[rangeSelection.length - 1].endRow.rowIndex;
    const startIndex =
      endRowIndex > startRowIndex ? startRowIndex : endRowIndex;
    const endIndex = endRowIndex > startRowIndex ? endRowIndex : startRowIndex;

    api.forEachNode(node => {
      if (node.rowIndex >= startIndex && node.rowIndex <= endIndex) {
        _nodes.push(node);
      }
    });

    return _nodes;
  }
}
