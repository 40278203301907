import React from "react";
// import { FormattedMessage } from 'react-intl';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ProductsOld from "../screens/products";
import Products from "../screens/products-api-integration";
import Dashboards from "../screens/dashboards";
import OnBoarding from "../screens/onboarding";
import Tools from "../screens/tools";
import Activities from "../screens/activities";
import Promos from "../screens/promos";
import Sheets from "../screens/sheets";
import Attributes from "../screens/attributes";
import ShopifyMetaFieldWizard from "../screens/shopifymetafieldwizard";
import ConnectionWizard from "../screens/connectionwizard";
import BcCustomFieldWizard from "../screens/bccustomfieldwizard";

import Elements from "../screens/elements";
import Lookups from "../screens/lookups";
import View from "../screens/views";
import Sets from "../screens/sets";

import CSVImport from "../screens/csvimport";
import CSVExport from "../screens/csvexport";
import BCConnect from "../screens/bcconnect";
import Sync from "../screens/sync";
import CreateAttribute from "../screens/createAttribute";
import Profile from "../screens/profile";
import Account from "../screens/account";
import Billing from "../screens/billing";
import Users from "../screens/users";
import Entities from "../screens/entities";
import AttributesStatic from "../screens/attributes-static-sheet";
import Assets from "../screens/assets";
import Business from "../screens/business";
import Categories from "../screens/categories";
import Channels from "../screens/channels";
import Connectors from "../screens/connectors";
import Infocards from "../screens/infocards";
import Tests from "../screens/tests";
import Filters from "../screens/filters";
import Summaries from "../screens/summaries";
import Toolbarbuttons from "../screens/toolbarbuttons";
import Tags from "../screens/tags";
import Roles from "../screens/roles";
import history from "./history";
// const NotVerified = () => (
//   <div>
//     <h1>
//       <FormattedMessage
//         id="login.notverified.title"
//         defaultMessage="Email is not verified"
//       />
//     </h1>
//     <p>
//       <FormattedMessage
//         id="login.notverified.instruction"
//         defaultMessage="Please follow up the link in your inbox."
//       />
//     </p>
//     <Button ui="primary" onClick={() => new Auth().logout()}>
//       Login again
//     </Button>
//   </div>
// );

const NoMatch = ({ location }) => (
  <div>
    <h3>
      No match for <code>{location.pathname}</code>
    </h3>
  </div>
);

export default () => (
  <Router history={history}>
    {/* if (!emailVerified) return <NotVerified />;
        if (!onboarded) return <Onboarding />; */}

    <Switch>
      {/* Onboarding route */}
      <Route exact path="/onBoard/check" component={OnBoarding} />
      <Route
        exact
        path="/products/:sheet?/:channel?/:view?"
        component={Products}
      />

      {/* Home/Landing Page */}
      <Route exact path="/" component={Dashboards} />

      <Route exact path="/activities" component={Activities} />
      <Route exact path="/whatsnew" component={Promos} />
      <Route exact path="/tools" component={Tools} />
      <Route exact path="/tools/csv-import" component={CSVImport} />
      <Route
        exact
        path="/tools/connection-setup"
        component={ConnectionWizard}
      />
      <Route
        exact
        path="/tools/bc-custom-fields"
        component={BcCustomFieldWizard}
      />
      <Route
        exact
        path="/tools/shopify-meta-fields"
        component={ShopifyMetaFieldWizard}
      />

      {/* Temporary routes - how do we do them dynamic? */}
      <Route exact path="/assets" component={Products} />
      <Route exact path="/categories" component={Products} />
      <Route exact path="/companies" component={Products} />

      {/* tool routes */}
      <Route exact path="/csvimport" component={CSVImport} />
      <Route exact path="/csvexport" component={CSVExport} />
      <Route exact path="/bcconnect" component={BCConnect} />
      <Route exact path="/sync" component={Sync} />
      <Route exact path="/create/attribute" component={CreateAttribute} />

      {/* settings routes */}
      <Route exact path="/settings" component={Profile} />
      <Route exact path="/settings/profile" component={Profile} />
      <Route exact path="/settings/account" component={Account} />
      <Route exact path="/settings/billing" component={Billing} />
      <Route exact path="/settings/infocards" component={Infocards} />
      <Route exact path="/settings/users" component={Users} />
      <Route exact path="/settings/roles" component={Roles} />
      <Route exact path="/settings/entities" component={Entities} />
      <Route exact path="/settings/attributes" component={Attributes} />
      <Route exact path="/settings/channels" component={Channels} />
      <Route exact path="/settings/connectors" component={Connectors} />
      <Route exact path="/settings/tests" component={Tests} />
      <Route exact path="/settings/sets" component={Sets} />
      <Route exact path="/settings/views" component={View} />
      <Route exact path="/settings/filters" component={Filters} />
      <Route exact path="/settings/tags" component={Tags} />
      <Route exact path="/settings/cards" component={Summaries} />
      <Route exact path="/settings/bookmarks" component={Toolbarbuttons} />

      <Route exact path="/sheets" component={Sheets} />

      <Route exact path="/sheets/all-products" component={Products} />
      <Route exact path="/sheets/products-old" component={ProductsOld} />
      <Route exact path="/sheets/all-assets" component={Assets} />
      <Route exact path="/sheets/all-categories" component={Categories} />
      <Route exact path="/sheets/lookups" component={Lookups} />
      <Route exact path="/sheets/elements" component={Elements} />
      <Route exact path="/sheets/all-business" component={Business} />
      <Route exact path="/sheets/views" component={View} />
      <Route exact path="/sheets/sets" component={Sets} />

      <Route exact path="/sheets/attributes" component={AttributesStatic} />

      {/* 404 */}
      <Route component={NoMatch} />
    </Switch>
  </Router>
);
