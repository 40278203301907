import React, { useState, useEffect } from "react";
//import { StitchContext } from 'stitch';

import { organization } from "auth/dummyData";

export default (Component, getInitialProps) => {
  return ({ history, match }) => {
    //const stitch = useContext(StitchContext);

    const [state, setState] = useState({
      loading: true,
      data: null,
      error: null
    });

    useEffect(() => {
      setState({ data: organization, loading: false });
      // const fetchData = async () => {
      //   setState({
      //     data: await getInitialProps({
      //       history,
      //       match,
      //       params: match.params,
      //       client: stitch.client,
      //       db: stitch.db,
      //       stitchUser: stitch.stitchUser,
      //       user: await stitch.user,
      //       account: await stitch.account,
      //     }),
      //     loading: false,
      //   });
      // };
      // fetchData();
    }, [
      history,
      match
      // stitch.client,
      // stitch.db,
      // stitch.stitchUser,
      // stitch.user,
      // stitch.account,
    ]);

    return (
      <Component
        loading={state.loading}
        data={state.data}
        error={state.error}
      />
    );
  };
};
