import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { styled } from "@material-ui/core/styles";
// import Divider from "@material-ui/core/Divider";
import { IMAGES_PATH } from "lib/constants";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360
  },
  inline: {
    display: "flex"
  }
}));

const MyAvatar = styled(Avatar)({
  width: "45px",
  height: "60px",
  borderRadius: "0px",
  /* Image border */

  border: "1px solid #E3E5E6",
  boxSizing: "border-box"
});

export default function ProductInfo({ data }) {
  const classes = useStyles();
  const ProductImage = data && data.sys_primary_image;
  const ProductName = data && data.name;
  const ProductType = data && data.sys_producttype;
  const ProductDesc = data && data.short_desc;
  return (
    <List className={classes.root}>
      <ListItem>
        <ListItemAvatar>
          <MyAvatar>
            <img
              src={`${IMAGES_PATH.thumbnail}/${ProductImage}`}
              alt={`${IMAGES_PATH.thumbnail}/1.png`}
            />
          </MyAvatar>
        </ListItemAvatar>
        <ListItemText
          primary={ProductName}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {ProductType}
              </Typography>
              {ProductDesc}
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  );
}
