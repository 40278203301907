// TODO: understand how the views relate to the actual data model
// import dataJSON from "../../../dummyData/entity_rows100.json";
import { ReplaySubject, Subject } from "rxjs";
import React from "react";
import SheetDataService from "../../../lib/SheetDataService";
import { DISPLAYS, TYPECASTS } from "../../../lib/constants";
import { UploadModal } from "../../../screens/products/modals/Upload";
import {
  AccountManager,
  ActionSlider,
  AttributeBuilder,
  AttributeInspector,
  CategorySelect,
  ChannelManager,
  ConnectionManager,
  ElementSelect,
  FilterBuilder,
  FilterManager,
  GalleryManager,
  OrganizationManager,
  SetBuilder,
  TestInspector,
  VariantBuilder,
  ViewBuilder,
  ViewManager
} from "../../../screens/products/modals";
import viewJSON from "../../../screens/attributes-static-sheet/attributes-static-data/view.json";
import sheetJSON from "../../../dummyData/sheets.json";
import channelJSON from "../../../dummyData/channel.json";
import entityJSON from "../../../screens/attributes-static-sheet/attributes-static-data/entity.json";
import filterJSON from "../../../dummyData/filter.json";
import settingsJSON from "../../../dummyData/settings.json";
import testsJSON from "../../../dummyData/test.json";
import { HtmlEditor } from "components/app/grid";
import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import Button from "../../ui/Button";
import { Decimal } from "decimal.js-light";

// TODO: try object oriented approach to class extension when migrating to typescript
export class GridController {
  // TODO: If only one copy of sheet data needed - use SheetDataService as a global singleton do distribute SheetData.
  sheetDataSubject = new ReplaySubject(1);
  sheetDataObservable$ = this.sheetDataSubject.asObservable();
  viewObjectSubject = new ReplaySubject(1);
  viewObjectObservable$ = this.viewObjectSubject.asObservable();
  channelDataSubject = new ReplaySubject(1);
  channelDataObservable$ = this.channelDataSubject.asObservable();
  setsSubject = new ReplaySubject(1);
  setsObservable$ = this.setsSubject.asObservable();
  entitiesSubject = new ReplaySubject(1);
  entitiesObservable$ = this.entitiesSubject.asObservable();
  viewsSubject = new ReplaySubject(1);
  viewsObservable$ = this.viewsSubject.asObservable();
  savedFiltersSubject = new ReplaySubject(1);
  savedFiltersObservable$ = this.savedFiltersSubject.asObservable();
  settingsSubject = new ReplaySubject(1);
  settingsObservable$ = this.settingsSubject.asObservable();
  selectedRowsSubject = new ReplaySubject(1);
  selectedRowsObservable$ = this.selectedRowsSubject.asObservable();
  channelsSubject = new ReplaySubject(1);
  channelsObservable = this.channelsSubject.asObservable();
  testsSubject = new ReplaySubject(1);
  testsObservable$ = this.testsSubject.asObservable();
  rowsSubject = new ReplaySubject(1);
  rowsObservable$ = this.rowsSubject.asObservable();
  viewSubject = new ReplaySubject(1);
  viewObservable$ = this.viewSubject.asObservable();
  isModalOpenSubject = new ReplaySubject(1);
  isModalOpenObservable$ = this.isModalOpenSubject.asObservable();
  modalContentSubject = new ReplaySubject(1);
  modalContentObservable$ = this.modalContentSubject.asObservable();
  isChildModalOpenSubject = new ReplaySubject(1);
  isChildModalOpenObservable$ = this.isChildModalOpenSubject.asObservable();
  childModalContentSubject = new ReplaySubject(1);
  childModalContentObservable$ = this.childModalContentSubject.asObservable();
  rowsToDisplaySubject = new ReplaySubject(1);
  rowsToDisplayObservable$ = this.rowsToDisplaySubject.asObservable();
  activeRowNodeSubject = new ReplaySubject(1);
  activeRowNodeObservable = this.activeRowNodeSubject.asObservable();
  firstViewRowSubject = new ReplaySubject(1);
  firstViewRowObservable = this.firstViewRowSubject.asObservable();
  changedRowsSubject = new ReplaySubject(1);
  changedRowsObservable$ = this.changedRowsSubject.asObservable();
  initialStateSubject = new Subject(1);
  initialStateObservable$ = this.initialStateSubject.asObservable();

  gridApi;
  gridColumnApi;
  columnState;
  _cols;
  _rows;

  // TODO: assign correct generic names to make sheet reusable
  constructor(columns, rows, urlParams, intl, writerCache) {
    this._cols = columns;
    this._rows = rows;
    this.potentialParent = null;
    this.urlParams = urlParams;
    this.intl = intl;
    this.writerCache = writerCache;
  }

  // Needs to be out of constructor as this can't be called before super in Javascript
  initGridConfigurationClasses(gridMenuController, gridOptions) {
    this.gridMenuController = gridMenuController;
    this.gridOptions = gridOptions;
  }

  initData() {
    this.viewSubject.next(
      SheetDataService.getView(viewJSON, this.urlParams.view)
    );
    this.setsObservable$.subscribe(sets => {
      this.sets = sets;
    });
    this.changedRowsSubject.next([]);
    this.changedRowsObservable$.subscribe(changedRows => {
      this.changedRows = changedRows;
    });
    // Get Sheet object
    // TODO: do we need url params. See products initData
    const sheetObj = SheetDataService.getSheet(sheetJSON);
    this.sheetDataSubject.next(sheetObj);

    // Get View object
    // TODO: do we need url params. See products initData
    const viewObj = SheetDataService.getView(viewJSON);
    this.viewObjectSubject.next(
      this.urlParams.alternative
        ? { ...viewObj, alternative: this.urlParams.alternative }
        : viewObj
    );

    // Get full Rows data based on channel
    // TODO: implement logic to filter based on channel if necessary
    // const rowsData = SheetDataService.getRowsData(
    //   dataJSON,
    //   gridController.urlParams.channel
    // );
    // gridController._rows = rowsData;
    // gridController.rowsSubject.next(gridController._rows);

    // Channel data based on channel url param
    const channelData = SheetDataService.getChannelData(
      channelJSON,
      this.urlParams.channel
    );
    this.channelsSubject.next(channelData);

    // Get sets and lookups
    // TODO: check logic
    // const sets = SheetDataService.getDropdownOptions(
    //     attributeJSON,
    //     lookupJSON,
    //     setJSON,
    //     urlParams.channel
    // );
    // setSets(sets);

    // Get entitiesSubject
    const entity = entityJSON.length > 0 ? entityJSON[0] : {};
    this.entitiesSubject.next(entity);

    // Get viewsSubject
    this.viewsSubject.next(viewJSON);

    // Get saved filters
    this.savedFiltersSubject.next(filterJSON);

    // Get settings
    this.settingsSubject.next(settingsJSON[0]);

    // Get channelsSubject
    this.channelsSubject.next(channelJSON);

    this.setsSubject.next([]);

    this.testsSubject.next(testsJSON);
  }

  onGridReady({ api, columnApi }) {
    this.gridApi = api;
    this.gridColumnApi = columnApi;
    this.rowsToDisplaySubject.next(api.getModel().rowsToDisplay);
    this.rowsSubject.next(this.getGridRows(api));

    // Save columns state
    this.columnsState = columnApi.getColumnState();

    // Set first cell focus
    const firstCol = columnApi.getAllDisplayedColumns()[1];
    api.addCellRange({
      rowStartIndex: 0,
      rowEndIndex: 0,
      columnStart: firstCol,
      columnEnd: firstCol
    });
    api.setFocusedCell(0, firstCol);
    api.addGlobalListener((type, event) => {
      //console.log('type: ', type, '\nevent: ', event);
      // Set number of rows in sheet
      if (
        type === "firstDataRendered" ||
        type === "modelUpdated" ||
        type === "filterChanged"
      ) {
        this.rowsToDisplaySubject.next(api.getModel().rowsToDisplay);
        this.rowsSubject.next(this.getGridRows(api));
      }
      // Get active row from focused cell
      if (
        type === "firstDataRendered" ||
        type === "cellFocused" ||
        type === "modelUpdated"
      ) {
        const focusedCell = api.getFocusedCell() || {};
        const row = api.getDisplayedRowAtIndex(focusedCell.rowIndex) || {};
        this.activeRowNodeSubject.next(row);
      }
      // Get first row from viewport
      if (type === "viewportChanged") {
        this.firstViewRowSubject.next(event.firstRow);
      }
      // Save row data on every cell change
      if (type === "cellValueChanged") {
        this.changedRows.push(event.data);
        this.changedRowsSubject.next(this.changedRows);
      }
    });
  }

  unsubscribe = () => {
    //TODO: destroy subscriptions if not shared
  };

  cellClassRules({ api, value, node, data = {}, colState, sets, channel }) {
    let _levelTests = {
      critical: false,
      warning: false,
      readOnly: false,
      good: false
    };

    // Do not execute tests in case entityRow has just been inserted
    if (this.isRowEmpty(data)) return _levelTests;
    const editableCols = SheetDataService.extractEditableCols(data, sets);
    const isOHCol = colState.display === DISPLAYS.overall_health;
    const isEditable = !colState.protect
      ? editableCols.length > 0
        ? editableCols.includes(colState.code)
        : true
      : !colState.protect;
    const isValidType = SheetDataService.testTypecast(
      value,
      colState.typecast,
      sets[colState.code]
    );
    const columnTests = SheetDataService.getColumnTestsByChannel(
      colState,
      channel
    );

    let _nodes = [];

    api &&
      api.forEachNode(rowNode => {
        rowNode.id !== node.id &&
          rowNode.data &&
          _nodes.push(rowNode.data[colState.code]);
      });

    data.entity_row_id !== null &&
      columnTests.forEach(test => {
        const testPassed = SheetDataService.testValue(value, test, _nodes);

        if (!testPassed) {
          _levelTests[test.level] = true;
        }
      });

    if (!isValidType || _levelTests.critical || (isOHCol && value > 999)) {
      _levelTests.critical = true;
    } else if (_levelTests.warning || (isOHCol && value > 0)) {
      _levelTests.warning = true;
    } else if (isOHCol && value === 0) {
      _levelTests.good = true;
    } else if (colState.protect || !isEditable) {
      _levelTests.readOnly = true;
    }

    return _levelTests;
  }

  isRowEmpty(data) {
    return Object.keys(data).length === 1 && data.id;
  }

  cellStyleRules({ value, colState }) {
    const validDate = SheetDataService.validDate(value);
    const boolCleaned = SheetDataService.booleanCleaner(value);
    const isEmpty = SheetDataService.isEmpty(value);

    let styles = {};

    // Align text based on type
    if (
      colState.typecast === TYPECASTS.boolean &&
      (typeof boolCleaned === "boolean" || isEmpty)
    ) {
      styles.textAlign = "center";
    } else if (
      (colState.typecast === TYPECASTS.date ||
        colState.typecast === TYPECASTS.currency ||
        colState.typecast === TYPECASTS.integer ||
        colState.typecast === TYPECASTS.decimal) &&
      (!isNaN(value) || validDate)
    ) {
      styles.textAlign = "right";
    } else {
      styles.textAlign = "left";
    }

    return styles;
  }

  handleEditable(params, colState) {
    const { column, data } = params;
    if (column.colId === "defaultValue") {
      const editableDefaultTypecasts = [
        "date",
        "text",
        "currency",
        "integer",
        "double",
        "select"
      ];
      return editableDefaultTypecasts.indexOf(data.typecast) !== -1;
    }
    const editableCols = SheetDataService.extractEditableCols(data, this.sets);

    return !colState.protect ||
      (this.urlParams.alternative === DISPLAYS.hierarchy &&
        colState.display === DISPLAYS.hierarchy)
      ? editableCols.length > 0
        ? editableCols.includes(column.colId)
        : true
      : false;
  }

  handleTesting({ api, value, column, node }, colState) {
    // Do not execute tests in case entityRow has just been inserted
    if (this.isRowEmpty(node.data)) return [];
    const colId = column ? column.colId : colState.code;
    const columnTests = SheetDataService.getColumnTestsByChannel(
      colState,
      this.urlParams.channel
    );

    const isValidType = SheetDataService.testTypecast(
      value,
      colState.typecast,
      this.sets[colId]
    );

    let _levelTests = [];
    let _nodes = [];

    api.forEachNode(rowNode => {
      rowNode.id !== node.id &&
        rowNode.data &&
        _nodes.push(rowNode.data[colId]);
    });
    if (!node.data) return _levelTests;

    node.data.entity_row_id !== null &&
      columnTests.forEach(test => {
        const testPassed = SheetDataService.testValue(value, test, _nodes);

        if (!testPassed) {
          _levelTests.push({
            ...test,
            column: colState.name,
            cellValue: value
          });
        }
      });

    if (!isValidType) {
      _levelTests.push({
        code: "type",
        level: "critical",
        params: [{ value: colState.typecast }],
        column: colState.name,
        cellValue: value
      });
    }

    return _levelTests;
  }

  handleHelperClick({ api, node, column, value }) {
    const { rowIndex } = node;
    const colState = this.colStateFromColumn(this._cols, column);

    api.setFocusedCell(rowIndex, column.colId);
    api.startEditingCell({
      rowIndex: rowIndex,
      colKey: column.colId,
      keyPress: "helperBtn"
    });

    if (colState.typecast === TYPECASTS.html) {
      this.isModalOpenSubject.next(true);
      this.modalContentSubject.next({
        type: "htmlEditor",
        params: { node, column, value }
      });
    }
  }

  colStateFromColumn(cols, column = {}) {
    const colState = cols.find(col => col.code === column.colId) || {};
    return colState;
  }

  suppressKeyPress({ event }, keys, isCtrl) {
    if (Array.isArray(keys)) {
      const includesKey = keys.includes(event.key);

      return isCtrl
        ? (event.ctrlKey || event.metaKey) && includesKey
        : includesKey;
    }

    return isCtrl
      ? (event.ctrlKey || event.metaKey) && event.key === keys
      : event.key === keys;
  }

  getGridRows(api) {
    const _newNodes = [];
    api.forEachNodeAfterFilterAndSort(node => {
      _newNodes.push(node.data);
    });
    return _newNodes;
  }

  setIsModalOpen(modalState) {
    this.isModalOpenSubject.next(modalState);
  }

  setChildModalOpen(modalState) {
    this.isChildModalOpenSubject.next(modalState);
  }

  setSelectedRows(selected) {
    this.selectedRowsSubject.next(selected);
  }

  setView(view) {
    this.viewSubject.next(view);
  }

  /**
   * Generates a row sequence for the row on top of the insertion range.
   * If rows are insterted beyond the last position the algorithm adds 1 to the last existing sequence number and
   * and inserts all new rows in the interval (lastExistingSeq - lastExistingSeq + 1).
   * For example if the last row has seq - 4 and 2 new rows are inserted the first row is inserted between 4 and 5 and receives a seq of 4.5
   * The next row is inserted between 4.5 and 5 and receives a seq of 4.75
   * TODO: decide if the constant value added to the last row should be greater than 1 (if yes what would be an appropriate constant)
   * @param lastExistingRowIndex
   * @param firstExistingRowSeq
   * @returns {Decimal}
   */
  generateLastRowSeq(lastExistingRowIndex, firstExistingRowSeq) {
    return this._rows.length - 1 < lastExistingRowIndex
      ? firstExistingRowSeq.plus(1)
      : new Decimal(
          this.rawProductData[this._rows[lastExistingRowIndex].id].seq
        );
  }

  /**
   * generates a row sequence for the row on bottom of the insertion range
   * If rows are inserted before the beginning of the sheet the initial index is assumed to be 0.
   * For example if the first row has seq - 4 and 2 new rows are inserted the first row is inserted between 4 and 0 and receives a seq of 2
   * The next row is inserted between 2 and 0 and receives a seq of 1
   * TODO: decide if the constant value added before the first row should be smaller than 0 (if yes what would be an appropriate constant)
   * @param firstExistingRowIndex
   * @returns {Decimal}
   */
  generateFirstRowSeq(firstExistingRowIndex) {
    return firstExistingRowIndex < 0
      ? new Decimal("0")
      : new Decimal(
          this.rawProductData[this._rows[firstExistingRowIndex].id].seq
        );
  }

  // TODO: add a check which normalises all rows in cases if both first and last seq are equal.
  // TODO: This can happen in case of errors - if the local grid is out of sync with the server or in case of multiple simultaneous sessions.
  /**
   * Generates an item seq based on the following parameters.
   * The method is generalised to work with a sequence of inserted rows.
   * To work with a single row in the case of row drag end, use the default arguments.
   * Takes the difference between the first and last existing sequence for the first inserted row of the list then performs the algorithm recursively for the next row
   * taking the last inserted row as a predecessor and the last existing row as a successor.
   * @param firstExistingSeq
   * @param lastExistingSeq
   * @param i - can be 0 in the case of row drag
   * @param blankRows - can be empty array in the case of row drag
   * @returns {Decimal}
   */
  generateItemSeq(firstExistingSeq, lastExistingSeq, i = 0, blankRows = []) {
    const seqBefore =
      i === 0
        ? firstExistingSeq
        : new Decimal(this.rawProductData[blankRows[i - 1].id].seq);
    const currentItemSeq = seqBefore.plus(
      lastExistingSeq.minus(seqBefore).dividedBy(2)
    );
    return currentItemSeq;
  }

  renderParentModal(type, params = {}) {
    return {
      upload: <UploadModal {...params} />,
      htmlEditor: (
        <HtmlEditor
          initValue={params.value}
          onChange={value => {
            params.node && params.node.setDataValue(params.column.colId, value);
          }}
        />
      ),
      actionSlider: <ActionSlider {...params} />,
      filterManager: <FilterManager {...params} />,
      viewManager: <ViewManager {...params} />,
      channelManager: <ChannelManager {...params} />,
      testInspector: <TestInspector {...params} />,
      galleryManager: <GalleryManager {...params} />,
      categorySelect: <CategorySelect {...params} />,
      attributeInspector: <AttributeInspector {...params} />,
      accountManager: <AccountManager {...params} />,
      attributeBuilder: <AttributeBuilder {...params} />,
      setBuilder: <SetBuilder {...params} />,
      organizationManager: <OrganizationManager {...params} />,
      elementSelect: <ElementSelect {...params} />,
      variantBuilder: <VariantBuilder {...params} />
    }[type];
  }

  renderModalContent(type, params = {}) {
    return {
      htmlEditor: (
        <div>
          <HtmlEditor
            initValue={params.value}
            onChange={value => {
              params.node &&
                params.node.setDataValue(params.column.colId, value);
            }}
          />
        </div>
      ),
      galleryManager: (
        <div>
          <h2 style={{ marginBottom: "12px" }}>Gallery Manager</h2>
          <div>
            <label>Attributes for caption:</label>
            <Input
              type="select"
              multiple
              label="Choose up to 3 attributes"
              onChange={e => {
                if (e.target.value.length <= 3) {
                  this.settingsSubject.next({
                    ...this.settings,
                    gallery: {
                      ...this.settings.gallery,
                      attributes: e.target.value
                    }
                  });
                  // Update server settings through webSocket
                  //WSContext.addData(e.target.value);
                }
              }}
              options={params.options ? params.options.attributes : []}
              value={
                this.settings.gallery ? this.settings.gallery.attributes : []
              }
            />
          </div>
          <div>
            <label>Margin between images:</label>
            <Input
              type="select"
              label="Select margin"
              onChange={e => {
                this.settingsSubject.next({
                  ...this.settings,
                  gallery: {
                    ...this.settings.gallery,
                    image_margin: e.target.value
                  }
                });
                // Update server settings through webSocket
                //WSContext.addData(e.target.value);
              }}
              options={params.options ? params.options.margins : []}
              value={
                this.settings.gallery
                  ? this.settings.gallery.image_margin
                  : "no_margin"
              }
            />
          </div>
          <div>
            <label>Image size:</label>
            <Input
              type="select"
              label="Select image size"
              onChange={e => {
                this.settingsSubject.next({
                  ...this.settings,
                  gallery: {
                    ...this.settings.gallery,
                    image_size: e.target.value
                  }
                });
                // Update server settings through webSocket
                //WSContext.addData(e.target.value);
              }}
              options={params.options ? params.options.sizes : []}
              value={
                this.settings.gallery
                  ? this.settings.gallery.image_size
                  : "thumbnail"
              }
            />
          </div>
          <div>
            <Input
              type="switch"
              value={
                this.settings.gallery
                  ? this.settings.gallery.caption_on_hover
                  : false
              }
              label="Caption on hover"
              onChange={e => {
                this.settingsSubject.next({
                  ...this.settings,
                  gallery: {
                    ...this.settings.gallery,
                    caption_on_hover: e.target.checked
                  }
                });
                // Update server settings through webSocket
                //WSContext.addData(e.target.value);
              }}
            />
          </div>
        </div>
      ),
      deleteConfirm: (
        <div>
          <DialogTitle id="alert-dialog-title">
            {this.intl.formatMessage(
              {
                id: "products.deleteConfirmation",
                defaultMessage: "Are you sure you want to delete row/s?"
              },
              { number: params.number }
            )}
          </DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button
              ui="primary"
              onClick={() => {
                this.gridMenuController.deleteRows(
                  params.fromUpToDown,
                  params.number,
                  params.rangeSelection
                );
                this.setIsModalOpen(false);
              }}
            >
              Confirm
            </Button>
            <Button
              ui="secondary"
              type="button"
              onClick={() => {
                this.setIsModalOpen(false);
              }}
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </div>
      ),
      columnTestInfo: (
        <div>
          <h2>Column tests information</h2>
          {params.testsData &&
            params.testsData.map(test => (
              <div key={test.id}>
                <div>
                  <b>{test.name}</b>
                </div>
                <div>{test.description}</div>
                {test.params && test.params.length > 0 && (
                  <div>
                    {test.params
                      .map(param => {
                        return param.value ? param.value : param;
                      })
                      .join("-")}
                  </div>
                )}
                <br />
              </div>
            ))}
        </div>
      ),
      cellTestInfo: (
        <div>
          <h2>Cell Tests Information</h2>
          {params.testsData &&
            params.testsData.map(test => (
              <div key={test.id}>
                <div>
                  <b>{test.name}</b>
                </div>
                <div>{test.description}</div>
                {test.params && test.params.length > 0 && (
                  <div>
                    {test.params
                      .map(param => {
                        return param.value ? param.value : param;
                      })
                      .join("-")}
                  </div>
                )}
                <br />
              </div>
            ))}
        </div>
      )
    }[type];
  }

  renderChildModal(gridController, type, params = {}) {
    return {
      filterBuilder: (
        <FilterBuilder
          {...params}
          modalHandle={modalState =>
            gridController.setChildModalOpen(modalState)
          }
        />
      ),
      ViewBuilder: (
        <ViewBuilder
          {...params}
          modalHandle={modalState =>
            gridController.setChildModalOpen(modalState)
          }
        />
      ),
      ConnectionManager: (
        <ConnectionManager
          {...params}
          modalHandle={modalState =>
            gridController.setChildModalOpen(modalState)
          }
        />
      )
    }[type];
  }
}
