import React, { useEffect, useState, useContext, useRef } from "react";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import humanize from "humanize-string";
import { injectIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import {
  Paper,
  Tabs,
  Tab,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { Button, Modal } from "components/ui";
import { AuthenticatedLayout, InspectorBar } from "components/app";
import { HtmlEditor, Toolbar } from "components/app/grid";
// Data
import sheetJSON from "dummyData/sheets.json";
import entityJSON from "dummyData/entity.json";
import attributeJSON from "dummyData/attribute.json";
import dataJSON from "dummyData/entity_rows100.json";
import viewJSON from "dummyData/view.json";
import channelJSON from "dummyData/channel.json";
import lookupJSON from "dummyData/lookup.json";
import setJSON from "dummyData/set.json";
import filterJSON from "dummyData/filter.json";
import settingsJSON from "dummyData/settings.json";
import testsJSON from "dummyData/test.json";
// Services
import { SheetDataService, WebSocketContext, useQuery } from "lib";
import {
  CHANNELS,
  FILTER_FROM_FILTER_TYPE,
  DISPLAYS,
  RENDERER_FROM_DISPLAY,
  EDITOR_FROM_TYPECAST,
  TOOLBAR_FILTER_TYPE,
  FILTER_OPTIONS,
  SELECTION_OPTIONS,
  TYPECASTS,
  VIEW_OPTIONS
} from "lib/constants";
// Components
import {
  CurrencyRenderer,
  BooleanRenderer,
  ImageRenderer,
  TextRenderer,
  HtmlRenderer,
  DateEditor,
  CalculatorEditor,
  OverallHealthRenderer,
  CustomToolPanel,
  Widget,
  Gallery,
  Input
} from "components/app/grid";

LicenseManager.setLicenseKey(
  "Evaluation_License_Not_For_Production_30_December_2019__MTU3NzY2NDAwMDAwMA==82f726e0f9e347787984f7c5e16cebcc"
);

let gridApi;
let gridColumnApi;
let _rows = [];
let _cols = [];
let potentialParent = null;
let columnsState = [];
let _changedRows = [];

// Static dropdown options
const marginOptions = [
  {
    code: "no_margin",
    label: "No margin"
  },
  {
    code: "narrow",
    label: "Narrow margin"
  },
  {
    code: "wide",
    label: "Wide margin"
  }
];
const imageSizes = [
  {
    code: "thumbnail",
    label: "Small"
  },
  {
    code: "list",
    label: "Medium"
  },
  {
    code: "original",
    label: "Large"
  }
];
// Columns typecasts containing helpers
const colTypesWithHelpers = [
  TYPECASTS.select,
  TYPECASTS.date,
  TYPECASTS.html,
  TYPECASTS.currency,
  TYPECASTS.integer,
  TYPECASTS.decimal
];

function Products({ intl }) {
  //const [rows, setRows] = useState([]);
  const [inspector, setInspector] = useState(false);
  const [picker, setPicker] = useState("assets2product");
  const [widget, setWidget] = useState(null);
  const [view, setView] = useState({});

  const [gridDestroyed, setGridDestroyed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [rows, setRows] = useState([]);
  //const [cols, setCols] = useState([]);
  const [channels, setChannels] = useState([]);
  const [channelData, setChannelData] = useState([]);
  const [sheetData, setSheetData] = useState({});
  const [sets, setSets] = useState([]);
  const [entities, setEntities] = useState({});
  const [views, setViews] = useState([]);
  const [settings, setSettings] = useState({});
  const [tests, setTests] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsToDisplay, setRowsToDisplay] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [savedFilters, setSavedFilters] = useState([]);
  const [activeRowNode, setActiveRowNode] = useState({});
  const [firstViewRow, setFirstViewRow] = useState(0);
  const [activeImage, setActiveImage] = useState(0);

  const WSContext = useContext(WebSocketContext); // Use when back-end is finished to get data

  const history = useHistory();
  const location = useLocation();

  const query = useQuery();
  const urlParams = {
    sheet: query.get("sheet"),
    channel: query.get("channel") || CHANNELS.core,
    view: query.get("view"),
    alternative: query.get("alternative")
  };

  const prevView = useRef();
  const prevViewObj = prevView.current || {};

  const hierarchyColumn =
    _cols.find(col => col.display === DISPLAYS.hierarchy) || "";

  useEffect(() => {
    // Initialise data
    initData();

    // Send/Get data on every 10 seconds
    setInterval(() => {
      /*
       * Method to get/send data through web socket or REST
       * Changed rows for the last 10 seconds are _changedRows
       */
      // WSContext.addData(_changedRows);

      // Clear changed rows array
      _changedRows = [];
    }, 10000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!gridApi || !gridColumnApi) return;
    // Set visible columns through view
    gridColumnApi.setColumnsVisible(view.attribute_codes || [], true);
    // Hide SKU column
    if (urlParams.alternative === DISPLAYS.hierarchy) {
      gridColumnApi.setColumnVisible("sku", false);
    }

    if (view.code === "edit_view" || view === "edit_view") {
      gridApi.openToolPanel("columns");
    } else {
      gridApi.closeToolPanel();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, urlParams.alternative, gridApi, gridColumnApi]);

  useEffect(() => {
    if (
      (view.alternative &&
        view.alternative === DISPLAYS.hierarchy &&
        prevViewObj.alternative !== DISPLAYS.hierarchy) ||
      (prevViewObj.alternative &&
        prevViewObj.alternative === DISPLAYS.hierarchy &&
        !view.alternative)
    ) {
      setGridDestroyed(true);
      setSelectedRows([]);
      setAppliedFilters({});
      setTimeout(() => {
        setGridDestroyed(false);
      }, 0);
      prevView.current = view;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  useEffect(() => {
    setGridDestroyed(true);
    setTimeout(() => {
      setGridDestroyed(false);
    }, 0);
  }, [channelData]);

  const initData = async () => {
    // Get Sheet object
    const sheetObj = SheetDataService.getSheet(sheetJSON, urlParams.sheet);
    setSheetData(sheetObj);

    // Get View object
    const viewObj = SheetDataService.getView(viewJSON, urlParams.view);
    setView(
      urlParams.alternative
        ? { ...viewObj, alternative: urlParams.alternative }
        : viewObj
    );

    // Cols data based on view param
    //const colsData = SheetDataService.getColsData(attributeJSON);
    _cols = attributeJSON;
    //setCols(colsData);

    // Get full Rows data based on channel
    const rowsData = SheetDataService.getRowsData(dataJSON, urlParams.channel);
    _rows = rowsData;
    setRows(rowsData);

    // Channel data based on channel url param
    const channelData = SheetDataService.getChannelData(
      channelJSON,
      urlParams.channel
    );
    setChannelData(channelData);

    // Get sets and lookups
    const sets = SheetDataService.getDropdownOptions(
      attributeJSON,
      lookupJSON,
      setJSON,
      urlParams.channel
    );
    setSets(sets);

    // Get entities
    const entity = entityJSON.length > 0 ? entityJSON[0] : {};
    setEntities(entity);

    // Get views
    setViews(viewJSON);

    // Get saved filters
    setSavedFilters(filterJSON);

    // Get settings
    setSettings(settingsJSON[0]);

    // Get channels
    setChannels(channelJSON);

    // Get tests
    setTests(testsJSON);
  };

  // Use to save filter
  const saveFilters = () => {
    // Update server data through websocket
    //WSContext.addData(appliedFilters);
  };

  const cellClassRules = ({
    api,
    value,
    node,
    data = {},
    colState,
    sets,
    channel
  }) => {
    const editableCols = SheetDataService.extractEditableCols(data, sets);
    const isOHCol = colState.display === DISPLAYS.overall_health;
    const isEditable = !colState.protect
      ? editableCols.length > 0
        ? editableCols.includes(colState.code)
        : true
      : !colState.protect;
    const isValidType = SheetDataService.testTypecast(
      value,
      colState.typecast,
      sets[colState.code]
    );
    const columnTests = SheetDataService.getColumnTestsByChannel(
      colState,
      channel
    );

    let _levelTests = {
      critical: false,
      warning: false,
      readOnly: false,
      good: false
    };
    let _nodes = [];

    api &&
      api.forEachNode(rowNode => {
        rowNode.id !== node.id &&
          rowNode.data &&
          _nodes.push(rowNode.data[colState.code]);
      });

    data.entity_row_id !== null &&
      columnTests.forEach(test => {
        const testPassed = SheetDataService.testValue(value, test, _nodes);

        if (!testPassed) {
          _levelTests[test.level] = true;
        }
      });

    if (!isValidType || _levelTests.critical || (isOHCol && value > 999)) {
      _levelTests.critical = true;
    } else if (_levelTests.warning || (isOHCol && value > 0)) {
      _levelTests.warning = true;
    } else if (isOHCol && value === 0) {
      _levelTests.good = true;
    } else if (colState.protect || !isEditable) {
      _levelTests.readOnly = true;
    }

    return _levelTests;
  };

  const cellStyleRules = ({ value, colState }) => {
    const validDate = SheetDataService.validDate(value);
    const boolCleaned = SheetDataService.booleanCleaner(value);
    const isEmpty = SheetDataService.isEmpty(value);

    let styles = {};

    // Align text based on type
    if (
      colState.typecast === TYPECASTS.boolean &&
      (typeof boolCleaned === "boolean" || isEmpty)
    ) {
      styles.textAlign = "center";
    } else if (
      (colState.typecast === TYPECASTS.date ||
        colState.typecast === TYPECASTS.currency ||
        colState.typecast === TYPECASTS.integer ||
        colState.typecast === TYPECASTS.decimal) &&
      (!isNaN(value) || validDate)
    ) {
      styles.textAlign = "right";
    } else {
      styles.textAlign = "left";
    }

    return styles;
  };

  // Used for shortcuts
  const handleCellKeyPress = params => {
    const { api, event, value, column, data, node } = params;
    const colState = colStateFromColumn(_cols, column);
    const valueCleaned = SheetDataService.booleanCleaner(value);
    const keyPressed = event.code;
    const editableCols = SheetDataService.extractEditableCols(data, sets);
    const isEditable = !colState.protect
      ? editableCols.length > 0
        ? editableCols.includes(column.colId)
        : true
      : !colState.protect;
    const testing = handleTesting({ api, value, column, node }, colState);
    const testsData = SheetDataService.testsMappings(testing, tests);
    const rangeSelection = api.getCellRanges();
    const startRow = rangeSelection[rangeSelection.length - 1].startRow;
    const endRow = rangeSelection[rangeSelection.length - 1].endRow;
    const fromUpToDown = endRow.rowIndex > startRow.rowIndex;
    const numberSelectedRows = fromUpToDown
      ? endRow.rowIndex + 1 - startRow.rowIndex
      : startRow.rowIndex + 1 - endRow.rowIndex;

    if (
      isEditable &&
      keyPressed === "Space" &&
      colState.typecast === TYPECASTS.boolean &&
      (typeof valueCleaned === "boolean" || !value)
    ) {
      node.setDataValue(column.colId, !valueCleaned);
    }

    // Show health information on ctrl + Q
    if (
      (event.metaKey || event.ctrlKey) &&
      (event.key === "Q" || event.key === "q") &&
      testsData.length > 0
    ) {
      setIsModalOpen(true);
      setModalContent({
        type: "cellTestInfo",
        params: { testsData },
        isDialog: true
      });
    }

    // Show cell helper on ctrl + H
    if (
      (event.metaKey || event.ctrlKey) &&
      (event.key === "H" || event.key === "h") &&
      isEditable &&
      colTypesWithHelpers.includes(colState.typecast)
    ) {
      handleHelperClick({ api, node, column, value });
    }

    // Select rows on ctrl/cmnd + A
    if (
      (event.metaKey || event.ctrlKey) &&
      (event.key === "A" || event.key === "a")
    ) {
      rowsSelection(rangeSelection, true);
    }

    // Cut cell on ctrl + x
    if (
      (event.metaKey || event.ctrlKey) &&
      (event.key === "X" || event.key === "x")
    ) {
      api.copySelectedRangeToClipboard();
      node.setDataValue(column.colId, "");
    }

    // Insert rows on ctrl/cmnd + I
    if (
      (event.metaKey || event.ctrlKey) &&
      (event.key === "I" || event.key === "i")
    ) {
      insertRows(numberSelectedRows, fromUpToDown ? startRow : endRow, true);
    }

    // Delete rows on ctrl/cmnd + D
    if (
      (event.metaKey || event.ctrlKey) &&
      (event.key === "D" || event.key === "d")
    ) {
      setIsModalOpen(true);
      setModalContent({
        type: "deleteConfirm",
        params: { number: numberSelectedRows, rangeSelection },
        isDialog: true
      });
    }
  };

  // const suppressSpace = (params, colState) => {
  //   const { event, api, column, node } = params;
  //   const value = api.getValue(column.colId, node);
  //   const valueCleaned = SheetDataService.booleanCleaner(value);
  //   const keyPressed = event.code;

  //   return typeof valueCleaned === 'boolean' || (colState.typecast === TYPECASTS.boolean && !value)
  //   ? keyPressed === 'Space' : false;
  // };

  const onRowDragMove = event => {
    setPotentialParentForNode(event.api, event.overNode, event.node, entities);
  };

  const onRowDragLeave = event => {
    setPotentialParentForNode(event.api, null, event.node, entities);
  };

  const handleRowDragEnd = ({ api, node }) => {
    if (urlParams.alternative !== DISPLAYS.hierarchy || !potentialParent) {
      return;
    }

    const movingData = node.data;
    const newParentPath =
      potentialParent.data &&
      (potentialParent.data.sys_hierarchy || potentialParent.data.id)
        ? potentialParent.data.sys_hierarchy || [potentialParent.data.id]
        : [];
    const needToChangeParent = !arePathsEqual(
      newParentPath,
      movingData.sys_hierarchy || [movingData.id]
    );
    const invalidMode = isSelectionParentOfTarget(node, potentialParent);
    if (invalidMode) {
      console.log("invalid move");
    }
    if (needToChangeParent && !invalidMode) {
      const updatedRows = [];
      moveToPath(newParentPath, node, updatedRows);
      gridApi.applyTransaction({ update: updatedRows });
      //gridApi.clearFocusedCell();
    }
    setPotentialParentForNode(api, null, node, entities);

    updateLocalRows(api);
    // Update server data through webSocket
    //WSContext.sendData(params.data);
  };

  const selectionChanged = ({ api }) => {
    const selected = api.getSelectedNodes();
    setSelectedRows(selected);
  };

  const handleCellClick = ({ node, api, column }) => {
    // if (column.colId === 'rowNumber') {
    //   api.clearRangeSelection();
    //   node.setSelected(node && node.isSelected());
    // }

    // Set active image in gallery mode if click cell from "primary_image" column
    if (column.colId === "sys_primary_image") {
      setActiveImage(node.rowIndex);
    }
  };

  const onCellChange = ({ data, node, column, value }) => {
    //_rows = _rows.map((row) => (row.id === data.id ? data : row));

    // Generate "entity_row_id" if it`s null after cell update
    if (data.entity_row_id === null) {
      const { colId } = column;
      const generatedRowId = uuidv4();
      const newData = { ...data, entity_row_id: generatedRowId };

      _cols.forEach(col => {
        newData[col.code] = newData[col.code] || col.default_value;
      });
      newData[colId] = value;

      node.setData(newData);
      // Update local data of rows
      _rows = _rows.map(row => (row.id === data.id ? newData : row));

      // Update server data through webSocket
      //WSContext.sendData(data);
    }
  };

  const getMainMenuItems = ({ columnApi, defaultItems, column }) => {
    const menuItems = defaultItems.splice(0);
    const colState = colStateFromColumn(_cols, column);
    const columnTests = SheetDataService.getColumnTestsByChannel(
      colState,
      urlParams.channel
    );
    const testsData = SheetDataService.testsMappings(columnTests, tests);

    menuItems.splice(6, 0, {
      name: "Hide Column From View",
      action: () => {
        columnApi.setColumnVisible(column.colId, false);
      }
    });

    menuItems.splice(7, 0, {
      name: "Reset Column",
      //icon: '',
      //shortcut: '',
      action: () => {
        const columnState = columnsState.find(
          state => state.colId === column.colId
        );
        columnApi.applyColumnState({ state: [columnState] });
      }
    });

    if (colState.testable && columnTests.length > 0) {
      menuItems.push("separator");
      menuItems.push({
        name: "Show Health Tests",
        //icon: '',
        //shortcut: '',
        action: () => {
          setIsModalOpen(true);
          setModalContent({
            type: "columnTestInfo",
            params: { testsData }
          });
        }
      });
    }

    return menuItems;
  };

  const getContextMenuItems = ({ api, defaultItems, node, value, column }) => {
    if (node == null || column == null) return null;

    let menuItems = defaultItems.splice(0);
    const colState = colStateFromColumn(_cols, column);
    const rangeSelection = api.getCellRanges();
    const testing = handleTesting({ api, value, column, node }, colState);
    const testsData = SheetDataService.testsMappings(testing, tests);
    const isEditable = handleEditable({ column, data: node.data }, colState);
    let numberSelectedRows = 1;

    menuItems.splice(0, 0, {
      name: "Health Information",
      shortcut: "Ctrl + Q",
      action: () => {
        setIsModalOpen(true);
        setModalContent({
          type: "cellTestInfo",
          params: { testsData },
          isDialog: true
        });
      },
      disabled: testsData.length === 0
    });

    menuItems.splice(1, 0, {
      name: "Show Cell Helper",
      shortcut: "Ctrl + H",
      action: () => {
        handleHelperClick({ api, node, column, value });
      },
      disabled: !(isEditable && colTypesWithHelpers.includes(colState.typecast))
    });

    menuItems.splice(2, 0, "separator");

    menuItems.splice(3, 0, generateSampleVariant(rangeSelection));

    menuItems.splice(4, 0, {
      name: "Connect To Master",
      action: () => {},
      disabled: true
    });

    menuItems.splice(5, 0, {
      name: "Remove From Master",
      action: () => {},
      disabled: true
    });

    menuItems.splice(6, 0, {
      name: "Create Master For Samples",
      action: () => {},
      disabled: true
    });

    menuItems.splice(7, 0, {
      name: "Create Samples For Master",
      action: () => {},
      disabled: true
    });

    menuItems.splice(8, 0, "separator");

    menuItems.splice(9, 0, {
      name: "Cut",
      icon:
        '<span class="ag-icon ag-icon-copy" unselectable="on" role="presentation"></span>',
      shortcut: "Ctrl + X",
      action: () => {
        api.copySelectedRangeToClipboard();
        node.setDataValue(column.colId, "");
      }
    });

    menuItems.splice(11, 1);

    menuItems.splice(12, 0, "separator");

    menuItems.splice(13, 0, {
      name: "Undo",
      shortcut: "Ctrl + Z",
      action: () => {
        api.undoCellEditing();
      }
    });

    menuItems.splice(14, 0, {
      name: "Redo",
      shortcut: "Ctrl + Y",
      action: () => {
        api.redoCellEditing();
      }
    });

    // Menu items for Row Header ( Row Number )
    if (column.colId === "rowNumber" && rangeSelection.length === 1) {
      menuItems = [];
      const startRow = rangeSelection[rangeSelection.length - 1].startRow;
      const endRow = rangeSelection[rangeSelection.length - 1].endRow;
      const fromUpToDown = endRow.rowIndex > startRow.rowIndex;
      numberSelectedRows = fromUpToDown
        ? endRow.rowIndex + 1 - startRow.rowIndex
        : startRow.rowIndex + 1 - endRow.rowIndex;

      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Select ${numberSelectedRows} rows`
            : "Select row",
        //icon: '',
        shortcut: "Ctrl + A",
        action: () => {
          rowsSelection(rangeSelection, true);
        }
      });
      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Unselect ${numberSelectedRows} rows`
            : "Unselect row",
        //icon: '',
        action: () => {
          rowsSelection(rangeSelection, false);
        }
      });
      menuItems.push("separator");
      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Insert ${numberSelectedRows} rows below`
            : "Insert row below",
        //icon: '',
        action: () =>
          insertRows(
            numberSelectedRows,
            fromUpToDown ? endRow : startRow,
            false
          )
      });
      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Insert ${numberSelectedRows} rows above`
            : "Insert row above",
        //icon: '',
        shortcut: "Ctrl + I",
        action: () =>
          insertRows(numberSelectedRows, fromUpToDown ? startRow : endRow, true)
      });
      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Clear ${numberSelectedRows} rows`
            : "Clear row",
        //icon: '',
        action: () => {
          clearRows(rangeSelection);
        }
      });
      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Delete ${numberSelectedRows} rows`
            : "Delete row",
        //icon: '',
        shortcut: "Ctrl + D",
        action: () => {
          setIsModalOpen(true);
          setModalContent({
            type: "deleteConfirm",
            params: { number: numberSelectedRows, rangeSelection },
            isDialog: true
          });
        },
        cssClasses: ["text-danger"]
      });
      menuItems.push("separator");
      menuItems.push({
        name: "Clone Row With Data",
        action: () => {
          cloneRow(node);
        }
      });
    }

    return menuItems;
  };

  // Use for "Sample Variant" menu item
  // TODO: This function has to be extended in the future
  const generateSampleVariant = rangeSelection => {
    const nodes = getRangeSelectedNodes(
      gridApi,
      rangeSelection,
      urlParams.alternative === DISPLAYS.hierarchy
    );
    const uniqueNodesRowIndex = SheetDataService.uniqByProp(nodes, "rowIndex");
    const isDisabled =
      uniqueNodesRowIndex.some(
        node =>
          node.allChildrenCount !== null ||
          node.level > 0 ||
          (node.data.sys_hierarchy && node.data.sys_hierarchy.length > 1)
      ) || uniqueNodesRowIndex.length === 0;
    const menuItem = {
      name: "Sample Variant",
      action: () => {
        sampleVariantAction(nodes);
      },
      disabled: isDisabled
    };

    return menuItem;
  };

  const sampleVariantAction = (nodes = []) => {
    if (nodes.length === 0) return;
    const newRowsData = [..._rows];
    const nodesToUpdate = [];
    const newRowIndex = nodes[nodes.length - 1].rowIndex + 1;
    const newRow = generateNotEmptyRow(nodes[0]);

    // Link every node`s "sys_hierarchy" with currently generated row
    nodes.forEach(node => {
      if (node.data.sys_hierarchy) {
        node.data.sys_hierarchy.unshift(newRow.entity_row_id);
      } else {
        node.data.sys_hierarchy = [
          newRow.entity_row_id,
          node.data.entity_row_id || node.data.id
        ];
      }
      const rowIndex = newRowsData.findIndex(row => row.id === node.data.id);
      newRowsData.splice(rowIndex, 1, node.data);
      nodesToUpdate.push(node.data);
    });

    newRowsData.splice(newRowIndex, 0, newRow);
    _rows = newRowsData;
    gridApi.setRowData(_rows);
    gridApi.applyTransaction({ update: nodesToUpdate });
  };

  const insertRows = (insertNumber, selectedRow, above) => {
    if (!selectedRow) return;

    const blankRows = generateBlankRows(
      insertNumber,
      sheetData,
      above ? selectedRow.rowIndex : selectedRow.rowIndex + insertNumber,
      urlParams.channel,
      _cols
    );
    const rowIndex = above ? selectedRow.rowIndex : selectedRow.rowIndex + 1;
    const newRowsData = [..._rows];

    newRowsData.splice(rowIndex, 0, ...blankRows);
    _rows = newRowsData;
    gridApi.setRowData(_rows);
  };

  const rowsSelection = (rangeSelection, select) => {
    const nodes = getRangeSelectedNodes(gridApi, rangeSelection);

    nodes.forEach(node => {
      node.setSelected(select);
    });
  };

  const clearRows = rangeSelection => {
    const nodes = getRangeSelectedNodes(gridApi, rangeSelection);

    nodes.forEach(node => {
      const newData = {};
      // Clear row data for every column
      Object.keys(node.data).forEach(key => {
        const colFound = _cols.some(col => col.code === key);
        if (!colFound) {
          newData[key] = node.data[key];
        }
      });
      node.setData(newData);
    });
  };

  const deleteRows = rangeSelection => {
    const nodesToDelete = [];
    const nodes = getRangeSelectedNodes(gridApi, rangeSelection);

    nodes.forEach(node => {
      nodesToDelete.push(node.data);
    });

    _rows = _rows.filter(
      row => nodesToDelete.findIndex(node => node.id === row.id) < 0
    );

    gridApi.applyTransaction({ remove: nodesToDelete });
    // gridApi.setRowData(_rows);
    // gridApi.refreshCells();
  };

  const cloneRow = rowNode => {
    if (!rowNode) return;
    const rowIndex = rowNode.rowIndex + 1;
    const newRowsData = [..._rows];
    const row = generateNotEmptyRow(rowNode);

    newRowsData.splice(rowIndex, 0, row);
    _rows = newRowsData;
    gridApi.setRowData(_rows);
    gridApi.refreshCells();
  };

  const handleFilterChange = ({ api }, filterType) => {
    const filters = api.getFilterModel();
    const emptyFilter = Object.keys(filters).length === 0;

    if (
      emptyFilter &&
      filterType !== TOOLBAR_FILTER_TYPE.filterToSelectedRows
    ) {
      setAppliedFilters(filters);
    } else {
      setAppliedFilters({
        label:
          (filterType &&
            filterType !== TOOLBAR_FILTER_TYPE.filterToSelectedRows) ||
          TOOLBAR_FILTER_TYPE.tempFilter,
        filters
      });
      api.deselectAll();
    }

    //api.closeToolPanel();
  };

  const additionalToolbars = () => {
    const filters =
      entities.limiters &&
      entities.limiters.map(limiter => ({
        id: limiter.code,
        labelDefault: limiter.name,
        labelKey: `filter_${limiter.code}`,
        iconKey: limiter.icon,
        toolPanel: "customToolPanel",
        toolPanelParams: {
          code: limiter.code,
          data: entities.limiters
        }
      }));

    return filters || [];
  };

  const valueGetter = colState => ({ api, data, node }) => {
    if (!data) return;
    if (colState.display === DISPLAYS.overall_health) {
      if (data.entity_row_id === null) return;
      let _levelTestsScore = 0;

      _cols.forEach(col => {
        const columnTests = SheetDataService.getColumnTestsByChannel(
          col,
          urlParams.channel
        );
        let _nodes = [];

        api.forEachNode(rowNode => {
          rowNode.id !== node.id &&
            rowNode.data &&
            _nodes.push(rowNode.data[col.code]);
        });

        const isValidType = SheetDataService.testTypecast(
          data[col.code],
          col.typecast,
          sets[col.code]
        );

        columnTests.forEach(test => {
          const testPassed = SheetDataService.testValue(
            data[col.code],
            test,
            _nodes
          );

          if (!testPassed) {
            if (test.level === "critical") {
              _levelTestsScore += 1000;
            } else if (test.level === "warning") {
              _levelTestsScore += 1;
            }
          }
        });

        if (!isValidType) {
          _levelTestsScore += 1000;
        }
      });

      // Update server test score through webSocket
      //WSContext.addData(_levelTestsScore);
      return _levelTestsScore;
    }

    if (colState.display === DISPLAYS.hierarchy && data.entity_row_id) {
      return (
        settings[DISPLAYS.hierarchy] &&
        (data[settings[DISPLAYS.hierarchy].attribute] ||
          settings[DISPLAYS.hierarchy].empty_label)
      );
    }

    return data[colState.code];
  };

  const valueSetter = colState => ({ data, newValue, oldValue, column }) => {
    const validValue = SheetDataService.validValue(
      colState,
      newValue,
      sets[colState.code]
    );
    if (!validValue) return false;

    if (
      colState.display === DISPLAYS.hierarchy &&
      settings[DISPLAYS.hierarchy]
    ) {
      data[settings[DISPLAYS.hierarchy].attribute] = newValue;
      return true;
    }
    data[column.colId] = newValue;
    return true;
  };

  const valueParser = colState => params => {
    if (urlParams.alternative === DISPLAYS.hierarchy) {
      return SheetDataService.lookupKey(
        colState,
        sets[colState.code],
        params.newValue,
        settings[DISPLAYS.hierarchy]
      );
    }
    return SheetDataService.lookupKey(
      colState,
      sets[colState.code],
      params.newValue
    );
  };

  const valueFormatter = colState => params => {
    if (urlParams.alternative === DISPLAYS.hierarchy) {
      return SheetDataService.lookupValue(
        colState,
        sets[colState.code],
        params.value,
        channelData,
        params.data,
        settings[DISPLAYS.hierarchy]
      );
    }
    return SheetDataService.lookupValue(
      colState,
      sets[colState.code],
      params.value,
      channelData,
      params.data
    );
  };

  const handleWidget = (event, newWidget) => {
    if (newWidget === null) {
      setInspector(false);
    } else {
      setPicker(newWidget);
      setInspector(true);
    }
    setWidget(newWidget);
  };

  const handleFilter = (event = {}) => {
    const filters = gridApi.getFilterModel();
    const emptyFilter = Object.keys(filters).length === 0;

    switch (event.code) {
      case FILTER_OPTIONS.clearFilter:
        if (emptyFilter) {
          gridApi.setRowData(_rows);
          break;
        }
        gridApi.setFilterModel({});
        break;
      case FILTER_OPTIONS.editFilter:
        gridApi.openToolPanel("filters");
        break;
      case FILTER_OPTIONS.saveFilter:
        saveFilters();
        break;
      default:
        return;
    }
  };

  const handleView = event => {
    setView(
      event.id
        ? event
        : event.alternative
        ? { ...view, alternative: event.code }
        : view
    );
    if (event.action) {
      switch (event.code) {
        case VIEW_OPTIONS.editView:
          if (urlParams.alternative) {
            setIsModalOpen(true);
            setModalContent({
              type: `${DISPLAYS[urlParams.alternative]}Manager`,
              params: {
                options: {
                  attributes: _cols,
                  margins: marginOptions,
                  sizes: imageSizes
                }
              }
            });
          } else {
            gridApi.setSideBarVisible(true);
            gridApi.openToolPanel("columns");
          }
          return;
        case VIEW_OPTIONS.copyView:
          // Do something
          return;
        case VIEW_OPTIONS.saveView:
          // Do something
          return;
        default:
          // Do something
          return;
      }
    }
    history.push(
      event.id
        ? `?view=${event.id}`
        : event.alternative
        ? `?view=${view.id}&alternative=${event.code}`
        : `?view=${view.id}`
    );
  };

  const handleSelection = event => {
    const selectedRows = gridApi.getSelectedNodes();

    switch (event.code) {
      case SELECTION_OPTIONS.filterToSelect:
        gridApi.setRowData(gridApi.getSelectedRows());
        handleFilterChange(
          { api: gridApi },
          TOOLBAR_FILTER_TYPE.filterToSelectedRows
        );
        break;
      case SELECTION_OPTIONS.clearSelected:
        gridApi.deselectAll();
        break;
      case SELECTION_OPTIONS.selectAll:
        gridApi.selectAll();
        break;
      case SELECTION_OPTIONS.invert:
        gridApi.selectAll();
        selectedRows.forEach(node => {
          node.setSelected(false);
        });
        break;
      default:
        return;
    }
  };

  const handleActionClick = event => {
    console.log("action click: ", event);
  };

  const handleToggleButtonClick = event => {
    console.log("button click: ", event);
  };

  const handleChannel = (event, newChannel) => {
    setChannelData(channels[newChannel]);
    // const historyParams = history.location.search;

    history.push({
      pathname: "/",
      search: `channel=${channels[newChannel].code}`
    });
  };

  const handleEditable = (params, colState) => {
    const { column, data } = params;
    const editableCols = SheetDataService.extractEditableCols(data, sets);

    return !colState.protect ||
      (urlParams.alternative === DISPLAYS.hierarchy &&
        colState.display === DISPLAYS.hierarchy)
      ? editableCols.length > 0
        ? editableCols.includes(column.colId)
        : true
      : false;
  };

  const handleTesting = ({ api, value, column, node }, colState) => {
    const colId = column ? column.colId : colState.code;
    const columnTests = SheetDataService.getColumnTestsByChannel(
      colState,
      urlParams.channel
    );
    const isValidType = SheetDataService.testTypecast(
      value,
      colState.typecast,
      sets[colId]
    );
    let _levelTests = [];
    let _nodes = [];

    api.forEachNode(rowNode => {
      rowNode.id !== node.id &&
        rowNode.data &&
        _nodes.push(rowNode.data[colId]);
    });
    if (!node.data) return _levelTests;

    node.data.entity_row_id !== null &&
      columnTests.forEach(test => {
        const testPassed = SheetDataService.testValue(value, test, _nodes);

        if (!testPassed) {
          _levelTests.push({
            ...test,
            column: colState.name,
            cellValue: value
          });
        }
      });

    if (!isValidType) {
      _levelTests.push({
        code: "type",
        level: "critical",
        params: [{ value: colState.typecast }],
        column: colState.name,
        cellValue: value
      });
    }

    return _levelTests;
  };

  const handleWidgetInputChange = event => {
    const { name, value } = event.target;
    activeRowNode.setDataValue(name, value);
  };

  const handleImageSelect = (rowData, selected) => {
    const node = gridApi && gridApi.getRowNode(rowData.id);
    node.setSelected(selected);
  };

  const handleActiveImage = (rowData, index) => {
    const columnisVisible = gridColumnApi
      .getColumn("sys_primary_image")
      .isVisible();
    if (!gridApi || !gridColumnApi || !columnisVisible) return;
    const node = gridApi.getRowNode(rowData.id);
    gridApi.clearRangeSelection();
    gridApi.addCellRange({
      rowStartIndex: node.rowIndex,
      rowEndIndex: node.rowIndex,
      columnStart: "sys_primary_image",
      columnEnd: "sys_primary_image"
    });
    gridApi.setFocusedCell(node.rowIndex, "sys_primary_image", "bottom");
    setActiveImage(index);
  };

  const handleHelperClick = ({ api, node, column, value }) => {
    const { rowIndex } = node;
    const colState = colStateFromColumn(_cols, column);

    api.setFocusedCell(rowIndex, column.colId);
    api.startEditingCell({
      rowIndex: rowIndex,
      colKey: column.colId,
      keyPress: "helperBtn"
    });

    if (colState.typecast === TYPECASTS.html) {
      setIsModalOpen(true);
      setModalContent({ type: "htmlEditor", params: { node, column, value } });
    }
  };

  const getCellValue = (colKey, rowData) => {
    if (!gridApi) return;
    const node = gridApi.getRowNode(rowData.id);
    const value = gridApi.getValue(colKey, node);
    return value;
  };

  const renderModalContent = (type, params = {}) =>
    ({
      htmlEditor: (
        <div>
          <HtmlEditor
            initValue={params.value}
            onChange={value => {
              params.node &&
                params.node.setDataValue(params.column.colId, value);
            }}
          />
        </div>
      ),
      galleryManager: (
        <div>
          <h2 style={{ marginBottom: "12px" }}>Gallery Manager</h2>
          <div>
            <label>Attributes for caption:</label>
            <Input
              type="select"
              multiple
              label="Choose up to 3 attributes"
              onChange={e => {
                if (e.target.value.length <= 3) {
                  setSettings({
                    ...settings,
                    gallery: {
                      ...settings.gallery,
                      attributes: e.target.value
                    }
                  });
                  // Update server settings through webSocket
                  //WSContext.addData(e.target.value);
                }
              }}
              options={params.options ? params.options.attributes : []}
              value={settings.gallery ? settings.gallery.attributes : []}
            />
          </div>
          <div>
            <label>Margin between images:</label>
            <Input
              type="select"
              label="Select margin"
              onChange={e => {
                setSettings({
                  ...settings,
                  gallery: {
                    ...settings.gallery,
                    image_margin: e.target.value
                  }
                });
                // Update server settings through webSocket
                //WSContext.addData(e.target.value);
              }}
              options={params.options ? params.options.margins : []}
              value={
                settings.gallery ? settings.gallery.image_margin : "no_margin"
              }
            />
          </div>
          <div>
            <label>Image size:</label>
            <Input
              type="select"
              label="Select image size"
              onChange={e => {
                setSettings({
                  ...settings,
                  gallery: {
                    ...settings.gallery,
                    image_size: e.target.value
                  }
                });
                // Update server settings through webSocket
                //WSContext.addData(e.target.value);
              }}
              options={params.options ? params.options.sizes : []}
              value={
                settings.gallery ? settings.gallery.image_size : "thumbnail"
              }
            />
          </div>
          <div>
            <Input
              type="switch"
              value={
                settings.gallery ? settings.gallery.caption_on_hover : false
              }
              label="Caption on hover"
              onChange={e => {
                setSettings({
                  ...settings,
                  gallery: {
                    ...settings.gallery,
                    caption_on_hover: e.target.checked
                  }
                });
                // Update server settings through webSocket
                //WSContext.addData(e.target.value);
              }}
            />
          </div>
        </div>
      ),
      deleteConfirm: (
        <div>
          <DialogTitle id="alert-dialog-title">
            {intl.formatMessage(
              {
                id: "products.deleteConfirmation",
                defaultMessage: "Are you sure you want to delete row/s?"
              },
              { number: params.number }
            )}
          </DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button
              ui="primary"
              onClick={() => {
                deleteRows(params.rangeSelection);
                setIsModalOpen(false);
              }}
            >
              Confirm
            </Button>
            <Button
              ui="secondary"
              type="button"
              onClick={() => {
                setIsModalOpen(false);
              }}
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </div>
      ),
      columnTestInfo: (
        <div>
          <h2>Column tests information</h2>
          {params.testsData &&
            params.testsData.map(test => (
              <div key={test.id}>
                <div>
                  <b>{test.name}</b>
                </div>
                <div>{test.description}</div>
                {test.params && test.params.length > 0 && (
                  <div>
                    {test.params
                      .map(param => {
                        return param.value ? param.value : param;
                      })
                      .join("-")}
                  </div>
                )}
                <br />
              </div>
            ))}
        </div>
      ),
      cellTestInfo: (
        <div>
          <h2>Cell Tests Information</h2>
          {params.testsData &&
            params.testsData.map(test => (
              <div key={test.id}>
                <div>
                  <b>{test.name}</b>
                </div>
                <div>{test.description}</div>
                {test.params && test.params.length > 0 && (
                  <div>
                    {test.params
                      .map(param => {
                        return param.value ? param.value : param;
                      })
                      .join("-")}
                  </div>
                )}
                <br />
              </div>
            ))}
        </div>
      )
    }[type]);

  const gridOptions = {
    defaultExportParams: {
      columnGroups: true
    },
    defaultColDef: {
      minWidth: 60
    },
    //enableCellChangeFlash: true,
    rowDragManaged: urlParams.alternative !== DISPLAYS.hierarchy,
    suppressMoveWhenRowDragging: true,
    suppressRowClickSelection: true,
    //rowGroupPanelShow: 'onlyWhenGrouping', // on of ['always','onlyWhenGrouping']
    enterMovesDownAfterEdit: true,
    multiSortKey: "ctrl",
    animateRows: true,
    resizable: true, //one of [true, false]
    enableStatusBar: true,
    enableRangeSelection: true,
    enableFillHandle: true,
    undoRedoCellEditing: true,
    undoRedoCellEditingLimit: 20,
    suppressClearOnFillReduction: true,
    rowSelection: "multiple", // one of ['single','multiple'], leave blank for no selection
    rowDeselection: true,
    quickFilterText: null,
    treeData: urlParams.alternative === DISPLAYS.hierarchy,
    getDataPath: data => data.sys_hierarchy || [data.id],
    // groupSuppressAutoColumn: true,
    groupDefaultExpanded: -1,
    autoGroupColumnDef: {
      headerName: hierarchyColumn.label || "Group",
      pinned: "left",
      lockPinned: true,
      resizable: true,
      editable: settings[DISPLAYS.hierarchy]
        ? settings[DISPLAYS.hierarchy].editable
        : true,
      width: settings[DISPLAYS.hierarchy]
        ? settings[DISPLAYS.hierarchy].width
        : 100,
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true
      },
      cellClassRules: {
        "hover-over": params => params.node === potentialParent,
        critical: ({ value }) =>
          value ===
          intl.formatMessage({
            id: `products.noSku`,
            defaultMessage: humanize("No SKU defined")
          })
      },
      valueFormatter: valueFormatter(hierarchyColumn),
      valueParser: valueParser(hierarchyColumn),
      valueGetter: valueGetter(hierarchyColumn),
      valueSetter: valueSetter(hierarchyColumn)
    },
    stopEditingWhenGridLosesFocus: true,
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressPivotMode: true,
            suppressRowGroups: true,
            suppressValues: true
          }
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel"
        },
        {
          id: "savedFilters",
          labelDefault: "Saved filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "customToolPanel",
          toolPanelParams: {
            code: "saved_filters",
            data: savedFilters
          }
        },
        ...additionalToolbars()
      ],
      position: "left"
    },
    //groupSelectsChildren: true, // one of [true, false]
    onSelectionChanged: selectionChanged, //callback when selection changed,
    onRowDragEnd: handleRowDragEnd,
    ...(urlParams.alternative === DISPLAYS.hierarchy && {
      onRowDragMove: onRowDragMove,
      onRowDragLeave: onRowDragLeave
    }),
    // onSortChanged: (params) => {
    //   params.api.refreshCells({ column: ['rowNumber'] });
    // },
    onCellValueChanged: onCellChange,
    onCellKeyPress: handleCellKeyPress,
    getContextMenuItems: getContextMenuItems,
    getMainMenuItems: getMainMenuItems,
    onCellMouseDown: handleCellClick,
    onFilterChanged: handleFilterChange
  };

  const onGridReady = ({ api, columnApi }) => {
    gridApi = api;
    gridColumnApi = columnApi;

    // Save columns state
    columnsState = columnApi.getColumnState();

    // Set first cell focus
    const firstCol = columnApi.getAllDisplayedColumns()[1];
    api.addCellRange({
      rowStartIndex: 0,
      rowEndIndex: 0,
      columnStart: firstCol,
      columnEnd: firstCol
    });
    api.setFocusedCell(0, firstCol);

    api.addGlobalListener((type, event) => {
      //console.log('type: ', type, '\nevent: ', event);
      // Set number of rows in sheet
      if (
        type === "firstDataRendered" ||
        type === "modelUpdated" ||
        type === "filterChanged"
      ) {
        setRowsToDisplay(api.getModel().rowsToDisplay);
        setRows(getGridRows(api));
      }
      // Get active row from focused cell
      if (
        type === "firstDataRendered" ||
        type === "cellFocused" ||
        type === "modelUpdated"
      ) {
        const focusedCell = api.getFocusedCell() || {};
        const row = api.getDisplayedRowAtIndex(focusedCell.rowIndex) || {};
        setActiveRowNode(row);
      }
      // Get first row from viewport
      if (type === "viewportChanged") {
        setFirstViewRow(event.firstRow);
      }
      // Save row data on every cell change
      if (type === "cellValueChanged") {
        _changedRows.push(event.data);
      }
    });
  };

  return (
    <AuthenticatedLayout
      title={intl.formatMessage({
        id: `sidebar.companies`,
        defaultMessage: humanize("Companies")
      })}
    >
      <Toolbar
        onFilterChange={handleFilter}
        onViewChange={handleView}
        onSelectionChange={handleSelection}
        onActionClick={handleActionClick}
        onButtonClick={handleToggleButtonClick}
        onWidgetToggle={handleWidget}
        views={views}
        view={view}
        rowsToDisplay={rowsToDisplay.length}
        rows={_rows}
        widget={widget}
        entities={entities}
        selectedNumber={selectedRows.length}
        appliedFilters={appliedFilters}
      />
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 255px)",
          overflowY: "scroll"
        }}
      >
        <div
          className={`ag-grid ag-theme-balham${
            urlParams.alternative === DISPLAYS.gallery ? " hidden" : ""
          }`}
          style={{
            width: "100%",
            height: "100%"
          }}
        >
          {!gridDestroyed && (
            <AgGridReact
              onGridReady={onGridReady}
              rowData={_rows}
              immutableData={true}
              getRowNodeId={data => data.id}
              frameworkComponents={{
                // renderers
                booleanRenderer: BooleanRenderer,
                imageRenderer: ImageRenderer,
                currencyRenderer: CurrencyRenderer,
                textRenderer: TextRenderer,
                htmlRenderer: HtmlRenderer,
                overallHealthRenderer: OverallHealthRenderer,
                // editors
                dateEditor: DateEditor,
                calculatorEditor: CalculatorEditor,
                // toolbars
                customToolPanel: CustomToolPanel
              }}
              {...gridOptions}
            >
              <AgGridColumn
                headerName="#"
                field="rowNumber"
                width={75}
                rowDrag={
                  urlParams.alternative === DISPLAYS.hierarchy
                    ? settings[DISPLAYS.hierarchy]
                      ? settings[DISPLAYS.hierarchy].dragging
                      : true
                    : true
                }
                checkboxSelection
                headerCheckboxSelection
                headerCheckboxSelectionFilteredOnly
                //valueGetter={(params) => params.node.rowIndex + 1}
                suppressMenu
                suppressCellFlash
                suppressMovable
                suppressColumnsToolPanel
                editable={false}
                cellClass="lock-col" // add style for this class ( :disabled )
                lockPosition
                lockPinned
                pinned="left"
                suppressKeyboardEvent={params => {
                  return suppressKeyPress(params, ["D", "d", "A", "a"], true);
                }}
              />
              {_cols.map(col => (
                <AgGridColumn
                  key={col.id}
                  field={col.code}
                  headerName={col.label}
                  filter={
                    FILTER_FROM_FILTER_TYPE[col.typecast] ||
                    "agTextColumnFilter"
                  }
                  //floatingFilter
                  width={SheetDataService.getColWidth(col, view)}
                  sortable
                  editable={params => handleEditable(params, col)}
                  resizable
                  enableValue
                  hide={!view.attribute_codes.includes(col.code)}
                  valueGetter={valueGetter(col)}
                  valueSetter={valueSetter(col)}
                  valueFormatter={valueFormatter(col)}
                  valueParser={valueParser(col)}
                  menuTabs={["generalMenuTab"]}
                  {...((col.display === DISPLAYS.overall_health ||
                    col.display === DISPLAYS.hierarchy) && {
                    pinned: "left",
                    lockPinned: true
                  })}
                  //floatCell
                  cellRenderer={
                    RENDERER_FROM_DISPLAY[col.display] || "textRenderer"
                  }
                  cellRendererParams={{
                    channelData,
                    typecast: col.typecast,
                    cols: _cols,
                    onHelperClick: handleHelperClick,
                    editable: params => handleEditable(params, col),
                    onTesting: (params, targetCol) =>
                      handleTesting(params, targetCol || col),
                    suppressCount: true
                  }}
                  //type={TYPE_FROM_TYPECAST[col.typecast]}
                  cellEditorParams={{
                    cellRenderer:
                      RENDERER_FROM_DISPLAY[col.display] || "textRenderer",
                    values: SheetDataService.extractValues(sets[col.code]),
                    channelData
                  }}
                  cellEditorSelector={params => {
                    if (params.keyPress && params.keyPress === "helperBtn") {
                      return {
                        component: EDITOR_FROM_TYPECAST[col.typecast]
                      };
                    } else if (params.value && params.value.length > 30) {
                      return {
                        component: "agPopupTextCellEditor"
                      };
                    }
                  }}
                  cellStyle={params =>
                    cellStyleRules({
                      colState: col,
                      ...params
                    })
                  }
                  cellClassRules={{
                    good: params =>
                      cellClassRules({
                        colState: col,
                        sets,
                        channel: urlParams.channel,
                        ...params
                      }).good,
                    critical: params =>
                      cellClassRules({
                        colState: col,
                        sets,
                        channel: urlParams.channel,
                        ...params
                      }).critical,
                    warning: params =>
                      cellClassRules({
                        colState: col,
                        sets,
                        channel: urlParams.channel,
                        ...params
                      }).warning,
                    readOnly: params =>
                      cellClassRules({
                        colState: col,
                        sets,
                        channel: urlParams.channel,
                        ...params
                      }).readOnly,
                    "hover-over": params => params.node === potentialParent
                  }}
                  suppressCellFlash={
                    col.typecast === TYPECASTS.boolean ||
                    col.code === DISPLAYS.overall_health
                  }
                  // suppressKeyboardEvent={(params) => {
                  //   suppressSpace(params, col);
                  //   return suppressKeyPress(params, 'a', true);
                  // }}
                  headerComponentParams={{
                    template:
                      '<div class="ag-cell-label-container" role="presentation">' +
                      '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                      '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                      '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                      '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                      '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                      '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                      '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
                      `    ${
                        col.testable && col.channels && col.channels.length > 0
                          ? '<span title="" class="ag-icon ag-icon-eye"></span>'
                          : ""
                      }` +
                      '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                      "  </div>" +
                      "</div>"
                  }}
                />
              ))}
            </AgGridReact>
          )}
        </div>
        {urlParams.alternative === DISPLAYS.gallery && (
          <Gallery
            data={rows}
            settings={settings[DISPLAYS.gallery]}
            onSelect={handleImageSelect}
            active={activeImage}
            onActive={handleActiveImage}
            selectedRows={selectedRows}
            getCellValue={getCellValue}
            firstViewRow={firstViewRow}
          />
        )}
      </div>

      <Paper square>
        <Tabs
          value={channelData.position}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChannel}
        >
          {channels.map(channel => (
            <Tab key={channel.id} label={channel.name} />
          ))}
        </Tabs>
      </Paper>

      <Widget
        open={inspector}
        picker={picker}
        rowNode={activeRowNode}
        onClose={() => setInspector(false)}
        onChange={handleWidgetInputChange}
      />

      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        isDialog={modalContent.isDialog}
      >
        {renderModalContent(modalContent.type, modalContent.params)}
      </Modal>
    </AuthenticatedLayout>
  );
}

// Use to create new virgin row
function generateBlankRows(counter, sheetData, seq) {
  const blank = {
    sheet_entity_row_id: null,
    sheet_id: sheetData.id,
    organization_id: "28a83b47-a6b3-4634-8125-f0afa723427c", // get from current user metadata
    entity_row_id: null,
    channels: null
  };
  const blankRows = [];

  for (let i = 0; i < counter; i++) {
    const generatedId = uuidv4();
    blankRows.push({
      ...blank,
      id: generatedId,
      seq: seq + (i + 1),
      sheet_entity_row_id: generatedId
    });
  }

  return blankRows;
}

// Insert row between rows and fill it with data from passed node
function generateNotEmptyRow(node = {}) {
  const sheet_entity_row_id = uuidv4();
  const entity_row_id = uuidv4();

  const row = {
    ...node.data,
    id: sheet_entity_row_id,
    sheet_entity_row_id,
    entity_row_id,
    seq: node.data.seq + 1,
    sys_hierarchy: [entity_row_id]
  };

  return row;
}

function getRangeSelectedNodes(api, rangeSelection) {
  if (!api || !rangeSelection) return;
  const _nodes = [];
  const startRowIndex =
    rangeSelection[rangeSelection.length - 1].startRow.rowIndex;
  const endRowIndex = rangeSelection[rangeSelection.length - 1].endRow.rowIndex;
  const startIndex = endRowIndex > startRowIndex ? startRowIndex : endRowIndex;
  const endIndex = endRowIndex > startRowIndex ? endRowIndex : startRowIndex;

  api.forEachNode(node => {
    if (node.rowIndex >= startIndex && node.rowIndex <= endIndex) {
      _nodes.push(node);
    }
  });

  return _nodes;
}

function colStateFromColumn(cols, column = {}) {
  const colState = cols.find(col => col.code === column.colId) || {};
  return colState;
}

function suppressKeyPress({ event }, keys, isCtrl) {
  if (Array.isArray(keys)) {
    const includesKey = keys.includes(event.key);

    return isCtrl
      ? (event.ctrlKey || event.metaKey) && includesKey
      : includesKey;
  }

  return isCtrl
    ? (event.ctrlKey || event.metaKey) && event.key === keys
    : event.key === keys;
}

function refreshRows(api, rowsToRefresh) {
  const params = {
    rowNodes: rowsToRefresh,
    force: true
  };
  api.refreshCells(params);
}

function getGridRows(api) {
  const _newNodes = [];
  api.forEachNodeAfterFilterAndSort(node => {
    _newNodes.push(node.data);
  });
  return _newNodes;
}

function updateLocalRows(api) {
  const _newNodes = [];
  api.forEachNode(node => {
    _newNodes.push(node.data);
  });
  _rows = _newNodes;
}

/**
 * Functions below are used for Tree view
 */
function moveToPath(newParentPath, node, allUpdatedNodes) {
  const oldPath = node.data.sys_hierarchy || [node.data.id];
  const groupName = oldPath[oldPath.length - 1];
  const newChildPath = newParentPath.slice();
  newChildPath.push(groupName);
  node.data.sys_hierarchy = newChildPath;
  allUpdatedNodes.push(node.data);
  if (node.childrenAfterGroup) {
    node.childrenAfterGroup.forEach(function(childNode) {
      moveToPath(newChildPath, childNode, allUpdatedNodes);
    });
  }
}

function isSelectionParentOfTarget(selectedNode, targetNode) {
  const children = selectedNode.childrenAfterGroup;
  for (var i = 0; i < children.length; i++) {
    if (targetNode && children[i].key === targetNode.key) return true;
    isSelectionParentOfTarget(children[i], targetNode);
  }
  return false;
}
function arePathsEqual(path1 = [], path2 = []) {
  if (path1.length !== path2.length) {
    return false;
  }
  let equal = true;
  path1.forEach(function(item, index) {
    if (path2[index] !== item) {
      equal = false;
    }
  });
  return equal;
}

function setPotentialParentForNode(api, overNode, node, entity = {}) {
  let newPotentialParent;
  const hasChildrens = entity.code === "products" && node.allChildrenCount > 0;
  if (overNode && !hasChildrens) {
    newPotentialParent =
      !(
        !overNode.data.entity_row_id ||
        (overNode.data.sys_hierarchy && overNode.data.sys_hierarchy.length > 1)
      ) || overNode.allChildrenCount > 0
        ? overNode
        : overNode.parent;
  } else {
    newPotentialParent = null;
  }
  const alreadySelected = potentialParent === newPotentialParent;
  if (alreadySelected) {
    return;
  }
  const rowsToRefresh = [];
  if (potentialParent) {
    rowsToRefresh.push(potentialParent);
  }
  if (newPotentialParent) {
    rowsToRefresh.push(newPotentialParent);
  }
  potentialParent = newPotentialParent;
  refreshRows(api, rowsToRefresh);
}

export default injectIntl(Products);
