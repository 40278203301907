import React, { useState } from "react";
import WidgetsIcon from "@material-ui/icons/Widgets";
import WarningIcon from "@material-ui/icons/Warning";
import MenuItem from "@material-ui/core/MenuItem";
import { HtmlEditor } from "components/app/grid";
import TextField from "@material-ui/core/TextField";
import Gallery from "react-photo-gallery";
import Shuffle from "@material-ui/icons/Shuffle";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import { IMAGES_PATH } from "lib/constants";
import ToolBox from "./ToolBox";
import { InspectorBar } from "components/app";
import ProductInfo from "./ProductInfo";
import { makeStyles } from "@material-ui/core/styles";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import * as testsJSON from "../../../../dummyData/test.json";
import * as attributesJSON from "../../../../dummyData/attribute.json";

import Grid from "@material-ui/core/Grid";
import { FormControl, InputLabel } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  fab: {
    background: "#48CFAD",
    borderRadius: "14px",
    height: "24px",
    width: "40px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  absolute: {
    position: "absolute"
  },

  heading: {
    //styleName: H6 Headline;
    fontFamily: "Roboto",
    color: "#3E4345",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "0.15000000596046448px",
    textAlign: "left",
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },

  headingTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineheight: "28px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.15px",
    color: "#5E6366"
  },
  toolBox: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "64%"
  }
}));

const photos = [
  {
    src: `${IMAGES_PATH.thumbnail}/2.png`,
    width: 4,
    height: 4
  },
  {
    src: `${IMAGES_PATH.thumbnail}/3.png`,
    width: 4,
    height: 4
  },
  {
    src: `${IMAGES_PATH.thumbnail}/4.png`,
    width: 4,
    height: 4
  },
  {
    src: `${IMAGES_PATH.thumbnail}/5.png`,
    width: 4,
    height: 4
  }
];

const AgGrid = () => {
  const [rowData] = useState([
    { image: "image", FileName: "Handcurfs", FileType: "PNG" },
    { image: "image", FileName: "Handcurfs", FileType: "PNG" },
    { image: "image", FileName: "Handcurfs", FileType: "PNG" }
  ]);
  return (
    <div
      className="ag-grid ag-theme-balham"
      style={{ height: "200px", paddingTop: "20px" }}
    >
      <AgGridReact rowData={rowData} checkboxSelection>
        <AgGridColumn field="image"></AgGridColumn>
        <AgGridColumn field="FileName"></AgGridColumn>
        <AgGridColumn field="FileType"></AgGridColumn>
      </AgGridReact>
    </div>
  );
};

const GalleryView = () => {
  return (
    <div className="ag-grid ag-theme-balham" style={{ marginTop: "20px" }}>
      <Gallery photos={photos} />
    </div>
  );
};

const AssetsWidget = ({ onClose, rowNode: { data = {} }, onChange }) => {
  const classes = useStyles();
  const [View, setView] = React.useState(<AgGrid />);
  const [viewType, setViewType] = React.useState(1);

  const handleView = event => {
    setView(event.target.value === 1 ? <AgGrid /> : <GalleryView />);
    setViewType(event.target.value === 1);
  };

  return (
    <>
      <div>
        <div>
          <Heading title="Related Assets" />
          <ProductInfo data={data && data} />
        </div>

        <div className={classes.toolBox}>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={viewType}
            onChange={handleView}
          >
            <MenuItem value={1}>Show Rows</MenuItem>
            <MenuItem value={2}>View Selected Rows in gallery</MenuItem>
          </Select>
          <ToolBox />
        </div>

        {View}
      </div>
    </>
  );
};

const ProductsWidget = ({ onClose, rowNode: { data = {} }, onChange }) => {
  const classes = useStyles();
  const [View, setView] = React.useState(<AgGrid />);
  const [viewType, setViewType] = React.useState(1);

  const handleView = event => {
    setView(event.target.value === 1 ? <AgGrid /> : <GalleryView />);
    setViewType(event.target.value === 1);
  };

  return (
    <>
      <div>
        <div>
          <Heading title="Related Products" />
          <ProductInfo data={data && data} />
        </div>

        <div className={classes.toolBox}>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={viewType}
            onChange={handleView}
          >
            <MenuItem value={1}>Show Rows</MenuItem>
            <MenuItem value={2}>View Selected Rows in gallery</MenuItem>
          </Select>
          <ToolBox />
        </div>

        {View}
      </div>
    </>
  );
};

// const TagsWidget = (any) => {
//   console.log("rowNode >>> ", any.rowState.column.colId);

const TagsWidget = ({ rowNode: { data = {} }, rowState }) => {
  let { colId, colDef } = rowState.column;
  let attributes = attributesJSON.default;
  let selectedAttr = attributes.find(a => a.code === colId);
  let tests = [];

  if (selectedAttr && selectedAttr.channels && selectedAttr.channels.length) {
    tests = selectedAttr.channels[0].tests;
  }

  const mapTestParams = (params, code, foundTest) => {
    let testParams =
      params &&
      params.length > 0 &&
      params.map((param, index) => {
        return (
          <div key={param.label + param.value}>
            <br />
            <TextField
              id="outlined-basic"
              variant="outlined"
              label={foundTest.parameters[index].name}
              defaultValue={param.value}
              onChange={e => {
                // checkTestParam(param, code, e);
                // console.log("e >>> ", e);
                // console.log("code >>> ", code);
              }}
              type={selectedAttr.typecast}
              max={param.label === "To" ? param.value : null}
              min={param.label === "From" ? param.value : null}
            />
          </div>
        );
      });

    return testParams;
  };

  const testDetails = test => {
    let testsExternalJSON = testsJSON.default;

    let foundTest = testsExternalJSON.find(t => t.code === test.code);
    let { level } = test;
    let color = "";

    if (level === "warning") {
      color = "#ffda6b";
    }

    if (level === "critical") {
      color = "#ff939a";
    }

    return (
      <ExpansionPanel style={{ paddingTop: "20px" }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container spacing={3}>
            <Grid item xs>
              <input type="checkbox" style={{ zoom: 2, borderRadius: "0px" }} />
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.heading}>
                {foundTest.name}
              </Typography>
            </Grid>
            <Grid item xs>
              <WarningIcon style={{ fontSize: 35, color: color }} />
            </Grid>
          </Grid>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>{foundTest.description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Level
                </InputLabel>
                <Select
                  id="outlined-basic"
                  variant="outlined"
                  value={foundTest.defaultLevel}
                  onChange={() => {}}
                  label="Level"
                >
                  <MenuItem value={foundTest.defaultLevel}>
                    {foundTest.defaultLevel}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {mapTestParams(test.params, test.code, foundTest)}
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  const mapTests = tests => {
    if (tests.length) {
      return tests.map(test => {
        return <div key={test.code + test.level}>{testDetails(test)}</div>;
      });
    }
  };

  const classes = useStyles();
  return (
    <>
      <div>
        <div>
          <Heading title="Tests" />
          <ProductInfo data={data && data} />
        </div>
      </div>
      <h4>{colDef.headerName}</h4>

      <Grid
        container
        spacing={3}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "14px",
          marginBottom: "14px"
        }}
      >
        <Grid item xs={3}>
          <Select
            id="outlined-basic"
            variant="outlined"
            value={tests.length}
            onChange={() => {}}
            label="Level"
          >
            <MenuItem value={tests.length}>{tests.length}</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={9}>
          <TextField label="tools" id="outlined-basic" variant="outlined" />
        </Grid>
      </Grid>

      {mapTests(tests)}
    </>
  );
};

const CategoriesWidget = ({ onClose, rowNode: { data = {} }, onChange }) => {
  return (
    <>
      <div>
        <div>
          <Heading title="Related Categories" />
          <ProductInfo data={data && data} />
        </div>
      </div>
    </>
  );
};

const DoctorWidget = ({ onClose, rowNode: { data = {} }, onChange }) => {
  return (
    <>
      <div>
        <Heading title="Doctor" />
        <ProductInfo data={data && data} />
      </div>
    </>
  );
};

const WysiwygWidget = ({ onClose, rowState, onChange }) => {
  return (
    <>
      <div>
        <Heading title="What you see is what you get" />
      </div>
      <div>
        <HtmlEditor
          initValue={rowState && rowState.value ? rowState.value : ""}
          onChange={value => {
            rowState.node &&
              rowState.node.setDataValue(rowState.column.colId, value);
          }}
        />
      </div>
    </>
  );
};

const Variants = ({ onClose, rowNode: { data = {} }, onChange }) => {
  const classes = useStyles();
  const [View, setView] = React.useState(<AgGrid />);
  const [viewType, setViewType] = React.useState(1);

  const handleView = event => {
    setView(event.target.value === 1 ? <AgGrid /> : <GalleryView />);
    setViewType(event.target.value === 1);
  };

  return (
    <>
      <div>
        <div>
          <Heading title="Related Variants" />
          <ProductInfo data={data && data} />
        </div>

        <div className={classes.toolBox}>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={viewType}
            onChange={handleView}
          >
            <MenuItem value={1}>Show Rows</MenuItem>
            <MenuItem value={2}>View Selected Rows in gallery</MenuItem>
          </Select>
          <ToolBox />
        </div>

        {View}
      </div>
    </>
  );
};

const Attributes = ({ onClose, rowNode: { data = {} }, onChange }) => {
  const classes = useStyles();

  return (
    <>
      <div>
        <div>
          <Heading title="Related Attributes" />
          <ProductInfo data={data && data} />
        </div>

        <div className={classes.toolBox}>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
          >
            <MenuItem value={1}>Show Rows</MenuItem>
            <MenuItem value={2}>View Selected Rows in gallery</MenuItem>
          </Select>
          <ToolBox />
        </div>

        <AgGrid />
      </div>
    </>
  );
};

const Lookups = ({ onClose, rowNode: { data = {} }, onChange }) => {
  const classes = useStyles();

  return (
    <>
      <div>
        <div>
          <Heading title="Related Lookups" />
          <ProductInfo data={data && data} />
        </div>

        <div className={classes.toolBox}>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
          >
            <MenuItem value={1}>Show Rows</MenuItem>
            <MenuItem value={2}>View Selected Rows in gallery</MenuItem>
          </Select>
          <ToolBox />
        </div>

        <AgGrid />
      </div>
    </>
  );
};

const Pricing = ({ rowNode: { data = {} } }) => {
  return (
    <>
      <div>
        <Heading title="Pricing" />
        <ProductInfo data={data && data} />
      </div>
    </>
  );
};

const Heading = ({ title }) => {
  const classes = useStyles();
  return (
    <div style={{ display: "flex" }}>
      <Shuffle />
      <p className={classes.headingTitle}>{title}</p>
    </div>
  );
};

const pickers = {
  assets2product: AssetsWidget,
  products2product4related: ProductsWidget,
  tags2product: TagsWidget,
  categories2product: CategoriesWidget,
  doctor: DoctorWidget,
  wysiwyg: WysiwygWidget,
  pricing2products: Pricing,
  variants2products: Variants,
  attributes2products: Attributes,
  lookup2products: Lookups
};

const SplashContent = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      background:
        "linear-gradient(335deg, rgba(108,0,223,1) 30%, rgba(0,168,255,1) 100%)",
      minHeight: "300px",
      color: "#fff"
    }}
  >
    <div>
      <WidgetsIcon style={{ fontSize: 100, marginBottom: 32 }} />
    </div>
    <h2>Sample Splash Screen</h2>
  </div>
);

export default function Widget({
  open,
  picker,
  rowNode,
  rowState,
  onChange,
  onClose
}) {
  const WidgetContent = pickers[picker];

  return (
    <InspectorBar open={open} size="small">
      {Object.keys(rowNode).length > 0 &&
      rowNode.data &&
      rowNode.data.entity_row_id ? (
        WidgetContent && (
          <WidgetContent
            onClose={onClose}
            rowNode={rowNode}
            rowState={rowState}
            onChange={onChange}
          />
        )
      ) : (
        <SplashContent />
      )}
    </InspectorBar>
  );
}
