import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import humanize from "humanize-string";
import HelperButton from "./helperButton";
import { CustomTooltip } from "components/app/grid";
import { Typography } from "@material-ui/core";

export default function OverallHealthRenderer({
  value,
  data = {},
  api,
  node,
  cols,
  onTesting
}) {
  const [isHelperVisible, setIsHelperVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  let failedTests = [];

  cols.forEach(col => {
    if (onTesting) {
      const failedTest = onTesting({ api, value: data[col.code], node }, col);
      failedTest.length > 0 && failedTests.push(...failedTest);
    }
  });

  const handleMouseEnter = () => {
    setIsHelperVisible(true);
  };

  const handleMouseLeave = () => {
    setIsHelperVisible(false);
  };

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className="ag-cell-container ag-cell-with-helper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span>{value}</span>
      <div className="cell-helpers-wrapper">
        {isHelperVisible && failedTests.length > 0 && (
          <>
            <span
              aria-owns={open ? "popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <HelperButton type="test" />
            </span>
            <CustomTooltip
              id="popover"
              anchorEl={anchorEl}
              handlePopoverClose={handlePopoverClose}
            >
              {failedTests.map((error, index) => {
                const min =
                  error.params &&
                  error.params.length > 0 &&
                  error.params[0].value;
                const max =
                  error.params &&
                  error.params.length > 1 &&
                  error.params[1].value;
                const words =
                  error.params &&
                  error.params.length > 0 &&
                  error.params[0].value;

                return (
                  <Typography key={index}>
                    <FormattedMessage
                      id={`products.failedOHTest.${error.code}`}
                      defaultMessage={humanize(error.code)}
                      values={{
                        column: error.column,
                        value: error.cellValue,
                        min,
                        max,
                        words
                      }}
                    />
                  </Typography>
                );
              })}
            </CustomTooltip>
          </>
        )}
      </div>
    </div>
  );
}
