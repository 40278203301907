import React from "react";
import { AuthContext } from "../auth/authContext";

export const decorateComponent = (getInitialProps, componentWithAuth) => {
  const props = getInitialProps();
  return () => componentWithAuth(props);
};

export const withAuthContext = Component => {
  return props => {
    return (
      <AuthContext.Consumer>
        {({ user, tokens, setUser, logout, isAuthenticated }) => {
          return (
            <Component
              {...props}
              user={user}
              tokens={tokens}
              setUser={setUser}
              logout={logout}
              isAuthenticated={isAuthenticated}
            />
          );
        }}
      </AuthContext.Consumer>
    );
  };
};
