import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import HttpService from "../../../lib/HttpService";
import Switch from "@material-ui/core/Switch";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ViewBuilder = ({ name, view }) => {
  async function putView(editedView) {
    delete editedView.entityId;

    try {
      const response = await HttpService.put(`views`, editedView);

      if (response) {
        // toast.success("View detail updated");
      }
    } catch (e) {
      toast.error("Unable to update view");
    }
  }

  const [active, setActive] = useState(view.active);

  const handleChange = e => {
    setActive(e.target.checked);
    putView({ ...view, active: e.target.checked });
  };

  return (
    <div>
      <h2>Edit View</h2>

      <br />
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          Active
          <Switch
            checked={active}
            onChange={handleChange}
            color="primary"
            name="acivateView"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <br />
          <br />
          <TextField
            label="Name"
            id="outlined-basic"
            variant="outlined"
            defaultValue={view.name}
            onBlur={e => {
              if (view.name !== e.target.value) {
                putView({ ...view, name: e.target.value });
              }
            }}
          />
          <br />
          <br />
          <TextField
            id="outlined-multiline-static"
            label="Description"
            multiline
            rows={4}
            defaultValue={view.description}
            variant="outlined"
            onBlur={e => {
              if (view.description !== e.target.value) {
                putView({ ...view, description: e.target.value });
              }
            }}
          />
          <br />
          <br />
          <TextField
            label="Freeze first X columns"
            id="outlined-basic"
            variant="outlined"
            defaultValue={view.freezeXColumns}
            onBlur={e => {
              if (view.freezeXColumns !== e.target.value) {
                putView({ ...view, freezeXColumns: e.target.value });
              }
            }}
          />
          <br />
          <br />
          <Select
            id="outlined-basic"
            variant="outlined"
            value={view.attributeCodes.length}
            onChange={() => {}}
            label="Level"
          >
            <MenuItem value={view.attributeCodes.length}>
              {view.attributeCodes.length}
            </MenuItem>
          </Select>
          <TextField label="Search" id="outlined-basic" variant="outlined" />
        </Grid>
      </Grid>
    </div>
  );
};
