import React from "react";
import { Button } from "components/ui";
import { DialogActions } from "@material-ui/core";

export const ChannelManager = ({
  name,
  setChildModalOpen,
  setChildModalContent,
  setIsModalOpen
}) => {
  return (
    <div>
      <h2>{name}</h2>
      <DialogActions>
        <Button
          ui="primary"
          onClick={() => {
            setChildModalContent({
              type: "ConnectionManager",
              params: { name: "Connection Manager" }
            });
            setChildModalOpen(true);
          }}
        >
          Open Connection manager
        </Button>
      </DialogActions>
    </div>
  );
};
