import moment from "moment";
import {
  TYPECASTS,
  RELATION_CONTEXT,
  TESTS,
  DISPLAYS,
  IMAGES_PATH
} from "./constants";

export default class SheetDataService {
  _column;

  // Get single Sheet
  static getSheet(sheetsData = [], sheetId) {
    let targetSheet = sheetId
      ? sheetsData.find(sheet => sheet.id === parseInt(sheetId))
      : sheetsData.find(
          sheet => (sheetsData.length > 0 ? sheetsData[0] : []) // default sheet is the 1st one
        );

    return targetSheet || {};
  }

  // Get single View
  static getView(viewsData = [], viewId) {
    const defaultView = viewsData.find(view => view.default);
    const targetView = viewId
      ? viewsData.find(view => view.id === viewId)
      : defaultView;

    return targetView || defaultView;
  }

  // Get single Channel
  static getChannelData(channelData = [], channelCode) {
    const targetChannel = channelData.find(
      channel => channel.code === channelCode
    );

    return targetChannel || {};
  }

  // Get Columns data for sheet
  static getColsData(attributesData = [], view = {}) {
    let colData = [];

    view.attribute_codes &&
      view.attribute_codes.forEach((viewAttr, index) => {
        attributesData.forEach(attr => {
          if (attr.code === viewAttr) {
            colData.push({ ...attr, width: view.attribute_widths[index] });
          }
        });
      });

    return colData;
  }

  // Get Columns width
  static getColWidth(column = {}, view = {}) {
    let width;

    view.attribute_codes &&
      view.attribute_codes.forEach((viewAttr, index) => {
        if (column.code === viewAttr) {
          width = view.attribute_widths[index];
        }
      });

    return width;
  }

  // Get Rows data for sheet
  static getRowsData(data = [], channelName) {
    const rowData = [];

    for (let i = 0; i < data.length; i++) {
      if (data[i].channels && data[i].channels.length) {
        for (let c = 0; c < data[i].channels.length; c++) {
          const channel = data[i].channels[c];
          if (channel.code === channelName) {
            rowData.push({
              id: data[i].sheet_entity_row_id,
              ...data[i],
              ...channel.attributes
            });
          }
        }
      } else {
        rowData.push({ id: data[i].sheet_entity_row_id, ...data[i] });
      }
    }

    return rowData;
  }

  // Get Dropdown options
  static getDropdownOptions(
    colsData = [],
    lookupData = [],
    setData = [],
    channelCode
  ) {
    const dropdowns = {};

    // filter columns by "select" typecast
    const colsWithSelect = colsData.filter(
      col => col.typecast === TYPECASTS.select
    );

    colsWithSelect.forEach(col => {
      if (col.relations_context === RELATION_CONTEXT.lookups) {
        const options = [];

        lookupData.forEach(lookup => {
          // filter lookup chanels by channelCode
          const channels = lookup.channels.filter(
            channel => channel.code === channelCode
          );
          // loop through filtered channels and set dropdown options for the column
          channels.forEach(channel => {
            if (
              channel.attributes &&
              col.relations_code === channel.attributes.group
            ) {
              options.push(channel.attributes);
            }
          });
        });
        dropdowns[col.code] = options;
      } else if (col.relations_context === RELATION_CONTEXT.sys_sets) {
        const options = [];

        setData.forEach(set => {
          options.push(set);
        });
        dropdowns[col.code] = options;
      }
    });

    return dropdowns;
  }

  static extractEditableCols(data = [], sets = []) {
    let editableCols = [];

    Object.keys(sets).forEach(col => {
      const foundSet = sets[col].find(
        obj => obj.hasOwnProperty("attribute_codes") && obj.code === data[col]
      );

      if (foundSet) {
        editableCols = foundSet.attribute_codes;
      }
    });

    return editableCols;
  }

  static testTypecast(value, typecast, sets = []) {
    const isEmpty = SheetDataService.isEmpty(value);
    if (isEmpty) {
      return true;
    }
    const parsedNumber = parseFloat(value);
    const isValidTimestamp = SheetDataService.validDateTimestamp(value);
    const isValidUrl = SheetDataService.validURL(value);
    const isValidEmail = SheetDataService.validEmail(value);
    const cleanBooleam = SheetDataService.booleanCleaner(value);
    const isValidCodeFromSet = sets.some(set => set.code === value);

    switch (typecast) {
      case TYPECASTS.integer:
        return Number.isInteger(parsedNumber);
      case TYPECASTS.decimal:
        return !isNaN(value);
      case TYPECASTS.currency:
        return !isNaN(value);
      case TYPECASTS.date:
        return isValidTimestamp;
      case TYPECASTS.url:
        return isValidUrl;
      case TYPECASTS.email:
        return isValidEmail;
      case TYPECASTS.boolean:
        return typeof cleanBooleam === "boolean";
      case TYPECASTS.select:
        return isValidCodeFromSet;
      default:
        return true;
    }
  }

  static testValue(value, test = {}, nodes) {
    const isEmpty = SheetDataService.isEmpty(value);

    const toLowercase = value
      ? typeof value === "string"
        ? value.toLowerCase()
        : value.toString()
      : "";
    const rangeValues = test.params.map(param => param.value);
    const isInRange = SheetDataService.between(
      value,
      rangeValues[0],
      rangeValues[1]
    );
    const hasWhiteSpace =
      typeof value === "string" ? value.indexOf(" ") >= 0 : false;
    const words = SheetDataService.getWords(value);
    let isInBlackList = false;
    let wordsToContain = [];
    let amountOfWords = 0;
    let numberFoundWords = 0;

    switch (test.code) {
      case TESTS.unique:
        return !(!isEmpty && nodes.includes(value));
      case TESTS.notNull:
        return !(value === null || value === "null");
      case TESTS.blacklist:
        test.params.forEach(param => {
          if (param.type === "array") {
            isInBlackList = param.value.some(word =>
              toLowercase.includes(word)
            );
          }
        });

        return !isInBlackList;
      case TESTS.required:
        return !isEmpty;
      case TESTS.range:
        return isInRange;
      case TESTS.period:
        return isInRange;
      case TESTS.compact:
        return !hasWhiteSpace;
      case TESTS.words:
        return test.params[0].value
          ? words.length >= test.params[0].value
          : false;
      case TESTS.characters:
        return test.params[0].value
          ? value.length >= test.params[0].value
          : false;
      case TESTS.whitelist:
        test.params.forEach(param => {
          if (param.type === "array") {
            wordsToContain = param.value;
          }
          if (param.type === "integer") {
            amountOfWords = param.value;
          }
        });

        if (amountOfWords > 0) {
          wordsToContain.forEach(word => {
            if (toLowercase.includes(word)) {
              numberFoundWords++;
            }
          });
        }

        return numberFoundWords >= amountOfWords;
      default:
        return true;
    }
  }

  static getColumnTestsByChannel(column, channel) {
    const channelObj =
      (column.testable &&
        column.channels &&
        column.channels.find(c => c.channel_id === channel)) ||
      {};
    return channelObj.tests || [];
  }

  static getTestParams(test) {
    let params = [];

    if (test.params) {
      params = test.params.map(param => (param.value ? param.value : param));
    }

    return params;
  }

  static testsMappings(colTests = [], tests = []) {
    const colTestInfos = [];

    colTests.forEach(colTest => {
      tests.forEach(test => {
        if (colTest.code === test.code) {
          colTestInfos.push({ ...colTest, ...test });
        }
      });
    });

    return colTestInfos;
  }

  static extractValues(sets = []) {
    const optionsValues = sets.map(option => option.code);
    return optionsValues;
  }

  static lookupValue(
    colState,
    sets = [],
    code,
    channelData,
    data = {},
    settings = {}
  ) {
    if (colState.display === DISPLAYS.hierarchy) {
      return data[settings.attribute];
    }

    if (colState.typecast === TYPECASTS.date) {
      const date = moment.unix(code);
      return code && !isNaN(parseInt(code)) && date.isValid()
        ? date.format(channelData.date_formatter)
        : code;
    }

    if (colState.typecast !== TYPECASTS.select) {
      return code;
    }

    const value = sets.find(map => map.code === code);
    return value ? value.name : code;
  }

  static lookupKey(colState, sets = [], name, settings = {}) {
    if (
      colState.display === DISPLAYS.hierarchy &&
      name === settings.empty_label
    ) {
      return "";
    }

    if (colState.typecast === TYPECASTS.date) {
      const date = moment.unix(name);
      return date.isValid() && name !== "" && !isNaN(parseInt(name))
        ? date.unix()
        : name;
    }

    if (colState.typecast !== TYPECASTS.select) {
      return name;
    }

    const value = sets.find(map => map.name === name);
    return value ? value.code : name;
  }

  static validValue(colState, value, sets) {
    if ((colState.defaultValue || colState.elastic) && !value) {
      return false;
    }
    const validType = SheetDataService.testTypecast(
      value,
      colState.typecast,
      sets
    );
    if (!validType) {
      return false;
    }
    return true;
  }

  static async extractImages(data = [], settings = {}) {
    const imagesData = [];
    let src = "";
    let imageDimensions = {};
    let caption = [];
    let imageRatio;
    let imageWidth = 1;
    let imageHeight = 1;

    for (const [index, row] of data.entries()) {
      src = row.sys_primary_image
        ? SheetDataService.imagePathFromSettings(
            settings.image_size,
            row.sys_primary_image
          )
        : settings.placeholder_image || "";
      caption =
        (settings.attributes &&
          settings.attributes.map(attribute => row[attribute] || "")) ||
        [];
      imageDimensions = await SheetDataService.getImageDimensions(src);
      imageRatio = imageDimensions.width / imageDimensions.height;
      imageHeight = SheetDataService.imageHeightFromSettings(
        settings.image_size
      );
      imageWidth = imageHeight * imageRatio;

      imagesData.push({
        key: `index_${index}`,
        src,
        width: imageWidth,
        height: imageHeight,
        rowData: row,
        caption
      });
    }

    return imagesData;
  }

  static imagePathFromSettings(imageSize, imageName) {
    return {
      thumbnail: IMAGES_PATH.list + "/" + imageName,
      list: IMAGES_PATH.list + "/" + imageName,
      original: IMAGES_PATH.display + "/" + imageName
    }[imageSize];
  }

  static imageHeightFromSettings(imageSize) {
    return {
      thumbnail: 100,
      list: 200,
      original: 300
    }[imageSize];
  }

  static getImageDimensions(src) {
    return new Promise(resolve => {
      let img;
      img = new Image();
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.onerror = () => resolve({ width: 1, height: 1 });
      img.src = src;
    });
  }

  static booleanCleaner(value) {
    if (value === true || value === "true" || value === 1 || value === "1") {
      return true;
    } else if (
      value === false ||
      value === "false" ||
      value === 0 ||
      value === "0"
    ) {
      return false;
    } else {
      return null;
    }
  }

  static currencyFormatter(channelData = {}, value, decimals = 2) {
    if (!value || isNaN(value) || isNaN(parseFloat(value))) {
      return value;
    }

    let currency = "";
    const formatValue = parseFloat(value)
      .toFixed(decimals)
      .replace(/\./g, channelData.decimal_character || ".")
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, channelData.decimal_character);
    switch (channelData.currency) {
      case "USD":
        currency = channelData.currencySymbol + formatValue;
        break;
      default:
        currency = formatValue + channelData.currencySymbol;
    }

    return currency;
  }

  static uniqByProp = (arr, prop) =>
    Object.values(
      arr.reduce(
        (acc, item) => (item && item[prop] && (acc[item[prop]] = item), acc),
        {}
      )
    );

  static validURL(str = "") {
    const pattern = new RegExp(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
    ); // fragment locator

    return !!pattern.test(str);
  }

  static validEmail(str = "") {
    const pattern = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    ); // fragment locator

    return !!pattern.test(str);
  }

  static validDate(str = "") {
    const dateFormat1 = new RegExp(/^\d{4}([./-])\d{2}\1\d{2}$/); // yyyy[-/.]mm[-/.]dd format
    const dateFormat2 = new RegExp(/^\d{2}([./-])\d{2}\1\d{4}$/); // dd[-/.]mm[-/.]yyyy format

    return !!dateFormat1.test(str) || !!dateFormat2.test(str);
  }

  static validDateTimestamp(timestamp) {
    if (SheetDataService.isEmpty(timestamp)) {
      return true;
    }

    const parsedInt = parseInt(timestamp);
    const valid = new Date(parsedInt).getTime() > 0;

    return valid;
  }

  static isEmpty(value) {
    const trim = typeof value === "string" ? value.trim() : value;
    return value === undefined || value === null || trim === "";
  }

  static isPresent(str = "") {
    return str.length > 0;
  }

  static stripHtmlTags(str = "") {
    const pattern = /<\/?[^>]+(>|$)/g;
    return str ? str.toString().replace(pattern, "") : str;
  }

  static between(value, min, max) {
    return value >= min && value <= max;
  }

  static getWords(str = "") {
    return typeof str === "string" ? str.split(" ") : str;
  }
}
