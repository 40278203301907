import { map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { take } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
// TODO: align on the usage of one api service strategy. Observable vs Promise based
export const sheetDataServiceFullAddress = process.env
  .REACT_APP_SHEET_SERVICE_PORT
  ? `${process.env.REACT_APP_SHEET_SERVICE_URL}:${process.env.REACT_APP_SHEET_SERVICE_PORT}`
  : process.env.REACT_APP_SHEET_SERVICE_URL;

export class RxHttpService {
  rxGetData(request, successCallback) {
    ajax(request)
      .pipe(
        take(1),
        map(productsResponse => productsResponse.response),
        catchError(error => {
          console.error("error: ", error);
          return of([]);
        })
      )
      .subscribe(successCallback);
  }

  rxPutData(request, successCallback) {
    ajax(request)
      .pipe(take(1))
      .subscribe(
        () => successCallback(),
        () => console.error("Update operation failed")
      );
  }

  rxPostData(request, successCallback) {
    ajax(request)
      .pipe(take(1))
      .subscribe(successCallback, () =>
        console.error("Insert operation failed")
      );
  }
}
