import React from "react";
import { injectIntl } from "react-intl";
// import humanize from "humanize-string";
// import { Registry } from "lib";
// import { User } from "models";
import IconButton from "@material-ui/core/IconButton";
import {
  Toolbar,
  Icon
  // LinkButton, Panel, Modal, H5
} from "components/ui";
// import Button from "@material-ui/core/Button";
// import { Field } from "lib/Form";
// import { ResourceForm } from "components/app";

// const emptyBookmarks = [];

const Tools = ({ intl }) => {
  const [open, setOpen] = React.useState(false);
  // const user = Registry.get("user");
  // const bookmarks = user ? user.bookmarks : emptyBookmarks;

  // async function update(id, values) {
  //   await User.update(id, values).then(() => {});
  // }

  function handleClickOpen() {
    console.log("open state : ", open);
    setOpen(true);
  }

  // function handleClose() {
  //   setOpen(false);
  // }

  // function handleSave(values) {
  //   setOpen(false);
  //   // eslint-disable-next-line no-param-reassign
  //   user.bookmarks.push(values);
  //   //update(user.id, user); // commented to prevent using stitch model
  // }

  return (
    <div
      style={{
        padding: "0.5em",
        backgroundColor: "#eceff1",
        borderBottom: "1px solid #ddd"
      }}
    >
      <Toolbar style={{ marginBottom: 0 }}>
        {/* {bookmarks.map(bookmark => ( */}

        {/* ))} */}
        <IconButton style={{ padding: 0 }} onClick={handleClickOpen}>
          <Icon size="14" line="0" glyph="add" />
        </IconButton>
      </Toolbar>
      {/* The bookmark form */}
      {/* <Modal open={open} onClose={handleClose}>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <H5>
              {intl.formatMessage({
                id: `bookmark.modal.title`,
                defaultMessage: humanize("Title")
              })}
            </H5>
            <IconButton style={{ padding: "0px" }} onClick={handleClose}>
              <Icon size="24" line="1" glyph="bigx" />
            </IconButton>
          </div>
          <ResourceForm
            id="specialform"
            resource="bookmark"
            initialValues={defaults}
            onSubmit={({ values }) => handleSave(values)}
          >
            {() => (
              <>
                <Panel>
                  <Field name="name" required />
                  <Field name="path" required />
                </Panel>
                <br />
                <Button onClick={handleClose} color="primary">
                  {intl.formatMessage({
                    id: `bookmark.modal.cancel`,
                    defaultMessage: humanize("Cancel")
                  })}
                </Button>
                <Button type="submit" color="primary">
                  {intl.formatMessage({
                    id: `bookmark.modal.save`,
                    defaultMessage: humanize("Add")
                  })}
                </Button>
              </>
            )}
          </ResourceForm>
        </div>
      </Modal> */}
    </div>
  );
};
export default injectIntl(Tools);
