import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect
} from "react";
import Calculator from "awesome-react-calculator";

const style = {
  height: "24rem",
  width: "15rem"
};

function CalculatorEditor({ value, api }, ref) {
  const [calcVal, setCalcVal] = useState(value);
  const [editing, setEditing] = useState(true);

  useEffect(() => {
    if (!editing) {
      api.stopEditing();
    }
  }, [api, editing]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return calcVal;
      },

      isPopup() {
        return true;
      }
    };
  });

  const handleResultChange = newResult => {
    setCalcVal(newResult.result);
    setEditing(false);
  };

  return (
    <div style={style}>
      <Calculator onResultChange={handleResultChange} />
    </div>
  );
}

export default forwardRef(CalculatorEditor);
