import { WriterBuffer } from "../../../lib/WriterBuffer";

export class ProductsWriterBuffer extends WriterBuffer {
  constructor(
    updateSheetItemBufferKey,
    deleteBufferKey,
    insertBufferKey,
    writerBufferKey
  ) {
    super(writerBufferKey);
    this.updateSheetItemBufferKey = updateSheetItemBufferKey;
    this.insertBufferKey = insertBufferKey;
    this.deleteBufferKey = deleteBufferKey;
    const updateSheetItemRequest = localStorage.getItem(
      this.updateSheetItemBufferKey
    );
    if (!updateSheetItemRequest) {
      localStorage.setItem(this.updateSheetItemBufferKey, JSON.stringify({}));
    }
    const insertDataRequest = localStorage.getItem(this.insertBufferKey);
    if (!insertDataRequest) {
      localStorage.setItem(this.insertBufferKey, JSON.stringify({}));
    }
    const deleteDataRequest = localStorage.getItem(this.deleteBufferKey);
    if (!deleteDataRequest) {
      localStorage.setItem(this.deleteBufferKey, JSON.stringify({}));
    }
  }

  addDataItemForInsert(dataItem, id) {
    this.addDataItem(this.insertBufferKey, dataItem, id);
  }

  getItemsForInsert() {
    return this.getItems(this.insertBufferKey);
  }

  clearItemsForInsert() {
    this.clearItems(this.insertBufferKey);
  }

  addDataItemForDelete(dataItem, id) {
    this.addDataItem(this.deleteBufferKey, dataItem, id);
  }

  getItemsForDelete() {
    return this.getItems(this.deleteBufferKey);
  }

  clearItemsForDelete() {
    this.clearItems(this.deleteBufferKey);
  }

  addSheetItemForUpdate(dataItem, id) {
    this.addDataItem(this.updateSheetItemBufferKey, dataItem, id);
  }

  getSheetItemsForUpdate() {
    return this.getItems(this.updateSheetItemBufferKey);
  }

  clearSheetItemsForDelete() {
    this.clearItems(this.updateSheetItemBufferKey);
  }
}
