import React from "react";
// import { Button } from "components/ui";
// import { DialogActions } from "@material-ui/core";

export const TestInspector = ({ name }) => {
  return (
    <div>
      <h2>{name}</h2>
    </div>
  );
};
