import React, { useState } from "react";
import { Button, Toolbar, H5, P, Icon, Panel } from "components/ui";
import { SettingsLayout, InspectorBar } from "components/app";
import withData from "lib/withData";
import { View } from "models";

const getInitialProps = async ({ db, user }) => {
  const views = await View(db).all(user.ownerId);

  return {
    views
  };
};

const Index = ({ loading, data }) => {
  const [inspector, setInspector] = useState(false);
  const [current] = useState(null);

  return (
    <SettingsLayout title="Views">
      <Icon size="24" line="1" glyph="columns" />
      <H5>Views here</H5>
      <P>Introduction to views</P>

      <Toolbar>
        <Button
          ui="secondary"
          size="small"
          onClick={() => setInspector(!inspector)}
        >
          Inspector
        </Button>
      </Toolbar>

      <Panel>
        <table size="small">
          <thead>
            <tr>
              <th>Entity</th>
              <th>Name</th>
              <th>Desc</th>
              <th>Owner</th>
            </tr>
          </thead>
          {/* <tbody>
            {loading && <p>Loading...</p>}

            {!loading &&
              data.views.map(view => (
                <tr key={view.id}>
                  <td>{view.entityGroup}</td>
                  <td>{view.name}</td>
                  <td>{view.desc}</td>
                  <td>{view.owner}</td>
                </tr>
              ))}
          </tbody> */}
        </table>
      </Panel>
      <InspectorBar open={inspector} size="small">
        <H5>Inspector</H5>
        <p>Detail view of ...</p>
        <Toolbar>
          <Button
            ui="secondary"
            size="small"
            onClick={() => setInspector(false)}
          >
            Edit
          </Button>
          <Button
            ui="secondary"
            color="secondary"
            size="small"
            onClick={() => setInspector(false)}
          >
            Delete
          </Button>
        </Toolbar>
        <Panel>{current && <p>Detail view of ...</p>}</Panel>
      </InspectorBar>
    </SettingsLayout>
  );
};

export default withData(Index, getInitialProps);
