import { ajax } from "rxjs/ajax";
import { Subject } from "rxjs";
import {
  RxHttpService,
  sheetDataServiceFullAddress
} from "../../../lib/RxHttpService";

export class AttributesDataService {
  // Subject is used as using BehaviourSubject or ReplaySubject causes unnecessary multiple executions of the subscritptions
  // observables are static to ensure sharing data amongst multiple components is possible. Serves as alternative to passing the services in the context
  static attributesSubject = new Subject();
  static attributesObservable$ = AttributesDataService.attributesSubject.asObservable();
  rxApiService = new RxHttpService();

  readItems(user, accessToken) {
    const readAttibutesRequest = {
      url: `${sheetDataServiceFullAddress}/attributes/read/${user.sub}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    };

    this.rxApiService.rxGetData(readAttibutesRequest, attributes => {
      AttributesDataService.attributesSubject.next(attributes);
    });
  }

  updateItems(accessToken, requests, successCallback) {
    const createAttributesRequest = {
      url: `${sheetDataServiceFullAddress}/attributes`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: requests
    };
    this.rxApiService.rxPutData(createAttributesRequest, successCallback);
  }

  createItems(accessToken, requests) {
    const createAttributesRequest = {
      url: `${sheetDataServiceFullAddress}/attributes`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: requests
    };
    this.rxApiService.rxPostData(createAttributesRequest);
    ajax(createAttributesRequest).subscribe();
  }
}
