import { DISPLAYS } from "../../../lib/constants";
import SheetDataService from "../../../lib/SheetDataService";
import channelJSON from "dummyData/channel.json";
import { combineLatest } from "rxjs";

// TODO: refactor to enable multiple sheets to reuse
export class GridOptions {
  constructor(gridController) {
    this.gridController = gridController;
    this.channelData = SheetDataService.getChannelData(
      channelJSON,
      this.gridController.urlParams.channel
    );
    this.hierarchyColumn =
      this.gridController._cols.find(
        col => col.display === DISPLAYS.hierarchy
      ) || "";
    // TODO: options can be overridden by passing the keys to be swapped as options to the GridController
    this.gridController.entitiesObservable$.subscribe(entities => {
      this.entities = entities;
    });
    combineLatest(
      this.gridController.settingsObservable$,
      this.gridController.savedFiltersObservable$,
      this.gridController.setsObservable$,
      this.gridController.sheetDataObservable$,
      this.gridController.viewObservable$
    ).subscribe(
      ([settings, savedFilters, sets, sheetData, view]) => {
        this.settings = settings;
        this.savedFilters = savedFilters;
        this.sets = sets;
        this.sheetData = sheetData;
        this.view = view;
        this.options = {
          defaultExportParams: {
            columnGroups: true
          },
          defaultColDef: {
            minWidth: 60
          },
          //enableCellChangeFlash: true,
          rowDragManaged:
            this.gridController.urlParams.alternative !== DISPLAYS.hierarchy,
          suppressMoveWhenRowDragging: true,
          suppressRowClickSelection: true,
          //rowGroupPanelShow: 'onlyWhenGrouping', // on of ['always','onlyWhenGrouping']
          enterMovesDownAfterEdit: true,
          multiSortKey: "ctrl",
          animateRows: true,
          resizable: true, //one of [true, false]
          enableStatusBar: true,
          enableRangeSelection: true,
          enableFillHandle: true,
          undoRedoCellEditing: true,
          undoRedoCellEditingLimit: 20,
          suppressClearOnFillReduction: true,
          rowSelection: "multiple", // one of ['single','multiple'], leave blank for no selection
          rowDeselection: true,
          quickFilterText: null,
          treeData:
            this.gridController.urlParams.alternative === DISPLAYS.hierarchy,
          getDataPath: data => data.sys_hierarchy || [data.id],
          // groupSuppressAutoColumn: true,
          groupDefaultExpanded: -1,
          autoGroupColumnDef: {
            headerName: this.hierarchyColumn.label || "Group",
            pinned: "left",
            lockPinned: true,
            resizable: true,
            editable: settings[DISPLAYS.hierarchy]
              ? settings[DISPLAYS.hierarchy].editable
              : true,
            width: settings[DISPLAYS.hierarchy]
              ? settings[DISPLAYS.hierarchy].width
              : 100,
            cellRendererParams: {
              suppressCount: true,
              suppressDoubleClickExpand: true
            },
            cellClassRules: {
              "hover-over": params =>
                params.node === this.gridController.potentialParent,
              critical: ({ value }) => value === "No SKU defined"
              // TODO: internationalize
              // intl.formatMessage({
              //     id: `products.noSku`,
              //     defaultMessage: humanize("No SKU defined")
              // })
            },
            valueFormatter: this.valueFormatter(this.hierarchyColumn),
            valueParser: this.valueParser(this.hierarchyColumn),
            valueGetter: this.valueGetter(this.hierarchyColumn),
            valueSetter: this.valueSetter(this.hierarchyColumn)
          },
          stopEditingWhenGridLosesFocus: true,
          sideBar: {
            toolPanels: [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressPivotMode: true,
                  suppressRowGroups: true,
                  suppressValues: true
                }
              },
              {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel"
              },
              {
                id: "savedFilters",
                labelDefault: "Saved filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "customToolPanel",
                toolPanelParams: {
                  code: "saved_filters",
                  data: this.savedFilters
                }
              },
              ...this.additionalToolbars()
            ],
            position: "left"
          },
          //groupSelectsChildren: true, // one of [true, false]
          // TODO: fix complex operations
          onSelectionChanged: ({ api }) => this.selectionChanged({ api }), //callback when selection changed,
          onRowDragEnd: ({ api, node }) => this.handleRowDragEnd({ api, node }),
          ...(this.gridController.urlParams.alternative ===
            DISPLAYS.hierarchy && {
            onRowDragMove: event => this.onRowDragMove(event),
            onRowDragLeave: event => this.onRowDragLeave(event)
          }),
          // TODO: uncomment sort changed if needed
          // onSortChanged: (params) => {
          //   params.api.refreshCells({ column: ['rowNumber'] });
          // },
          onCellValueChanged: ({ data, node, column, value }) =>
            this.onCellChange({ data, node, column, value }),
          getContextMenuItems: ({ api, defaultItems, node, value, column }) =>
            this.gridController.gridMenuController.getContextMenuItems({
              api,
              defaultItems,
              node,
              value,
              column
            }),
          getMainMenuItems: ({ columnApi, defaultItems, column }) =>
            this.gridController.gridMenuController.getMainMenuItems({
              columnApi,
              defaultItems,
              column
            }),
          onCellMouseDown: ({ node, api, column }) =>
            this.handleCellClick({ node, api, column }),
          onFilterChanged: ({ api }, filterType) =>
            this.handleFilterChange({ api }, filterType)
        };
      },
      err => console.log(err)
    );
  }

  // TODO: understand sheet data service
  valueFormatter(colState) {
    return params => {
      if (this.gridController.urlParams.alternative === DISPLAYS.hierarchy) {
        return SheetDataService.lookupValue(
          colState,
          this.sets[colState.code],
          params.value,
          this.channelData,
          params.data,
          this.settings[DISPLAYS.hierarchy]
        );
      }
      // TODO: add correct lookup function
      return SheetDataService.lookupValue(
        colState,
        this.sets[colState.code],
        params.value,
        this.channelData,
        params.data
      );
    };
  }

  colStateFromColumn = (cols, column = {}) => {
    const colState = cols.find(col => col.code === column.colId) || {};
    return colState;
  };

  valueParser(colState) {
    return params => {
      return;
      // if (this.urlParams.alternative === DISPLAYS.hierarchy) {
      //   return SheetDataService.lookupKey(
      //     colState,
      //     this.sets[colState.code],
      //     params.newValue,
      //     this.settings[DISPLAYS.hierarchy]
      //   );
      // }
      // return SheetDataService.lookupKey(
      //   colState,
      //   this.sets[colState.code],
      //   params.newValue
      // );
    };
  }

  valueGetter(colState) {
    return ({ api, data, node }) => {
      if (!data) return;
      if (colState.display === DISPLAYS.entityAttribute) {
        return colState.key.split(".").reduce((acc, val) => {
          return acc[val];
        }, data);
      }

      // TODO: simplify the value getter if possible
      // if (colState.display === DISPLAYS.overall_health) {
      //   if (data.entity_row_id === null) return;
      //   let _levelTestsScore = 0;
      //
      //   this._cols.forEach(col => {
      //     const columnTests = SheetDataService.getColumnTestsByChannel(
      //       col,
      //       this.urlParams.channel
      //     );
      //     let _nodes = [];
      //
      //     api.forEachNode(rowNode => {
      //       rowNode.id !== node.id &&
      //         rowNode.data &&
      //         _nodes.push(rowNode.data[col.code]);
      //     });
      //
      //     const isValidType = SheetDataService.testTypecast(
      //       data[col.code],
      //       col.typecast,
      //       this.sets[col.code]
      //     );
      //
      //     columnTests.forEach(test => {
      //       const testPassed = SheetDataService.testValue(
      //         data[col.code],
      //         test,
      //         _nodes
      //       );
      //
      //       if (!testPassed) {
      //         if (test.level === "critical") {
      //           _levelTestsScore += 1000;
      //         } else if (test.level === "warning") {
      //           _levelTestsScore += 1;
      //         }
      //       }
      //     });
      //
      //     if (!isValidType) {
      //       _levelTestsScore += 1000;
      //     }
      //   });
      //
      //   // Update server test score through webSocket
      //   //WSContext.addData(_levelTestsScore);
      //   return _levelTestsScore;
      // }
      //
      // if (colState.display === DISPLAYS.hierarchy && data.entity_row_id) {
      //   return (
      //     this.settings[DISPLAYS.hierarchy] &&
      //     (data[this.settings[DISPLAYS.hierarchy].attribute] ||
      //       this.settings[DISPLAYS.hierarchy]["empty_label"])
      //   );
      // }

      return data[colState.code];
    };
  }

  valueSetter(colState) {
    const self = this;
    return ({ data, newValue, oldValue, column }) => {
      const validValue = SheetDataService.validValue(
        colState,
        newValue,
        self.sets[colState.code]
      );
      if (!validValue) return false;

      if (
        colState.display === DISPLAYS.hierarchy &&
        this.settings[DISPLAYS.hierarchy]
      ) {
        data[this.settings[DISPLAYS.hierarchy].attribute] = newValue;
        return true;
      }
      data[column.colId] = newValue;
      return true;
    };
  }

  additionalToolbars() {
    const filters =
      this.entities.limiters &&
      this.entities.limiters.map(limiter => ({
        id: limiter.code,
        labelDefault: limiter.name,
        labelKey: `filter_${limiter.code}`,
        iconKey: limiter.icon,
        toolPanel: "customToolPanel",
        toolPanelParams: {
          code: limiter.code,
          data: this.entities.limiters
        }
      }));

    return filters || [];
  }

  // TODO: look into callbacks logic further to separate them from the options
  selectionChanged({ api }) {
    const selected = api.getSelectedNodes();
    this.gridController.setSelectedRows(selected);
  }

  handleRowDragEnd({ api, node }) {
    // TODO: implement row dragging when needed
    console.log("row dragged");
  }

  // TODO: investigate if extra parameters will be needed
  onCellChange({ data, node, column, value }) {
    this.gridController.writerCache.addDataItemForUpdate(data);
  }

  handleCellKeyPress(params) {
    // TODO: implement cell key press when needed
    console.log("cell keypress");
  }

  handleCellClick({ node, api, column }) {
    // TODO: implement handleCellClick when needed
    console.log("handle cell click");
  }

  handleFilterChange({ api }, filterType) {
    // TODO: implement handleFilterChange when needed
    console.log("handle filter change");
  }

  handleView(event) {
    this.gridController.setView(
      event.id
        ? event
        : event.alternative
        ? { ...this.view, alternative: event.code }
        : this.view
    );

    // TODO: add additional views if needed
  }

  onRowDragMove(event) {
    // TODO: implement onRowDragMove when needed
    console.log("row drag move");
  }

  onRowDragLeave(event) {
    // TODO: implement onRowDragLeave when needed
    console.log("row drag leave");
  }
}
