import React, { useState } from "react";
import { injectIntl } from "react-intl";
import HelperButton from "./helperButton";
import { SheetDataService } from "lib";
import { TYPECASTS } from "lib/constants";
import { useTestHelper } from "components/app/grid";

function TextRenderer({
  value,
  valueFormatted,
  data,
  api,
  column,
  node,
  typecast,
  channelData,
  editable,
  onHelperClick,
  onTesting,
  intl
}) {
  const [isHelperVisible, setIsHelperVisible] = useState(false);

  const testTooltip = useTestHelper({
    value,
    data,
    api,
    node,
    column,
    onTesting,
    intl
  });

  const isEditable = editable && editable({ column, data });

  const numbersFormat =
    valueFormatted && !isNaN(valueFormatted) && typecast === TYPECASTS.decimal
      ? parseFloat(valueFormatted)
          .toFixed(2)
          .replace(/\./g, channelData.decimal_character || ".")
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, channelData.decimal_character)
      : valueFormatted;

  const htmlEscaped = SheetDataService.stripHtmlTags(numbersFormat);

  const handleMouseEnter = () => {
    setIsHelperVisible(true);
  };

  const handleMouseLeave = () => {
    setIsHelperVisible(false);
  };

  const handleHelperClick = () => {
    onHelperClick({ api, node, column, value });
  };

  return (
    <div
      className="ag-cell-container ag-cell-with-helpers"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span>{htmlEscaped}</span>
      <div className="cell-helpers-wrapper">
        {isHelperVisible && isEditable && (
          <HelperButton type={typecast} onClick={handleHelperClick} />
        )}
        {isHelperVisible && testTooltip}
      </div>
    </div>
  );
}

export default injectIntl(TextRenderer);
