import React from "react";
import styled from "styled-components";
import { Toolbar } from "components/ui";
import { injectIntl } from "react-intl";
// import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
// import { makeStyles } from "@material-ui/core/styles";
// import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { Input } from ".";
import {
  // VIEW_OPTIONS,
  FILTER_OPTIONS,
  SLIDER_OPTIONS,
  SELECTION_OPTIONS
} from "lib/constants";

// TODO: refactor to enable multiple sheets to reuse
// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));

class NewToolbar extends React.Component {
  filterOptions = [
    {
      code: FILTER_OPTIONS.clearFilter,
      name: "Clear filter"
    },
    {
      code: FILTER_OPTIONS.editFilter,
      name: "Edit filter"
    },
    {
      code: FILTER_OPTIONS.saveFilter,
      name: "Save filter"
    }
  ];

  selectionOptions = [
    {
      code: SELECTION_OPTIONS.filterToSelect,
      name: "Filter to selected rows",
      disabled: this.props.selectedNumber === 0
    },
    {
      code: SELECTION_OPTIONS.clearSelected,
      name: `Clear ${this.props.selectedNumber} selected rows`,
      disabled: this.props.selectedNumber === 0
    },
    {
      code: SELECTION_OPTIONS.selectAll,
      name: `Select all ${this.props.rowsToDisplay} rows`
    },
    {
      code: SELECTION_OPTIONS.invert,
      name: `Invert selected`,
      disabled: this.props.selectedNumber === 0
    }
  ];

  viewOptions = [];

  sliderOptions = [
    {
      code: SLIDER_OPTIONS.actionSlider,
      name: "Actions",
      defaultSize: "medium"
    },
    {
      code: SLIDER_OPTIONS.filterManager,
      name: "Filter Manager",
      defaultSize: "small"
    },

    {
      code: SLIDER_OPTIONS.viewManager,
      name: "View Manager",
      defaultSize: "small"
    },
    {
      code: SLIDER_OPTIONS.channelManager,
      name: "Channel Manager",
      defaultSize: "medium"
    },
    {
      code: SLIDER_OPTIONS.testInspector,
      name: "Test Inspector",
      defaultSize: "small"
    },
    {
      code: SLIDER_OPTIONS.categorySelect,
      name: "Category Selector",
      defaultSize: "small"
    },
    {
      code: SLIDER_OPTIONS.elementSelect,
      name: "Element Selector",
      defaultSize: "small"
    },
    {
      code: SLIDER_OPTIONS.attributeInspector,
      name: "Attribute Inspector",
      defaultSize: "small"
    },
    {
      code: SLIDER_OPTIONS.galleryManager,
      name: "Gallery Manager",
      defaultSize: "small"
    },
    {
      code: SLIDER_OPTIONS.accountManager,
      name: "Account Manager",
      defaultSize: "medium"
    },
    {
      code: SLIDER_OPTIONS.organizationManager,
      name: "Organization Manager",
      defaultSize: "medium"
    },
    {
      code: SLIDER_OPTIONS.setBuilder,
      name: "Set Builder",
      defaultSize: "medium"
    },
    {
      code: SLIDER_OPTIONS.variantBuilder,
      name: "Variant Builder",
      defaultSize: "medium"
    }
  ];

  generateViewOptions = () => {
    let options = [...(this.props.views || [])];
    if (this.props.entities.views && this.props.entities.views.length > 0) {
      options.push({ element: "divider" });
    }
    this.props.entities.views &&
      this.props.entities.views.forEach(view => {
        options.push({
          code: view.alternative_view_code,
          name: view.alternative_view_name,
          alternative: true
        });
      });

    if (this.viewOptions.length > 0) {
      options.push({ element: "divider" }, ...this.props.viewOptions);
    }
    return options;
  };

  generateSliderOptions = () => {
    let options = [];

    options.push(...this.props.sliderOptions);
    return options;
  };

  getOptionName = view => {
    const option = this.generateViewOptions().find(
      option => option.code === view
    );
    return option ? option.name : view;
  };

  render() {
    const ToolbarControl = styled.div`
      position: relative;
      border: solid 1px lightgray;
      height: 35px;
      font-size: 14px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    `;

    const ToolbarControlHeader = styled.div`
      position: absolute;
      top: -9px;
      left: 7px;
      background-color: #fafafa;
    `;

    const ToolbarHeaderText = styled.div`
      font-size: 11px;
      opacity: 0.6;
    `;

    const ToolbarNumberControl = styled(ToolbarControl)`
      width: 75px;
    `;

    const ToolbarSelectControl = styled(ToolbarControl)`
      width: 160px;
    `;
    const SelectionLabelControl = styled(ToolbarControl)`
      width: 130px;
      padding: 0px 20px 0px 10px;
    `;
    const SelectionNumber = styled.div`
      background-color: #219653;
      width: 30px;
      border-radius: 25%;
      color: #fdfdfd;
      display: flex;
      justify-content: center;
    `;
    const SelectionFlexSpacer = styled.div`
      width: 12px;
    `;

    const ToolbarFlexSpacer = styled.span`
      width: 10px;
    `;

    const toolbarComponentStyle = {
      display: "flex",
      margin: "5px 0px 5px 0px"
    };

    return (
      <Toolbar style={toolbarComponentStyle}>
        {/*{entities.show_limiters && (*/}
        {/*  <Input*/}
        {/*    type="menu"*/}
        {/*    id="limiters"*/}
        {/*    label={intl.formatMessage({*/}
        {/*      id: `products.toolbar.${appliedFilters.label}`,*/}
        {/*      defaultMessage: "No filtering"*/}
        {/*    })}*/}
        {/*    options={filterOptions}*/}
        {/*    onChange={onFilterChange}*/}
        {/*    disabled={Object.keys(appliedFilters).length === 0}*/}
        {/*  />*/}
        {/*)}*/}
        <ToolbarNumberControl>
          <ToolbarControlHeader>
            <ToolbarHeaderText>Visible</ToolbarHeaderText>
          </ToolbarControlHeader>
          {this.props.rowsToDisplay}
        </ToolbarNumberControl>
        <ToolbarFlexSpacer />
        <ToolbarNumberControl>
          <ToolbarControlHeader>
            <ToolbarHeaderText>Available</ToolbarHeaderText>
          </ToolbarControlHeader>
          {this.props.rows.length}
        </ToolbarNumberControl>
        <ToolbarFlexSpacer />
        <ToolbarSelectControl>
          <ToolbarControlHeader>
            <ToolbarHeaderText>Views</ToolbarHeaderText>
          </ToolbarControlHeader>
          {this.props.entities.show_views && (
            <Input
              type="menuNew"
              id="views"
              display="text"
              label={
                this.props.view.name && !this.props.view.alternative
                  ? this.props.view.name
                  : this.getOptionName(this.props.view.alternative)
              }
              options={this.generateViewOptions()}
              onChange={this.props.onViewChange}
            />
          )}
        </ToolbarSelectControl>
        {/*<Input*/}
        {/*  type="menu"*/}
        {/*  id="SLIDER"*/}
        {/*  label="Select Slider"*/}
        {/*  options={generateSliderOptions()}*/}
        {/*  onChange={onSliderChange}*/}
        {/*/>*/}
        <ToolbarFlexSpacer />
        <SelectionLabelControl>
          <SelectionNumber> {this.props.selectedNumber} </SelectionNumber>
          <SelectionFlexSpacer />
          Selected
          {/*Add logic back when it is clear if it is needed*/}
          {/*{this.props.entities.show_selected && (*/}
          {/*    <Input*/}
          {/*        type="menuNew"*/}
          {/*        id="selections"*/}
          {/*        label={`${this.props.selectedNumber} selected`}*/}
          {/*    />*/}
          {/*)}*/}
        </SelectionLabelControl>
        {/*<span> | </span>*/}
        {/*{entities.show_actions && (*/}
        {/*  <Input*/}
        {/*    type="menu"*/}
        {/*    id="actions"*/}
        {/*    label="Actions"*/}
        {/*    options={entities.actions}*/}
        {/*    onChange={onActionClick}*/}
        {/*    disabled={selectedNumber === 0}*/}
        {/*  />*/}
        {/*)}*/}
        {/*<span> | </span>*/}
        {/*{entities.show_buttons &&*/}
        {/*  entities.buttons.map(button => (*/}
        {/*    <Input*/}
        {/*      type="button"*/}
        {/*      key={button.button_code}*/}
        {/*      id={button.code}*/}
        {/*      label={button.button_name}*/}
        {/*      icon={button.button_icon}*/}
        {/*      onClick={() => onButtonClick(button.button_code)}*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*<ToggleButtonGroup*/}
        {/*  size="small"*/}
        {/*  value={widget}*/}
        {/*  exclusive*/}
        {/*  onChange={onWidgetToggle}*/}
        {/*>*/}
        {/*  {entities.relationships &&*/}
        {/*    entities.relationships.map(toggle => (*/}
        {/*      <ToggleButton*/}
        {/*        key={toggle.code}*/}
        {/*        title={toggle.name}*/}
        {/*        value={toggle.widget_code}*/}
        {/*      >*/}
        {/*        {toggle.widget_icon && (*/}
        {/*          <img src={toggle.widget_icon} alt={toggle.name} />*/}
        {/*        )}*/}
        {/*        /!* <Icon size="18" line="0" glyph="stethoscope" /> *!/*/}
        {/*      </ToggleButton>*/}
        {/*    ))}*/}
        {/*</ToggleButtonGroup>*/}
      </Toolbar>
    );
  }
}

export default injectIntl(NewToolbar);
