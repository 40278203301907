import React, { useEffect, useState } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PencilIcon from "@material-ui/icons/Edit";
import StarIcon from "@material-ui/icons/Star";
import TrashIcon from "@material-ui/icons/Delete";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import HttpService from "../../../lib/HttpService";

const getViews = () => {};

export const ViewManager = ({
  name,
  setChildModalOpen,
  setChildModalContent,
  setIsModalOpen,
  setChildModalSize
}) => {
  const [views, setViews] = useState([]);
  useEffect(() => {
    async function getViews() {
      try {
        const response = await HttpService.get(
          `views/28a83b47-a6b3-4634-8125-f0afa723427c `
        );

        if (response && response.data && response.data.length) {
          setViews(response.data);
        }
      } catch (e) {
        console.error("getViews Error", e);
      }
    }

    getViews();
  }, []);

  async function deleteView(editedView) {
    delete editedView.entityId;
    try {
      const response = await HttpService.delete(`views`, editedView);

      if (response) {
        toast.success("View deleted!");
        getViews();
      }
    } catch (e) {
      toast.error("Unable to delete view");
    }
  }

  const mapViews = views => {
    return views.map(view => {
      return (
        <ExpansionPanel style={{ paddingTop: "20px" }} key={view.id}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container spacing={3}>
              <Grid item xs={1}>
                {/* <input type="checkbox" style={{ zoom: 2, borderRadius: "0px" }} /> */}
                <StarIcon style={{ fontSize: 30, color: "gold" }} />
              </Grid>
              <Grid item xs={8}>
                <Typography className={"classes.heading"} variant="body1">
                  <b>{view.name}</b>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TrashIcon
                  style={{ fontSize: 30, color: "black" }}
                  onClick={() => {
                    if (view && view.deletable && view.deletable === true) {
                      deleteView(view);
                    }
                  }}
                />
                <PencilIcon
                  style={{ fontSize: 30, color: "black" }}
                  onClick={() => {
                    setChildModalContent({
                      type: "ViewBuilder",
                      params: {
                        name: "View Builder",
                        view
                      }
                    });
                    setChildModalOpen(true);
                    setChildModalSize("mini");
                  }}
                />
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>{view.description}</Typography>
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });
  };

  return (
    <div>
      <h2>{name}</h2>

      <br />
      <Grid container>
        <Grid item xs={2}>
          <Chip label={views.length} color="primary" />
        </Grid>
        <Grid item xs={10}>
          <Typography className={"classes.heading"} variant="body1">
            Activate one of the predefined views or rearrange the views or edit
            attributes associated with the views.
          </Typography>
        </Grid>
      </Grid>
      <br />
      <br />

      <Typography className={"classes.heading"} variant="body1">
        {views.length} Star marked views
      </Typography>

      {mapViews(views)}
    </div>
  );
};
